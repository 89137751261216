import React, { Component } from "react";
import "aframe";
import { withTranslation } from "react-i18next";

class CustomVrPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clickStartingPosition: { x: 0, y: 0 },
      rot: 180,
      gyroscope: false,
      showModalVr: false,
      preCanvaNode: null,
    };
  }
  componentDidMount() {
    var scene = document
      .getElementById(this.props.id + "-vr-scene")
      .querySelector("canvas");
    scene.id = this.props.id + "-vr-canva";
    scene.addEventListener("mousedown", this.handleClick);
    scene.addEventListener("mouseup", this.handleClickLeave);
    scene.addEventListener("touchstart", this.handleTouchStart);
    scene.addEventListener("touchleave", this.handleTouchEnd);
  }

  componentWillUnmount() {
    var scene = document
      .getElementById(this.props.id + "-vr-scene")
      .querySelector("canvas");
    scene.removeEventListener("mousedown", this.handleClick);
    scene.removeEventListener("mouseup", this.handleClickLeave);
    scene.removeEventListener("touchstart", this.handleTouchStart);
    scene.removeEventListener("touchleave", this.handleTouchEnd);
  }

  handleClick = (e) => {
    this.setState({ clickStartingPosition: { x: e.x, y: e.y } });
  };

  handleClickLeave = (e) => {
    let newPosition = { x: e.x, y: e.y };
    if (
      this.state.clickStartingPosition.x === newPosition.x &&
      this.state.clickStartingPosition.y === newPosition.y
    ) {
      this.props.pause();
    }
  };

  handleTouchStart = (e) => {
    this.setState({
      clickStartingPosition: {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY,
      },
    });
  };
  handleTouchEnd = (e) => {
    console.log(e);
    let newPosition = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
    };
    if (
      this.state.clickStartingPosition.x === newPosition.x &&
      this.state.clickStartingPosition.y === newPosition.y
    ) {
      this.props.pause();
    }
  };

  addRotation = () => {
    setTimeout(() => {
      // this.setState({ rot: this.state.rot + 0.1 }, () => {
      //   this.addRotation();
      // });
    }, 3);
  };

  testG = () => {};

  render() {
    return (
      <span id={this.props.id + "-vr-scene"}>
        {/* <button
          style={{ width: "200%" }}
          onClick={() => {
            this.testG();
          }}
        >
          TEST GYROSCOPIC SENSOR
        </button> */}
        <a-scene
          // always-fullscreen="platform:nu; debug:true"
          allowfullscreen="no"
          vr-mode-ui="enabled: true"
          device-orientation-permission-ui={`enabled: ${
            this.props.gyroscope
          };denyButtonText:${this.props.t(
            "post.vrDeny"
          )};allowButtonText:${this.props.t(
            "post.vrAllow"
          )};cancelButtonText:${this.props.t(
            "post.vrCancel"
          )};deviceMotionMessage:${this.props.t(
            "post.vrDeviceMotion"
          )};httpsMessage:${this.props.t("post.vrHttpsMessage")}`}
          enabled="true"
          denyButtonText="Denny"
          allowButtonText="Almight"
          cancelButtonText="Canc"
          deviceMotionMessage="DevMotMes"
          mobileDesktopMessage="MOBILEDESKMEG"
          httpsMessage="SWICTHHTHTHHT"
          https-message="messsss"
          light="defaultLightsEnabled: false"
          height={this.props.height}
          width={this.props.width}
          embedded
        >
          {/* <a-dialog-text>CIAO</a-dialog-text> */}
          <a-entity light="type: ambient; color: #fff"></a-entity>
          <a-assets height={this.props.height} width={this.props.width}>
            <video
              poster={this.props.poster}
              id={this.props.id}
              src={this.props.src}
              crossOrigin="anonymous"
              controls={false}
              height={this.props.height}
              width={this.props.width}
            />
          </a-assets>

          {this.props.media.projectionType === 1 && (
            <>
              <a-plane
                position={"0 0 1"}
                height="100"
                width="100"
                rotation="0 180 0"
                color="black"
              ></a-plane>{" "}
              <a-videosphere
                src={"#" + this.props.id}
                rotation={`0 ${this.state.rot} 0`}
                id={this.props.id + "-vr-sphere"}
              ></a-videosphere>
            </>
          )}
          {this.props.media.projectionType === 2 && (
            <>
              <a-plane
                position={"0 0 1"}
                height="100"
                width="100"
                rotation="0 180 0"
                color="black"
              ></a-plane>
              <a-videosphere
                // src={"#" + this.props.id}
                rotation={`0 ${this.state.rot} 0`}
                id={this.props.id + "-vr-sphere"}
                // material={`src: ${this.props.id}`}
                material={`src: #${this.props.id} ; repeat: 2 1`}
              ></a-videosphere>
            </>
          )}
          {this.props.media.projectionType === 3 && (
            <a-videosphere
              src={"#" + this.props.id}
              rotation={`0 ${this.state.rot} 0`}
              id={this.props.id + "-vr-sphere"}
            ></a-videosphere>
          )}
          {this.props.media.projectionType === 4 && (
            <>
              <a-plane
                position={"0 4 -2.5"}
                height="10"
                width="15"
                rotation="0 0 0"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-1"}
              ></a-plane>
              <a-plane
                position={"-2.5 -1 0"}
                height="10"
                width="15"
                rotation="0 90 0"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-2"}
              ></a-plane>
              <a-plane
                position={"5 4 2.5"}
                height="10"
                width="15"
                rotation="0 180 0"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-3"}
              ></a-plane>

              <a-plane
                position={"2.5 -1 5"}
                height="10"
                width="15"
                rotation="0 270 0"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-4"}
              ></a-plane>
              <a-plane
                position={"-5 4 -2.5"}
                height="10"
                width="15"
                rotation="90 0 0"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-5"}
              ></a-plane>
              <a-plane
                position={"5 -1 -2.5"}
                height="10"
                width="15"
                rotation="-90 0 0"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-6"}
              ></a-plane>
            </>
          )}
          {this.props.media.projectionType === 5 && (
            <>
              <a-plane
                position={"-2.5 1.5 -2.5"}
                height="10"
                width="15"
                rotation="0 0 90"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-1"}
                //non si tocca
              ></a-plane>
              <a-plane
                position={"-2.5 -1 5"}
                height="10"
                width="15"
                rotation="0 90 0"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-2"}
              ></a-plane>
              <a-plane
                position={"0 -1 2.5"}
                height="10"
                width="15"
                rotation="0 180 0"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-3"}
                //non si tocca
              ></a-plane>

              <a-plane
                position={"2.5 -1 5"}
                height="10"
                width="15"
                rotation="0 270 0"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-4"}
              ></a-plane>
              <a-plane
                position={"-2.5 4 -5"}
                height="10"
                width="15"
                rotation="90 0 90"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-5"}
              ></a-plane>
              <a-plane
                position={"-2.5 -1 -5"}
                height="10"
                width="15"
                rotation="-90 0 90"
                material={`src: #${this.props.id}`}
                id={this.props.id + "-vr-plane-6"}
              ></a-plane>
            </>
          )}
          <a-camera
            // look-controls-enabled="false" disable rotation of the camera(possible use for a custom movement)
            // position="0 0 0"
            wasd-controls-enabled="false"
            id={this.props.id + "-vr-camera"}
          ></a-camera>
        </a-scene>
      </span>
    );
  }
}

export default withTranslation()(CustomVrPlayer);
