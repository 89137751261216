import React, { Component, Fragment } from "react";
import ReportService from "../../../services/ReportService.js";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { REPORT_TYPES } from "../../config/constants.js";

class ReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportList: REPORT_TYPES,
      reportValue: 0,
      doMessage: false,
      message: "",
      loading: false,
    };
  }

  componentDidMount = () => {};

  sendReport = () => {
    this.props.onHide();
    this.setState({ loading: true });
    let params = {
      type: this.props.type,
      reported: this.props.reported,
      reasons: this.state.reportValue,
      reasonsText: this.state.message,
      objId: this.props.id,
    };

    ReportService.sendReport({ ...params })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ loading: false });
          toast.success(this.props.t("report.successfullySend"));
          // this.props.onHide();
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  render() {
    return (
      <Fragment>
        <Modal
          show={this.props.show}
          onHide={() => this.props.onHide()}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton className="ps-3 pt-2 pb-2">
            <span className="text-color-dark fw-700 font-xssss">
              {this.props.t("report.title")}
            </span>
          </Modal.Header>
          {this.state.loading ? (
            <Modal.Body className="add-card-modal">
              <Loader />
            </Modal.Body>
          ) : (
            <Modal.Body
              className={`text-signal-card add-card-modal ${
                this.state.doMessage === true ? "pb-0" : ""
              }
                          `}
            >
              {this.state.doMessage === false && (
                <>
                  <p
                    style={{ paddingBottom: "8px", paddingTop: "8px" }}
                    className="wallet-secret-text"
                  >
                    {this.props.t("report.description")}
                  </p>
                  <div className="report-text flex-paginator-list">
                    {this.state.reportList.map((value, index) => (
                      <div
                        style={{ paddingBottom: "1px", paddingTop: "1px" }}
                        key={index}
                        onClick={() =>
                          this.setState({ reportValue: index, doMessage: true })
                        }
                      >
                        <p className="report-category">{this.props.t(value)}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {this.state.doMessage === true && (
                <>
                  <p className="font-weight700 wallet-secret-text">
                    {this.props.t("report.reasons")}:
                  </p>
                  <p className="font-weight700 wallet-secret-text">
                    {this.props.t(
                      this.state.reportList[this.state.reportValue]
                    )}
                  </p>
                  <div className="text-area-tip mb-16px">
                    <textarea
                      maxLength={150}
                      placeholder={this.props.t("report.reasons_placeholder")}
                      className="post-input no-resize"
                      onChange={(value) =>
                        this.setState({ message: value.target.value })
                      }
                    ></textarea>
                  </div>

                  <div className="report-action">
                    <p style={{ marginBottom: "4px" }}>
                      {this.props.t("report.action")}
                    </p>
                    <ul>
                      <li>{this.props.t("report.action_1")}</li>
                      <li>{this.props.t("report.action_2")}</li>
                      <li>{this.props.t("report.action_3")}</li>
                    </ul>
                  </div>
                </>
              )}

              {this.state.doMessage === true && (
                <Modal.Footer className="d-flex p-2 justify-content-end border-0">
                  <div
                    className="pointer fw-bold me-4 font-xsss text-silver"
                    onClick={() => this.props.onHide()}
                  >
                    {this.props.t("report.cancel")}
                  </div>

                  <Button
                    className="fix-tip-button fw-bold me-2 rounded-pill font-xssss text-white px-3 py-2 pointer"
                    onClick={() => this.sendReport()}
                  >
                    {this.props.t("report.submit")}
                  </Button>
                </Modal.Footer>
              )}
            </Modal.Body>
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation()(ReportModal);
