import React, { Component } from "react";
import { FormatEmojii } from "../helper/FormatEmojii";

class EmojiList extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      emojisList: [],
    };
  }

  componentDidMount() {
    this.loadEmojis();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      this.wrapperRef.current !== null &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.props?.onClickOutside();
    }
  }

  loadEmojis = () => {
    const emojis = JSON.parse(localStorage.getItem("emojis"));

    this.setState({
      emojisList: emojis,
    });
  };

  spliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    const arr2 = JSON.parse(localStorage.getItem("emojis"));
    while (arr2.length > 0) {
      const chunk = arr2.splice(0, chunkSize);
      res.push(chunk);
    }
    return res;
  };

  render() {
    return this.props?.show ? (
      <div
        className="emoji-list-main-container"
        style={this.props?.style}
        ref={this.wrapperRef}
      >
        {this.spliceIntoChunks(this.state.emojisList, 6).map((chunks, idx1) => {
          return (
            <ul className="emoji-list-main-list">
              {chunks.length > 0 &&
                chunks.map((element, idx2) => {
                  return (
                    <li className="emoji-list-main-item" key={idx2}>
                      <span
                        className="emoji-list-main-item-span"
                        onClick={() =>
                          this.props.onAddOrRemoveReaction(
                            element._id,
                            element.uniCode
                          )
                        }
                      >
                        {FormatEmojii(element.uniCode)}
                      </span>
                    </li>
                  );
                })}
            </ul>
          );
        })}
      </div>
    ) : (
      <></>
    );
  }
}

export default EmojiList;
