import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AWS_URL } from "./config/constants";

class SupportHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    const width = window.innerWidth;
    var isMobile = width <= 512;
    return (
      <div
        className="support-header"
        style={{
          backgroundImage: `url(${AWS_URL}logo/support.png)`,
          backgroundSize: "cover",
        }}
      >
        <div
          className={`${
            isMobile ? "fix-header-support" : ""
          } nav-top back-transparent`}
        >
          <Link
            to="/"
            className={"back-transparent"}
            style={{ paddingLeft: isMobile ? "0px" : "24px", gap: "7.24px" }}
          >
            <img
              className="logo-red-circle"
              src={`${AWS_URL}logo/logoRedCircle.png`}
              alt=""
            ></img>
            <img
              className="logo-red-circle"
              src={`${AWS_URL}logo/RedCircleRed.png`}
              alt=""
            ></img>
          </Link>
          <Link
            className={`${
              isMobile ? "w50 f-16 back-transparent" : "f-18 back-transparent"
            }`}
            to="/"
            style={{ marginLeft: "auto" }}
          >
            <span className="d-inline-block ls-3 fw-400 text-white responsive-font-18-16 back-transparent">
              Go back to Feeds
            </span>
          </Link>
        </div>
        <div className="nav-center text-white back-transparent">
          {this.props.title}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SupportHeader)
);
