import { Avatar, Badge } from "@mui/material";
import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import { stringAvatar } from "../helper/GeneralHelper";
import Skeleton from "@mui/material/Skeleton";
import { SocketContext } from "../../context/SocketContext";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default class DefaultAvatar extends Component {
  static contextType = SocketContext;
  constructor(props) {
    super(props);
    this.state = {
      online: this.props?.online ? this.props?.online : false,
    };
  }
  componentDidMount = () => {
    if (window.location.pathname.includes("chats")) {
      this.context?.socket?.on("get_online_user", (user) => {
        if (this.props.id === user?.userId) {
          this.setState({ online: true });
        }
      });
      this.context?.socket?.on("get_offline_user", (user) => {
        if (this.props.id === user?.userId) {
          this.setState({ online: false });
        }
      });
    }
  };

  render() {
    return (
      <span className="default-avatar-bg">
        {this.props.loading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            variant="circular"
            width={this.props.width}
            height={this.props.height}
            className={this.props.className}
          />
        ) : this.props.children ? (
          this.state.online ? (
            <StyledBadge
              className="defaultAvatarBadge"
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
              sx={{
                marginBottom: this.props.marginBottom,
                top: this.props.top,
                left: this.props.left,
              }}
            >
              {this.props.children}
            </StyledBadge>
          ) : (
            <StyledBadge
              className="defaultAvatarBadge"
              // overlap="circular"
              // anchorOrigin={{
              //   vertical: "bottom",
              //   horizontal: "right",
              // }}
              // variant="dot"
              sx={{
                marginBottom: this.props.marginBottom,
                top: this.props.top,
                left: this.props.left,
              }}
            >
              {this.props.children}
            </StyledBadge>
          )
        ) : this.state.online ? (
          <StyledBadge
            className="defaultAvatarBadge"
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
            sx={{
              marginBottom: this.props.marginBottom,
              top: this.props.top,
              left: this.props.left,
            }}
          >
            <Avatar
              onClick={this.props.onClick}
              alt={this.props.userName}
              title={this.props.userName}
              {...stringAvatar(this.props.userName)}
              className={this.props.className}
              sx={{
                height: this.props.height,
                width: this.props.width,
                backgroundColor: "black",
                fontSize: this.props.fontSize ? this.props.fontSize : "1rem",
              }}
            ></Avatar>
          </StyledBadge>
        ) : (
          <StyledBadge
            className="defaultAvatarBadge"
            // overlap="circular"
            // anchorOrigin={{
            //   vertical: "bottom",
            //   horizontal: "right",
            // }}
            // variant="dot"
            sx={{
              marginBottom: this.props.marginBottom,
              top: this.props.top,
              left: this.props.left,
            }}
          >
            <Avatar
              onClick={this.props.onClick}
              alt={this.props.userName}
              title={this.props.userName}
              {...stringAvatar(this.props.userName)}
              className={this.props.className}
              sx={{
                minHeight: this.props.height,
                minWidth: this.props.width,
                height: this.props.height,
                width: this.props.width,
                backgroundColor: "black",
                fontSize: this.props.fontSize ? this.props.fontSize : "1rem",
                marginBottom: this.props.marginBottom,
                marginRight: this.props.marginRight,
                top: this.props.top,
                left: this.props.left,
              }}
            ></Avatar>
            {this.props.editor}
          </StyledBadge>
        )}
      </span>
    );
  }
}
