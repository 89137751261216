import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BiSolidCreditCardFront, BiSolidCalendarEvent } from "react-icons/bi";
import { MdInfo } from "react-icons/md";
import Loader from "../../../components/Loader";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { REDUX_LOCAL_STORE_KEY } from "../../config/constants.js";
import UserService from "../../../services/UserService.js";
import GeneralService from "../../../services/GeneralService.js";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: localStorage.getItem("theme").includes("theme-dark")
        ? "white"
        : "#000000",
      fontWeight: 300,
      fontFamily: "'solitaire-mvb-pro', Avenir, Arial, sans-serif",
      fontSize: "15px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: localStorage.getItem("theme").includes("theme-dark")
          ? "white"
          : "#000000",
      },
      "::placeholder": {
        color: "#888888",
      },
    },
    invalid: {
      iconColor: "#ff0000",
      color: "#ff0000",
    },
  },
};

const Input = ({ title, className, placeholder, type }) => {
  return (
    <div className={className}>
      <label className="text-color-dark d-block fw-700 font-xssss">
        {title}
      </label>
      <input
        className="modal-input rounded-pill px-4 py-2"
        placeholder={placeholder}
        type={type ? type : "text"}
      />
    </div>
  );
};

const AddNewCardModal = ({
  show,
  handleModal,
  handleLoading,
  onSubmit,
  propsT,
  loader,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [user, setUser] = useState({
    name: "",
  });

  const [shippingAddress, setShippingAddress] = useState({
    address: "",
    postCode: "",
    city: "",
    state: "",
  });

  const handleSubmit = async (event) => {
    if (
      country !== null &&
      country !== undefined &&
      country !== "Select country"
    ) {
      event.preventDefault();
      const cardNumber = elements.getElement(CardNumberElement);
      const cardExpiry = elements.getElement(CardExpiryElement);
      const cardCvc = elements.getElement(CardCvcElement);

      const paymentMethod = stripe.createPaymentMethod({
        type: "card",
        card: cardNumber,
        billing_details: {
          name: user.name,
          address: {
            line1: shippingAddress?.address,
            city: shippingAddress?.city,
            state: shippingAddress?.state,
            country: country,
            postal_code: shippingAddress?.postCode,
          },
        },
      });

      let params = {
        paymentMethodId: (await paymentMethod)?.paymentMethod?.id,
      };
      if (
        params.paymentMethodId !== undefined &&
        params.paymentMethodId !== null
      ) {
        UserService.addCardToStripe({ ...params })
          .then((res) => {
            if (res.status === 200) {
              onSubmit();
              let newUserDetails = JSON.parse(
                localStorage.getItem(REDUX_LOCAL_STORE_KEY)
              );
              newUserDetails.authentication.userData.stripeCustomerId =
                res.data.data.stripeCustomerId;
              localStorage.setItem(
                REDUX_LOCAL_STORE_KEY,
                JSON.stringify(newUserDetails)
              );
              setUser({ name: "" });
              setShippingAddress({
                address: "",
                postCode: "",
                city: "",
                state: "",
              });
              setCountry("");
              cardNumber.clear();
              cardExpiry.clear();
              cardCvc.clear();
              toast.success(propsT("payment.cardAdded"));
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      } else {
        toast.error(propsT("common.fillAll"));
      }
    } else {
      toast.error(propsT("common.fillAll"));
    }
  };

  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);

  const handleSelectedCountry = (event) => {
    setCountry(event.target.value);
  };

  const getCountryList = useCallback(() => {
    let params = {
      search: "",
    };

    GeneralService.getCountryList({ ...params })
      .then((res) => {
        if (res.status === 200) {
          setCountryList(res?.data?.data?.data);
        }
      })
      .catch(() => {
        handleLoading?.(false);
      });
  }, [handleLoading]);

  useEffect(() => {
    getCountryList();
  }, [getCountryList]);

  return (
    <Modal
      show={show}
      onHide={() => handleModal(false)}
      keyboard={false}
      centered
    >
      {loader && <Loader />}
      <Modal.Header closeButton className="ps-3 pt-2 pb-2">
        <span className="text-color-dark fw-700 font-xssss">
          {propsT("manageCards.addCard")}
        </span>
      </Modal.Header>
      <Modal.Body className="p-4 add-card-modal">
        <div className="row">
          <Input
            className="col-12 my-2"
            placeholder={propsT("manageCards.form.card.placeholder")}
            title={propsT("manageCards.form.card.label")}
          />
          <Input
            className="col-12 my-2"
            placeholder={propsT("manageCards.form.address.placeholder")}
            title={propsT("manageCards.form.address.label")}
          />
          <Input
            className="col-sm-6 col-12 my-2"
            placeholder={propsT("manageCards.form.postcode.placeholder")}
            title={propsT("manageCards.form.postcode.label")}
          />
          <Input
            className="col-sm-6 col-12 my-2"
            placeholder={propsT("manageCards.form.city.placeholder")}
            title={propsT("manageCards.form.city.label")}
          />
          <Input
            className="col-sm-6 col-12 my-2"
            placeholder={propsT("manageCards.form.state.placeholder")}
            title={propsT("manageCards.form.state.label")}
          />
          <div className="col-sm-6 col-12 mt-1">
            <div>
              <label className="text-color-dark font-xssss fw-bold">
                {propsT("manageCards.form.country.label")}
              </label>
              <div className="stripe-input rounded-pill p-2">
                <select
                  className={`border-0 bg-payment-white font-dark font-xsss ms-1 w-95 text-color-dark ${
                    !country && "text-silver"
                  } bgc-white`}
                  value={country}
                  onChange={handleSelectedCountry}
                >
                  <option defaultChecked value={null}>
                    {propsT("manageCards.form.country.placeholder")}
                  </option>
                  {countryList.map((country, index) => (
                    <option
                      className="text-black"
                      key={index}
                      value={country.sortname}
                    >
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 my-2">
            <div className="form-group mb-1">
              <label className="text-color-dark font-xssss fw-bold">
                {propsT("manageCards.form.cardNumber")}
              </label>
              <span className="flex-paginator">
                <div className="stripe-input rounded-pill">
                  <CardNumberElement
                    className="text-xsss"
                    options={CARD_OPTIONS}
                  />
                </div>
                <div className="icons-add-card">
                  <BiSolidCreditCardFront></BiSolidCreditCardFront>
                </div>
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-12 my-2">
            <div className="form-group mb-1">
              <label className="text-color-dark font-xssss fw-bold">
                {propsT("manageCards.form.expire.label")}
              </label>
              <span className="flex-paginator">
                <div className="stripe-input rounded-pill">
                  <CardExpiryElement options={CARD_OPTIONS} />
                </div>
                <div className="icons-add-card">
                  <BiSolidCalendarEvent></BiSolidCalendarEvent>
                </div>
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-12 my-2">
            <div className="form-group mb-1">
              <label className="text-color-dark font-xssss fw-bold">
                {propsT("manageCards.form.cvc.label")}
              </label>
              <span className="flex-paginator">
                <div className="stripe-input rounded-pill">
                  <CardCvcElement options={CARD_OPTIONS} />
                </div>
                <div className="icons-add-card">
                  <MdInfo></MdInfo>
                </div>
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex p-2 justify-content-end">
        <div
          className="pointer fw-bold me-4 font-xsss text-silver"
          onClick={() => handleModal(false)}
        >
          {propsT("common.cancel")}
        </div>

        <div
          className="fw-bold me-2 rounded-pill bg-green font-xssss text-white px-3 py-2 pointer"
          onClick={handleSubmit}
          disabled
        >
          {propsT("manageCards.addCard")}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewCardModal;
