import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AWS_URL } from "../components/config/constants";
const width = window.innerWidth;
var isMobile = width <= 512;

class PublicLayout extends Component {
  render() {
    return (
      <div className="main-wrap" onContextMenu={(e) => e.preventDefault()}>
        <div className="row">
          <div className="col-xl-12 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card overflow-scroll-mobile">
              <div className="card-body rounded-0 text-center">
                <Link
                  to="/"
                  style={{
                    paddingLeft: isMobile ? "0px" : "0px",
                    gap: "7.24px",
                  }}
                >
                  <img
                    className="logo-red-circle"
                    src={`${AWS_URL}logo/logoRedCircle.png`}
                    alt=""
                  ></img>
                  <img
                    className="logo-red-circle"
                    src={`${AWS_URL}logo/${
                      localStorage.getItem("theme") === "theme-light"
                        ? "RedCircleBlack.png"
                        : "RedCircleRed.png"
                    }`}
                    alt=""
                  ></img>
                </Link>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PublicLayout;
