export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
export const INSTAGRAM_REGEX =
  /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\\.]+)/;
export const TWITTER_REGEX =
  /(?:(?:http|https):\/\/)?(?:www\.)?(?:twitter\.com)\/@?([A-Za-z0-9-_\\.]+)/;
export const SNAPCHAT_REGEX =
  /(?:(?:http|https):\/\/)?(?:www\.)?(?:snapchat\.com)\/add\/([A-Za-z0-9-_\\.]+)\/?/;
export const REDDIT_REGEX =
  /(?:(?:http|https):\/\/)?(?:www\.)?(?:[a-z]+\.)?(?:reddit\.com)\/(?:u(?:ser)?)\/([A-Za-z0-9-_\\.]+)/;
export const TELEGRAM_REGEX =
  /(?:(?:http|https):\/\/)?(?:www\.)?(?:t(?:elegram)?\.me|telegram\.org)\/([A-Za-z0-9-_\\.]+)/;
export const USERNAME_REGEX = /^[a-zA-Z0-9._]+$/;
export const TWITCH_REGEX =
  /^(https?:\/\/)?(www\.)?twitch\.tv\/[\w-]+(\/\w+)?$/;
export const YOUTUBE_REGEX =
  /^(https?:\/\/)?(www\.)?youtube\.com\/(c\/)?[\w-]+(\/\w+)?$/;
export const AMAZON_REGEX =
  /^(https?:\/\/)?(www\.)?amazon\.(com|ca|co\.uk)\/[\w-]+(\/\w+)?$/;
export const TIKTOK_REGEX =
  /^(https?:\/\/)?(www\.)?tiktok\.com\/@[\w-]+(\/\w+)?$/;
export const SPOTIFY_REGEX =
  /^(https?:\/\/)?(open\.spotify\.com\/(user|playlist)\/|spotify:)([\w\d]+)/;
