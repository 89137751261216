import React, { Component, Fragment } from "react";
import ClipService from "../../../services/ClipService.js";
import GeneralService from "../../../services/GeneralService.js";
import BookmarkService from "../../../services/BookmarkService.js";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Image, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { BsFilter } from "react-icons/bs";
import { FaXmark } from "react-icons/fa6";
import {
  REDUX_LOCAL_STORE_KEY,
  USER_TYPES,
} from "../../../components/config/constants";
import Carousel from "react-elastic-carousel";
import { BiDollarCircle } from "react-icons/bi";
import { FiMessageSquare } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import Stories from "react-insta-stories";
import { BsThreeDots } from "react-icons/bs";
import { GoAlertFill } from "react-icons/go";
import { Link } from "react-router-dom";
import TipModal from "../Tip/TipModal";
import { BiLike, BiShareAlt, BiBookmark, BiSolidLockAlt } from "react-icons/bi";
import { FormatEmojii } from "../../../components/helper/FormatEmojii";
import { BsBookmarkFill } from "react-icons/bs";
import ReportModal from "../../../components/common/commonModal/ReportModal.jsx";
import { ImPlay3, ImCheckmark } from "react-icons/im";
import { getUserProfilePic } from "../../../components/helper/GeneralHelper.js";
import SingleCommentClip from "../../../components/common/commonModal/SingleCommentClip.jsx";
import AccountVerifyTick from "../../../components/common/AccountVerifyTick.jsx";
import DefaultAvatar from "../../../components/common/DefaultAvatar.jsx";
import EmojiList from "../../../components/common/EmojiList.jsx";
import InfiniteScroll from "react-infinite-scroll-component";

class Clip extends Component {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
    this.width = window.innerWidth;
    this.isMobile = this.width <= 512;
    this.isTab = this.width <= 9999;
    this.state = {
      showFilters: false,
      clips: [],
      clipsUrl: [],
      currentClip: 0,
      showOption: false,
      isClipMuted: false,
      showModalTip: false,
      showModalReport: false,
      showModalComment: false,
      commentList: [],
      commentText: "",
      emojisList: JSON.parse(localStorage.getItem("emojis")),
      showAddReactionDesk: false,
      showAddReactionMobile: false,
      showAddReactionComment: false,
      isReply: false,
      targetReply: "",
      targetReplyId: "",
      typeApiClips: 3, //0=>BestSellers 1=>More seen 2=>New 3=>Random
      categoryList: [],
      myCategory: [],
      isFree: false,
      isDiscounted: false,
      isDescending: true,
      currentPage: 1,
      maxPage: 0,
      isClipPaused: undefined,
      canScroll: true,
      totalComment: 0,
      commentPage: 0,
      commentLimit: 10,
    };
  }

  componentDidMount = () => {
    window.addEventListener("click", this.handleClick);
    window.addEventListener("touch", this.handleTouch);
    this.updateListOfClip();
    this.getAllCategory();
  };

  componentWillUnmount = () => {
    window.removeEventListener("click", this.handleClick);
    window.removeEventListener("touch", this.handleTouch);
  };

  handleClick = (e) => {
    const filter = document.getElementById("filterId");
    const filterCard = document.getElementById("filterIdCard");
    if (!filterCard.contains(e.target) && !filter.contains(e.target)) {
      this.closeFilter();
    }
    if (e.target.className === "containter-button-mobile-pause") {
      this.setState({ isClipPaused: !this.state.isClipPaused }, () =>
        this.togglePauseAllVideos()
      );
    }
    this.controlReactionModal(e);
  };
  handleTouch = (e) => {
    console.log("touch");
    if (e.target.className === "containter-button-mobile-pause") {
      this.setState({ isClipPaused: !this.state.isClipPaused }, () =>
        this.togglePauseAllVideos()
      );
    }
    this.controlReactionModal(e);
  };

  controlReactionModal = (e) => {
    if (e.target.id === "openReactionModal" || e.target.id === "emojisModal") {
    } else {
      this.setState({ showAddReactionMobile: false });
    }
  };

  closeFilter = () => {
    this.setState({ showFilters: false });
  };

  getMostViewedClip = () => {
    let params = {
      limit: 20,
      page: this.state.currentPage,
      categoryIds: this.state.myCategory,
      orderBy: this.state.isDescending ? "DSC" : "ASC",
      ...(this.state.isFree === true && { free: true }),
      ...(this.state.isDiscounted === true && { onSale: true }),
      // free:this.state.isFree,
      // onSale:this.state.isDiscounted
    };
    ClipService.mostViewedClip({ ...params })
      .then((res) => {
        let fixedArrayClips = res.data.data[0].data.map((value) => ({
          url:
            value.clipMedia?.name === undefined
              ? value.clipMedia?.thumbnailUrl
              : value.clipMedia?.clipUrl,
          thumbNailImage: value.clipMedia?.thumbnailUrl,
          id: value.creatorDetails._id,
          clipId: value._id,
          type:
            (value.locked ? value.locked : false) === false ? "video" : null,
          creatorDetails: value.creatorDetails,
          description: value.description,
          bookmark: value.userBookmark ? true : false,
          emojiId: value.userReaction ? value.userReaction.uniCode : null,
          locked: value.locked ? value.locked : false,
          price: value.price,
        }));
        this.setState({
          clips:
            1 > 0
              ? this.state.currentPage > 1
                ? [...this.state.clips, ...res.data.data[0].data]
                : [...res.data.data[0].data]
              : this.state.currentPage > 1
              ? [
                  { blank: true },
                  ...this.state.clips.slice(1, this.state.clips.length - 1),
                  ...res.data.data[0].data,
                  { blank: true },
                ]
              : [{ blank: true }, ...res.data.data[0].data, { blank: true }],
          clipsUrl:
            1 > 0
              ? this.state.currentPage > 1
                ? [...this.state.clipsUrl, ...fixedArrayClips]
                : [...fixedArrayClips]
              : this.state.currentPage > 1
              ? [
                  { blank: true },
                  ...this.state.clipsUrl.slice(
                    1,
                    this.state.clipsUrl.length - 1
                  ),
                  ...fixedArrayClips,
                  { blank: true },
                ]
              : [{ blank: true }, ...fixedArrayClips, { blank: true }],
        });
      })
      .catch((err) => toast.error(err.message));
  };

  handleMouseWheel = (e) => {
    if (this.state.canScroll) {
      this.setState({ canScroll: false, isClipPaused: false, commentList: [] });
      const delta = Math.max(-1, Math.min(1, e.deltaY));
      if (this.state.currentClip + delta >= this.state.clips.length) {
        this.props.history.push("/");
      } else {
        if (this.state.currentClip + delta >= 0) {
          this.carouselRef.goTo(this.state.currentClip + delta);
          this.setState(
            {
              isClipPaused: false,
              currentClip: this.state.currentClip + delta,
            },
            () => {
              this.muteAllVideos();
            }
          );
        }
      }
      setTimeout(() => {
        this.setState({ canScroll: true });
      }, 750);
    } else {
    }
  };

  getRandomClip = () => {
    let params = {
      limit: 20,
      page: this.state.currentPage,
      categoryIds: this.state.myCategory,
      // ...(this.state.isFree === true && {free:true}),
      // ...(this.state.isDiscounted === true && {onSale:true}),
    };
    ClipService.randomClip({ ...params })
      .then((res) => {
        let fixedArrayClips = res.data.data[0].data.map((value) => ({
          url:
            value.clipMedia?.name === undefined
              ? value.clipMedia?.thumbnailUrl
              : value.clipMedia?.clipUrl,
          thumbNailImage: value.clipMedia?.thumbnailUrl,
          id: value.creatorDetails._id,
          clipId: value._id,
          type:
            (value.locked ? value.locked : false) === false ? "video" : null,
          creatorDetails: value.creatorDetails,
          description: value.description,
          bookmark: value.userBookmark ? true : false,
          emojiId: value.userReaction ? value.userReaction.uniCode : null,
          locked: value.locked ? value.locked : false,
          price: value.price,
        }));
        this.setState({
          clips:
            1 > 0
              ? this.state.currentPage > 1
                ? [...this.state.clips, ...res.data.data[0].data]
                : [...res.data.data[0].data]
              : this.state.currentPage > 1
              ? [
                  { blank: true },
                  ...this.state.clips.slice(1, this.state.clips.length - 1),
                  ...res.data.data[0].data,
                  { blank: true },
                ]
              : [{ blank: true }, ...res.data.data[0].data, { blank: true }],
          clipsUrl:
            1 > 0
              ? this.state.currentPage > 1
                ? [...this.state.clipsUrl, ...fixedArrayClips]
                : [...fixedArrayClips]
              : this.state.currentPage > 1
              ? [
                  { blank: true },
                  ...this.state.clipsUrl.slice(
                    1,
                    this.state.clipsUrl.length - 1
                  ),
                  ...fixedArrayClips,
                  { blank: true },
                ]
              : [{ blank: true }, ...fixedArrayClips, { blank: true }],
        });
      })
      .catch((err) => toast.error(err.message));
  };

  getNewestClip = () => {
    let params = {
      limit: 20,
      page: this.state.currentPage,
      categoryIds: this.state.myCategory,
      ...(this.state.isFree === true && { free: true }),
      ...(this.state.isDiscounted === true && { onSale: true }),
      orderBy: this.state.isDescending ? "DSC" : "ASC",
    };
    ClipService.newClip({ ...params })
      .then((res) => {
        let fixedArrayClips = res.data.data[0].data.map((value) => ({
          url:
            value.clipMedia?.name === undefined
              ? value.clipMedia?.thumbnailUrl
              : value.clipMedia?.clipUrl,
          thumbNailImage: value.clipMedia?.thumbnailUrl,
          id: value.creatorDetails._id,
          clipId: value._id,
          type:
            (value.locked ? value.locked : false) === false ? "video" : null,
          creatorDetails: value.creatorDetails,
          description: value.description,
          bookmark: value.userBookmark ? true : false,
          emojiId: value.userReaction ? value.userReaction.uniCode : null,
          locked: value.locked ? value.locked : false,
          price: value.price,
        }));
        this.setState({
          clips:
            1 > 0
              ? this.state.currentPage > 1
                ? [...this.state.clips, ...res.data.data[0].data]
                : [...res.data.data[0].data]
              : this.state.currentPage > 1
              ? [
                  { blank: true },
                  ...this.state.clips.slice(1, this.state.clips.length - 1),
                  ...res.data.data[0].data,
                  { blank: true },
                ]
              : [{ blank: true }, ...res.data.data[0].data, { blank: true }],
          clipsUrl:
            1 > 0
              ? this.state.currentPage > 1
                ? [...this.state.clipsUrl, ...fixedArrayClips]
                : [...fixedArrayClips]
              : this.state.currentPage > 1
              ? [
                  { blank: true },
                  ...this.state.clipsUrl.slice(
                    1,
                    this.state.clipsUrl.length - 1
                  ),
                  ...fixedArrayClips,
                  { blank: true },
                ]
              : [{ blank: true }, ...fixedArrayClips, { blank: true }],
        });
      })
      .catch((err) => toast.error(err.message));
  };

  leaveLike = (clipId, index, indexReply) => {
    let params = {
      clipCommentId: clipId,
    };
    ClipService.leaveLikeComment({ ...params })
      .then((res) => {
        toast.success(this.props.t("clip.commentVotedSuccessfully"));
        let commentList = this.state.commentList;
        if (indexReply !== undefined) {
          commentList[index].replies[indexReply].totalCommentReactions =
            commentList[index].replies[indexReply].totalCommentReactions +
            (commentList[index].replies[indexReply].likeDetails ? -1 : +1);
          commentList[index].replies[indexReply].likeDetails
            ? (commentList[index].replies[indexReply].likeDetails = undefined)
            : (commentList[index].replies[indexReply].likeDetails = {
                id: "id",
              });
          this.setState({ commentList });
        } else {
          commentList[index].totalCommentReactions =
            commentList[index].totalCommentReactions +
            (commentList[index].likeDetails ? -1 : +1);
          commentList[index].likeDetails
            ? (commentList[index].likeDetails = undefined)
            : (commentList[index].likeDetails = { id: "id" });
          this.setState({ commentList });
        }
      })
      .catch((err) => console.log(err.message));
  };

  getMostSellClip = () => {
    let params = {
      limit: 20,
      page: this.state.currentPage,
      categoryIds: this.state.myCategory,
      orderBy: this.state.isDescending ? "DSC" : "ASC",
      ...(this.state.isDiscounted === true && { onSale: true }),
    };
    ClipService.mostSellClip({ ...params })
      .then((res) => {
        let fixedArrayClips = res.data.data[0].data.map((value) => ({
          url:
            value.clipMedia?.name === undefined
              ? value.clipMedia?.thumbnailUrl
              : value.clipMedia?.clipUrl,
          thumbNailImage: value.clipMedia?.thumbnailUrl,
          id: value.creatorDetails._id,
          clipId: value._id,
          type:
            (value.locked ? value.locked : false) === false ? "video" : null,
          creatorDetails: value.creatorDetails,
          description: value.description,
          bookmark: value.userBookmark ? true : false,
          emojiId: value.userReaction ? value.userReaction.uniCode : null,
          locked: value.locked ? value.locked : false,
          price: value.price,
        }));
        this.setState({
          clips:
            1 > 0
              ? this.state.currentPage > 1
                ? [...this.state.clips, ...res.data.data[0].data]
                : [...res.data.data[0].data]
              : this.state.currentPage > 1
              ? [
                  { blank: true },
                  ...this.state.clips.slice(1, this.state.clips.length - 1),
                  ...res.data.data[0].data,
                  { blank: true },
                ]
              : [{ blank: true }, ...res.data.data[0].data, { blank: true }],
          clipsUrl:
            1 > 0
              ? this.state.currentPage > 1
                ? [...this.state.clipsUrl, ...fixedArrayClips]
                : [...fixedArrayClips]
              : this.state.currentPage > 1
              ? [
                  { blank: true },
                  ...this.state.clipsUrl.slice(
                    1,
                    this.state.clipsUrl.length - 1
                  ),
                  ...fixedArrayClips,
                  { blank: true },
                ]
              : [{ blank: true }, ...fixedArrayClips, { blank: true }],
        });
      })
      .catch((err) => toast.error(err.message));
  };

  goToStory = (storyIndex) => {
    this.setState({ isClipPaused: false, commentList: [] });
    if (storyIndex >= this.state.clips.length) {
      this.props.history.push("/");
    } else {
      this.carouselRef.goTo(storyIndex);
      this.setState({ isClipPaused: false, currentClip: storyIndex }, () => {
        this.muteAllVideos();
      });
    }
  };

  addRemoveBookMark = (index) => {
    let clipsUrl = this.state.clipsUrl;
    clipsUrl[index].bookmark = !clipsUrl[index].bookmark;
    this.setState({ clipsUrl: clipsUrl });

    let params = {
      type: "CLIP",
      contentId: this.state.clipsUrl[this.state.currentClip].clipId,
    };
    BookmarkService.addRemoveBookMarkOnPost({ ...params })
      .then((res) => {
        toast.success(
          this.props.t(
            res.data.message === "BookMark removed successfully."
              ? "clip.removedBookMark"
              : "clip.addedBookMark"
          )
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  getCommentClip = () => {
    this.setState({ commentPage: 0 });
    let params = {
      clipId: this.state.clipsUrl[this.state.currentClip].clipId,
      limit: this.state.commentLimit,
    };
    ClipService.getCommentClip({ ...params })
      .then((value) => {
        this.setState({
          commentList: value?.data?.data,
          totalComment: value.data?.totalCount,
        });
      })
      .catch((err) => console.log(err.message));
  };

  loadMoreComment = () => {
    this.setState({ commentPage: this.state.commentPage + 1 }, () => {
      let params = {
        clipId: this.state.clipsUrl[this.state.currentClip].clipId,
        limit: this.state.commentLimit,
        page: this.state.commentPage,
      };
      ClipService.getCommentClip({ ...params })
        .then((value) => {
          this.setState({
            commentList: [...this.state.commentList, ...value.data.data],
            totalComment: value.data.totalCount,
          });
        })
        .catch((err) => console.log(err.message));
    });
  };

  muteAllVideos = () => {
    const videoElements = document.getElementsByTagName("video");
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i];
      videoElement.muted = this.state.isClipMuted;
    }
  };

  togglePauseAllVideos = () => {
    const videoElements = document.getElementsByTagName("video");
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i];
      if (this.state.isClipPaused) {
        videoElement.pause(); // Metti in pausa i video
      } else {
        videoElement.play(); // Fai ripartire i video
      }
    }
  };

  addComment = () => {
    let params = {
      clipId: this.state.clipsUrl[this.state.currentClip].clipId,
      commentText: this.state.commentText,
      isReply: this.state.isReply,
      commentId: this.state.targetReplyId,
    };

    ClipService.addCommentClip({ ...params })
      .then((value) => {
        this.setState({
          commentText: "",
          isReply: false,
          targetReply: "",
          targetReplyId: "",
        });
        this.getCommentClip();
      })
      .catch((err) => console.log(err.message));
  };

  muteClips = () => {
    this.setState({ isClipMuted: !this.state.isClipMuted }, () =>
      this.muteAllVideos()
    );
  };

  addReactionClip = (clipId, emojiId, index, uniCode) => {
    let clipsUrl = this.state.clipsUrl;
    if (clipsUrl[index].emojiId === uniCode) {
      clipsUrl[index].emojiId = null;
    } else {
      clipsUrl[index].emojiId = uniCode;
    }
    this.setState({
      showAddReactionMobile: false,
      showAddReactionDesk: false,
      clipsUrl: clipsUrl,
    });
    let params = {
      clipId: clipId,
      emojiId: emojiId,
    };
    ClipService.addReactionClip({ ...params })
      .then((res) => {
        // toast.success(this.props.t("clip.clipVotedSuccessfully"));
      })
      .catch((err) => console.log(err.message));
  };

  getAllCategory = () => {
    this.setState({ loading: true });
    GeneralService.getAllCategory()
      .then((res) => {
        this.setState({ categoryList: res.data.data }, () => {});
      })
      .catch((err) => {});
  };

  addClipView = (value) => {
    let params = {
      clipId: value.clipId,
    };
    ClipService.addClipView({ ...params })
      .then((res) => {
        this.setState(
          { isClipPaused: false, currentClip: this.state.currentClip + 1 },
          () => this.goToStory(this.state.currentClip)
        );
      })
      .catch((err) => {
        this.setState(
          { isClipPaused: false, currentClip: this.state.currentClip + 1 },
          () => this.goToStory(this.state.currentClip)
        );
      });
  };

  updateCategory = (categoryId) => {
    this.setState(
      (prevState) => {
        if (prevState.myCategory.includes(categoryId)) {
          return {
            myCategory: prevState.myCategory.filter((id) => id !== categoryId),
          };
        } else {
          return {
            myCategory: [...prevState.myCategory, categoryId],
          };
        }
      },
      () => this.updateListOfClip()
    );
  };

  updateListOfClip = (scroll) => {
    if (scroll) {
      switch (this.state.typeApiClips) {
        case 0:
          this.getMostSellClip();
          break;
        case 1:
          this.getMostViewedClip();
          break;
        case 2:
          this.getNewestClip();
          break;
        case 3:
          this.getRandomClip();
          break;

        default:
          break;
      }
    } else {
      this.setState({ currentClip: 0, commentList: [], currentPage: 1 }, () => {
        this.carouselRef.goTo(0);
        switch (this.state.typeApiClips) {
          case 0:
            this.getMostSellClip();
            break;
          case 1:
            this.getMostViewedClip();
            break;
          case 2:
            this.getNewestClip();
            break;
          case 3:
            this.getRandomClip();
            break;

          default:
            break;
        }
      });
    }
  };

  closeCommnetModal = () => {
    this.setState({
      showModalComment: false,
      commentText: "",
      isReply: false,
      targetReply: "",
      targetReplyId: "",
    });
  };

  render() {
    const width = window.innerWidth;
    var isMobile = width <= 512;

    return (
      <Fragment>
        <div className="emojis-fix-clip finalContainer main-content-clip right-chat-active pad-fix-clip flex-paginator mobile-clips">
          <div
            id={`filterIdCard`}
            className={`${
              this.state.showFilters === false && "display-none"
            } div-filter-clip fc-view filter-clip input-search-clip nobar`}
          >
            <div
              style={{ marginBottom: "20px" }}
              className="mt-top32px flex-paginator"
            >
              <span className="filter-text w-50 align-center">
                {this.props.t("common.filter")}
              </span>
              <span
                onClick={() => this.setState({ showFilters: false })}
                className="x-icon-filter w-50 align-center"
              >
                <FaXmark></FaXmark>
              </span>
            </div>
            <form action="#">
              <span className="mainlabel text-filter-clip-second-bar">
                {this.props.t("clip.sortBy")}
              </span>
              <div className="mb-6px mb-2 d-flex align-items-center">
                <input
                  type="radio"
                  className="cursor-pointer active-checkbox-clip"
                  id="Black"
                  name="payment-method"
                  checked={!this.state.isDescending}
                  onClick={() =>
                    this.setState({ isDescending: false }, () =>
                      this.updateListOfClip()
                    )
                  }
                />

                <label
                  class={`payment-label  fw-600 text-black ms-2 bookmarkText`}
                >
                  {this.props.t("common.ascending")}
                </label>
              </div>
              <div className="mb-6px mb-2 d-flex align-items-center">
                <input
                  type="radio"
                  className="cursor-pointer"
                  id="Black"
                  name="payment-method"
                  checked={this.state.isDescending}
                  onClick={() =>
                    this.setState({ isDescending: true }, () =>
                      this.updateListOfClip()
                    )
                  }
                />
                <label
                  class={`payment-label  fw-600 text-black ms-2 bookmarkText`}
                >
                  {this.props.t("common.descending")}
                </label>
              </div>
            </form>

            <form style={{ marginTop: "24px" }} action="#">
              <span className="mainlabel text-filter-clip-second-bar">
                {this.props.t("clip.clips")}
              </span>
              <div className="mb-6px mb-2 d-flex align-items-center">
                {this.state.typeApiClips === 0 && (
                  <div
                    style={{
                      position: "relative",
                      width: "0px",
                      height: "0px",
                    }}
                    onClick={() => {
                      this.state.typeApiClips === 0
                        ? this.setState(
                            { typeApiClips: 3, isFree: false },
                            () => this.updateListOfClip()
                          )
                        : this.setState(
                            { typeApiClips: 0, isFree: false },
                            () => this.updateListOfClip()
                          );
                    }}
                  >
                    <ImCheckmark className="checkbox-check-icon"></ImCheckmark>
                  </div>
                )}
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  id="Black"
                  name="payment-method"
                  checked={this.state.typeApiClips === 0 ? true : false}
                  onClick={() => {
                    this.state.typeApiClips === 0
                      ? this.setState({ typeApiClips: 3, isFree: false }, () =>
                          this.updateListOfClip()
                        )
                      : this.setState({ typeApiClips: 0, isFree: false }, () =>
                          this.updateListOfClip()
                        );
                  }}
                />

                <label
                  class={`payment-label  fw-600 text-black ms-2 bookmarkText`}
                >
                  {this.props.t("clip.bestSellers")}
                </label>
              </div>
              <div className="mb-6px mb-2 d-flex align-items-center">
                {this.state.typeApiClips === 1 && (
                  <div
                    style={{
                      position: "relative",
                      width: "0px",
                      height: "0px",
                    }}
                    onClick={() => {
                      this.state.typeApiClips === 1
                        ? this.setState({ typeApiClips: 3 }, () =>
                            this.updateListOfClip()
                          )
                        : this.setState({ typeApiClips: 1 }, () =>
                            this.updateListOfClip()
                          );
                    }}
                  >
                    <ImCheckmark className="checkbox-check-icon"></ImCheckmark>
                  </div>
                )}
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  id="Black"
                  name="payment-method"
                  checked={this.state.typeApiClips === 1 ? true : false}
                  onClick={() => {
                    this.state.typeApiClips === 1
                      ? this.setState({ typeApiClips: 3 }, () =>
                          this.updateListOfClip()
                        )
                      : this.setState({ typeApiClips: 1 }, () =>
                          this.updateListOfClip()
                        );
                  }}
                />
                <label
                  class={`payment-label  fw-600 text-black ms-2 bookmarkText`}
                >
                  {this.props.t("clip.moreSeen")}
                </label>
              </div>
              <div className="mb-6px mb-2 d-flex align-items-center">
                {this.state.typeApiClips === 2 && (
                  <div
                    style={{
                      position: "relative",
                      width: "0px",
                      height: "0px",
                    }}
                    onClick={() => {
                      this.state.typeApiClips === 2
                        ? this.setState({ typeApiClips: 3 }, () =>
                            this.updateListOfClip()
                          )
                        : this.setState({ typeApiClips: 2 }, () =>
                            this.updateListOfClip()
                          );
                    }}
                  >
                    <ImCheckmark className="checkbox-check-icon"></ImCheckmark>
                  </div>
                )}
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  id="Black"
                  name="payment-method"
                  checked={this.state.typeApiClips === 2 ? true : false}
                  onClick={() => {
                    this.state.typeApiClips === 2
                      ? this.setState({ typeApiClips: 3 }, () =>
                          this.updateListOfClip()
                        )
                      : this.setState({ typeApiClips: 2 }, () =>
                          this.updateListOfClip()
                        );
                  }}
                />
                <label
                  class={`payment-label  fw-600 text-black ms-2 bookmarkText`}
                >
                  {this.props.t("clip.new")}
                </label>
              </div>
            </form>

            <form style={{ marginTop: "24px" }} action="#">
              <span className="mainlabel text-filter-clip-second-bar">
                {this.props.t("clip.byPrice")}
              </span>
              <div className="mb-6px mb-2 d-flex align-items-center">
                {this.state.isDiscounted && (
                  <div
                    style={{
                      position: "relative",
                      width: "0px",
                      height: "0px",
                    }}
                    onClick={() =>
                      this.setState(
                        { isDiscounted: !this.state.isDiscounted },
                        () => this.updateListOfClip()
                      )
                    }
                  >
                    <ImCheckmark className="checkbox-check-icon"></ImCheckmark>
                  </div>
                )}
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  id="Black"
                  name="payment-method"
                  checked={this.state.isDiscounted}
                  onClick={() =>
                    this.setState(
                      { isDiscounted: !this.state.isDiscounted },
                      () => this.updateListOfClip()
                    )
                  }
                />

                <label
                  class={`payment-label  fw-600 text-black ms-2 bookmarkText`}
                >
                  {this.props.t("clip.discounted")}
                </label>
              </div>
              <div className="mb-6px mb-2 d-flex align-items-center">
                {this.state.isFree && (
                  <div
                    style={{
                      position: "relative",
                      width: "0px",
                      height: "0px",
                    }}
                    onClick={() =>
                      this.setState({ isFree: !this.state.isFree }, () =>
                        this.updateListOfClip()
                      )
                    }
                  >
                    <ImCheckmark className="checkbox-check-icon"></ImCheckmark>
                  </div>
                )}
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  id="Black"
                  name="payment-method"
                  disabled={this.state.typeApiClips === 0 ? true : false}
                  checked={this.state.isFree}
                  onClick={() =>
                    this.setState({ isFree: !this.state.isFree }, () =>
                      this.updateListOfClip()
                    )
                  }
                />
                <label
                  class={`payment-label  fw-600 text-black ms-2 bookmarkText`}
                >
                  {this.props.t("clip.free")}
                </label>
              </div>
            </form>

            <form style={{ marginTop: "24px" }} action="#">
              <span className="mainlabel text-filter-clip-second-bar">
                {this.props.t("clip.byCategory")}
              </span>
              {this.state.categoryList.map((value, index) => (
                <div className="mb-6px mb-2 d-flex align-items-center">
                  <div
                    style={{
                      position: "relative",
                      width: "0px",
                      height: "0px",
                    }}
                  >
                    <ImCheckmark
                      onClick={() => this.updateCategory(value._id)}
                      className="checkbox-check-icon"
                    ></ImCheckmark>
                  </div>
                  <input
                    onChange={() => this.updateCategory(value._id)}
                    type="checkbox"
                    checked={this.state.myCategory.includes(value._id)}
                    className="cursor-pointer"
                    id="Black"
                    name="payment-method"
                  />
                  <label
                    class={`payment-label  fw-600 text-black ms-2 bookmarkText`}
                  >
                    {this.props.t(`category.${value.categoryName}`)}
                  </label>
                </div>
              ))}
            </form>
          </div>
          <>
            {this.isMobile === false ? (
              <div
                id="filterId"
                className={`${
                  this.state.showFilters && "display-none"
                } div-filter-clip absolute-center nobar`}
              >
                <Button
                  onClick={() => this.setState({ showFilters: true })}
                  className="width99px wallet-schedule-button fc-view padding-top-4rem"
                >
                  {this.props.t("common.filter")}
                  <div style={{ width: "2px" }}></div> <BsFilter></BsFilter>
                </Button>
              </div>
            ) : (
              <Button
                id="filterId"
                onClick={() => this.setState({ showFilters: true })}
                className={`${
                  this.state.showFilters && "display-none"
                } width99px wallet-schedule-button fc-view z-index-fix-mobile-clip`}
                style={{ width: "100px", marginTop: "18px" }}
              >
                <div style={{ width: "2px" }}></div> <BsFilter></BsFilter>
              </Button>
            )}
          </>
          <div onWheel={this.handleMouseWheel} className={`container-clip`}>
            <Carousel
              verticalMode={true}
              ref={(ref) => (this.carouselRef = ref)}
              enableSwipe={true}
              enableMouseSwipe={true}
              showArrows={false}
              pagination={false}
              breakPoints={[
                {
                  width: 0,
                  itemsToShow: this.isMobile ? 1 : 1,
                },
              ]}
              onChange={(event) =>
                this.setState(
                  {
                    showOption: false,
                    currentClip: event.index,
                    // isClipPaused: false,
                  },
                  () => {
                    this.muteAllVideos();
                    if (
                      this.state.currentClip >
                      this.state.currentPage * 20 - 7
                    ) {
                      this.setState(
                        {
                          currentPage: this.state.currentPage + 1,
                        },
                        () => this.updateListOfClip(true)
                      );
                    }
                  }
                )
              }
            >
              {this.state.clipsUrl.map((value, index) => (
                <div>
                  {index === this.state.currentClip ? (
                    <div className="clip-card-current flex-paginator">
                      {isMobile && this.state.isClipPaused && (
                        <div className="containter-button-mobile-pause">
                          <div className="pause-button-clip">
                            <div className="pause-internal-button-clip">
                              <ImPlay3
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  color: "#FFFFFF",
                                  paddingLeft: "4px",
                                }}
                              ></ImPlay3>
                            </div>
                          </div>
                        </div>
                      )}
                      <span className="relative">
                        <span className={`clip-creator-icon`}>
                          <div className={`flex-paginator`}>
                            <Link
                              to={`/${value.creatorDetails.userName}`}
                              className="text-decoration-none text-black flex-paginator"
                            >
                              <span
                                style={{ width: "32px", height: "32px" }}
                                className="picture-story"
                              >
                                <DefaultAvatar
                                  className={`story-box-container hover`}
                                  width={"32px"}
                                  height={"32px"}
                                  userName={value.creatorDetails.name}
                                  id={value.creatorDetails._id}
                                >
                                  {value.creatorDetails.profilePicture ? (
                                    <Image
                                      width={32}
                                      height={32}
                                      title={value.creatorDetails.name}
                                      alt={value.creatorDetails.name}
                                      className={`story-box-container hover`}
                                      src={getUserProfilePic(
                                        value.creatorDetails._id,
                                        value.creatorDetails.profilePicture
                                      )}
                                    ></Image>
                                  ) : null}
                                </DefaultAvatar>
                              </span>
                              <span className="story-creators-name">
                                {value.creatorDetails.name}{" "}
                                {value?.creatorDetails?.isCreatorVerified ? (
                                  <span
                                    style={{
                                      marginLeft: "2px",
                                      verticalAlign: "text-bottom",
                                    }}
                                  >
                                    <AccountVerifyTick width="15px"></AccountVerifyTick>
                                  </span>
                                ) : null}
                              </span>
                            </Link>
                          </div>
                          {value.locked ? null : (
                            <div className="description-clip">
                              {value.description}
                            </div>
                          )}
                          {this.isMobile && (
                            <div className={`flex-paginator icons-clip-mobile`}>
                              {!value.locked && (
                                <div className="icon-for-clip-mobile">
                                  {value.emojiId === null ? (
                                    <button
                                      onBlur={(event) => {
                                        this.setState({
                                          showAddReactionMobile: false,
                                        });
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          showAddReactionMobile:
                                            !this.state.showAddReactionMobile,
                                        })
                                      }
                                      className="fix-button-like"
                                    >
                                      <BiLike
                                        color="white"
                                        id="openReactionModal"
                                      ></BiLike>
                                      <EmojiList
                                        show={this.state.showAddReactionMobile}
                                        style={{ bottom: "10px" }}
                                        emojisList={this.state.emojisList}
                                        onClickOutside={() => {
                                          this.setState({
                                            showAddReactionMobile: false,
                                          });
                                        }}
                                        onAddOrRemoveReaction={(
                                          emojiId,
                                          emojiUnicode
                                        ) => {
                                          this.addReactionClip(
                                            value.clipId,
                                            emojiId,
                                            index,
                                            emojiUnicode
                                          );
                                        }}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      onBlur={(event) => {
                                        this.setState({
                                          showAddReactionMobile: false,
                                        });
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          showAddReactionMobile:
                                            !this.state.showAddReactionMobile,
                                        })
                                      }
                                      className="fix-button-like"
                                    >
                                      <span className="font-lg pointer em font-size-18px">
                                        {FormatEmojii(value.emojiId)}
                                        <EmojiList
                                          show={
                                            this.state.showAddReactionMobile
                                          }
                                          style={{ bottom: "10px" }}
                                          emojisList={this.state.emojisList}
                                          onClickOutside={() => {
                                            this.setState({
                                              showAddReactionMobile: false,
                                            });
                                          }}
                                          onAddOrRemoveReaction={(
                                            emojiId,
                                            emojiUnicode
                                          ) => {
                                            this.addReactionClip(
                                              value.clipId,
                                              emojiId,
                                              index,
                                              emojiUnicode
                                            );
                                          }}
                                        />
                                      </span>
                                    </button>
                                  )}
                                </div>
                              )}
                              {!value.locked && (
                                <div
                                  onClick={() => {
                                    this.getCommentClip();
                                    this.setState({ showModalComment: true });
                                  }}
                                  className="icon-for-clip-mobile"
                                >
                                  <FiMessageSquare></FiMessageSquare>
                                </div>
                              )}
                              <div
                                onClick={() => this.addRemoveBookMark(index)}
                                className="icon-for-clip-mobile"
                              >
                                {value.bookmark ? (
                                  <BsBookmarkFill></BsBookmarkFill>
                                ) : (
                                  <BiBookmark></BiBookmark>
                                )}
                              </div>
                              <div
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    `${
                                      new URL(window.location.href).protocol
                                    }//${
                                      new URL(window.location.href).host
                                    }/single-clip?clipId=${value.clipId}`
                                  );
                                  toast.success(
                                    this.props.t("clip.copiedToClipboard")
                                  );
                                }}
                                className="icon-for-clip-mobile"
                              >
                                <BiShareAlt></BiShareAlt>
                              </div>
                              {
                                <button
                                  onBlur={() =>
                                    this.setState({
                                      showOption: false,
                                    })
                                  }
                                  onClick={() =>
                                    this.setState({
                                      showOption: !this.state.showOption,
                                    })
                                  }
                                  className="fix-button-like icon-for-clip-mobile"
                                >
                                  <div className="icon-for-clip-mobile">
                                    <BsThreeDots
                                      style={{ color: "#FFFFFF" }}
                                    ></BsThreeDots>
                                    {this.state.showOption && (
                                      <div className="option-clip-div">
                                        <div
                                          onClick={() =>
                                            this.setState({
                                              showModalReport: true,
                                            })
                                          }
                                        >
                                          <GoAlertFill
                                            style={{
                                              position: "relative",
                                              top: "-2px",
                                            }}
                                          ></GoAlertFill>{" "}
                                          {this.props.t("clip.report")}
                                        </div>
                                        <div
                                          onClick={() =>
                                            this.setState({
                                              showModalTip: true,
                                            })
                                          }
                                        >
                                          <BiDollarCircle
                                            style={{
                                              position: "relative",
                                              top: "-2px",
                                            }}
                                          ></BiDollarCircle>{" "}
                                          {this.props.t("stories.tip")}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </button>
                              }
                            </div>
                          )}
                        </span>
                      </span>
                      <span
                        className={`disable-reload-clip test-extreme-div-height story-style ${
                          value.locked && "blocked-clip "
                        }`}
                      >
                        <Stories
                          isPaused={
                            this.state.isClipPaused === true ? true : undefined
                          }
                          preventDefault={true}
                          storyContainerStyles={{ overflow: "hidden" }}
                          progressContainerStyles={{ display: "none" }}
                          stories={[value]}
                          defaultInterval={10000}
                          width={
                            this.isMobile
                              ? "100vw"
                              : "calc(86.6666666vh * 0.560897 )"
                          }
                          height={
                            this.isMobile ? "calc(100%-32px)" : "86.6666666vh"
                          }
                          loop={false}
                          onAllStoriesEnd={(event) => {
                            this.addClipView(value);
                          }}
                          onNext={(event) => {
                            event.preventDefault();
                          }}
                        />
                        {value.locked !== true && (
                          <div
                            className={`${
                              isMobile
                                ? "containter-button-mobile-pause"
                                : "containter-button-mobile-pause"
                            }`}
                            name="pause-button"
                          >
                            {isMobile === false && this.state.isClipPaused && (
                              <div className="containter-button-mobile-pause">
                                <div className="pause-button-mobile">
                                  <div className="pause-internal-button-clip">
                                    <ImPlay3 //segnaposto
                                      style={{
                                        width: "36px",
                                        height: "36px",
                                        color: "#FFFFFF",
                                        paddingLeft: "4px",
                                        zIndex: 9999999,
                                      }}
                                    ></ImPlay3>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </span>
                      {value.locked && (
                        <div
                          className={`clip-card-background hover ${
                            this.isMobile ? "blocked-clip" : ""
                          }`}
                          style={{
                            position: "absolute",
                            zIndex: 999,
                            backgroundSize: "cover",
                            backgroundImage: this.isMobile
                              ? `url('${value.thumbNailImage}')`
                              : "",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div
                              className={`hover w-auto ${
                                this.isMobile ? "zIndex9999" : ""
                              }`}
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="clip-lock-box">
                                  <BiSolidLockAlt></BiSolidLockAlt>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="clip-is-locked">
                                  {this.props.t("clip.lockedContent")}
                                </div>
                              </div>
                              <div>
                                <Link
                                  to={{
                                    pathname: "/payment",
                                    state: {
                                      creatorDetails: value.creatorDetails,
                                      creatorPicture: getUserProfilePic(
                                        value.creatorDetails?._id,
                                        value.creatorDetails?.profilePicture,
                                        true,
                                        "0% 0% / cover no-repeat"
                                      ),
                                      price: value.price,
                                      typeOfPayment: 2,
                                      clipId: value.clipId,
                                    },
                                  }}
                                >
                                  <button className="width-button-buy-clip p-0 btn p-2 lh-24 w125 ms-1  d-inline-block rounded-xl creatorButton border-red detailTitle fw-700 text-white">
                                    {this.props.t("clip.unlockContentFor$")}
                                    {`${value.price}`}
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.isMobile === false ? (
                        <div className="container-button-clip">
                          <div className="fix-containter-clip">
                            {JSON.parse(
                              localStorage.getItem(REDUX_LOCAL_STORE_KEY)
                            ).authentication.userData.userType ===
                              USER_TYPES.CREATOR && (
                              <div
                                className="add-clip-button"
                                onClick={() =>
                                  this.props.history.push("/upload-clip")
                                }
                              >
                                <FaPlus></FaPlus>
                              </div>
                            )}
                            <div className="clip-option-button">
                              {!value.locked && (
                                <div className="icon-for-clip">
                                  {value.emojiId === null ? (
                                    <button
                                      onBlur={() =>
                                        this.setState({
                                          showAddReactionDesk: false,
                                        })
                                      }
                                      onClick={() =>
                                        this.setState({
                                          showAddReactionDesk:
                                            !this.state.showAddReactionDesk,
                                        })
                                      }
                                      className="fix-button-like"
                                    >
                                      <BiLike></BiLike>

                                      <EmojiList
                                        show={this.state.showAddReactionDesk}
                                        style={{ left: "-200px" }}
                                        emojisList={this.state.emojisList}
                                        onClickOutside={() => {
                                          this.setState({
                                            showAddReactionDesk: false,
                                          });
                                        }}
                                        onAddOrRemoveReaction={(
                                          emojiId,
                                          emojiUnicode
                                        ) => {
                                          this.addReactionClip(
                                            value.clipId,
                                            emojiId,
                                            index,
                                            emojiUnicode
                                          );
                                        }}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      onBlur={() =>
                                        this.setState({
                                          showAddReactionDesk: false,
                                        })
                                      }
                                      onClick={() =>
                                        this.setState({
                                          showAddReactionDesk:
                                            !this.state.showAddReactionDesk,
                                        })
                                      }
                                      className="fix-button-like"
                                    >
                                      <span
                                        onClick={() =>
                                          this.setState({
                                            showAddReactionDesk:
                                              !this.state.showAddReactionDesk,
                                          })
                                        }
                                        className="font-lg pointer em font-size-18px"
                                      >
                                        {FormatEmojii(value.emojiId)}
                                        <EmojiList
                                          show={this.state.showAddReactionDesk}
                                          style={{ left: "-200px" }}
                                          emojisList={this.state.emojisList}
                                          onClickOutside={() => {
                                            this.setState({
                                              showAddReactionDesk: false,
                                            });
                                          }}
                                          onAddOrRemoveReaction={(
                                            emojiId,
                                            emojiUnicode
                                          ) => {
                                            this.addReactionClip(
                                              value.clipId,
                                              emojiId,
                                              index,
                                              emojiUnicode
                                            );
                                          }}
                                        />
                                      </span>
                                    </button>
                                  )}
                                </div>
                              )}
                              {!value.locked && (
                                <div
                                  onClick={() =>
                                    this.setState(
                                      { showModalComment: true },
                                      () => this.getCommentClip()
                                    )
                                  }
                                  className="icon-for-clip"
                                >
                                  <FiMessageSquare></FiMessageSquare>
                                </div>
                              )}
                              <div
                                onClick={() => this.addRemoveBookMark(index)}
                                className="icon-for-clip"
                              >
                                {value.bookmark ? (
                                  <BsBookmarkFill></BsBookmarkFill>
                                ) : (
                                  <BiBookmark></BiBookmark>
                                )}
                              </div>
                              <div
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    `${
                                      new URL(window.location.href).protocol
                                    }//${
                                      new URL(window.location.href).host
                                    }/single-clip?clipId=${value.clipId}`
                                  );
                                  toast.success(
                                    this.props.t("clip.copiedToClipboard")
                                  );
                                }}
                                className="icon-for-clip"
                              >
                                <BiShareAlt></BiShareAlt>
                              </div>
                              {
                                <button
                                  onBlur={() =>
                                    this.setState({
                                      showOption: false,
                                    })
                                  }
                                  onClick={() =>
                                    this.setState({
                                      showOption: !this.state.showOption,
                                    })
                                  }
                                  className="fix-button-like"
                                >
                                  <div className="internal-3point">
                                    <BsThreeDots></BsThreeDots>
                                    {this.state.showOption && (
                                      <div className="option-clip-div-desk">
                                        <div
                                          onClick={() =>
                                            this.setState({
                                              showModalReport: true,
                                            })
                                          }
                                          style={{
                                            alignItems: "center",
                                            display: "inline-flex",
                                            justifyContent: "space-evenly",
                                          }}
                                        >
                                          <GoAlertFill
                                            style={{
                                              position: "relative",
                                              top: "-2px",
                                            }}
                                          ></GoAlertFill>{" "}
                                          {this.props.t("clip.report")}
                                        </div>
                                        <div
                                          onClick={() =>
                                            this.setState({
                                              showModalTip: true,
                                            })
                                          }
                                          style={{
                                            alignItems: "center",
                                            display: "inline-flex",
                                            justifyContent: "space-evenly",
                                          }}
                                        >
                                          <BiDollarCircle
                                            style={{
                                              position: "relative",
                                              top: "-2px",
                                            }}
                                          ></BiDollarCircle>{" "}
                                          {this.props.t("stories.tip")}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {JSON.parse(
                            localStorage.getItem(REDUX_LOCAL_STORE_KEY)
                          ).authentication.userData.userType ===
                            USER_TYPES.CREATOR && (
                            <span className="relative">
                              <div
                                onClick={() =>
                                  this.props.history.push("/upload-clip")
                                }
                                className="mobile-add-clip"
                              >
                                <FaPlus></FaPlus>
                              </div>
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {value.blank === true ? (
                        <div className={`clip-card-blank`}></div>
                      ) : (
                        <div
                          onClick={() =>
                            this.setState(
                              { isClipPaused: this.state.isClipPaused },
                              () => this.goToStory(index)
                            )
                          }
                          className={`clip-card-background hover blocked-clip`}
                          style={{
                            backgroundSize: "cover",
                            backgroundImage: `url('${value.thumbNailImage}')`,
                          }}
                        >
                          {value.locked ? (
                            <>
                              <div
                                className={`clip-card-background hover ${
                                  this.isMobile ? "blocked-clip" : ""
                                }`}
                                style={{
                                  position: "absolute",
                                  zIndex: 999,
                                  backgroundSize: "cover",
                                  backgroundImage: this.isMobile
                                    ? `url('${value.thumbNailImage}')`
                                    : "",
                                }}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <div
                                    className={`hover w-auto ${
                                      this.isMobile ? "zIndex9999" : ""
                                    }`}
                                  >
                                    <div className="d-flex justify-content-center align-items-center">
                                      <div className="clip-lock-box">
                                        <BiSolidLockAlt></BiSolidLockAlt>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                      <div className="clip-is-locked">
                                        {this.props.t("clip.lockedContent")}
                                      </div>
                                    </div>

                                    <div>
                                      <Link
                                        to={{
                                          pathname: "/payment",
                                          state: {
                                            creatorDetails:
                                              value.creatorDetails,
                                            creatorPicture: getUserProfilePic(
                                              value.creatorDetails?._id,
                                              value.creatorDetails
                                                ?.profilePicture,
                                              true,
                                              "0% 0% / cover no-repeat"
                                            ),
                                            price: value.price,
                                            typeOfPayment: 2,
                                            clipId: value.clipId,
                                          },
                                        }}
                                      >
                                        <button className="width-button-buy-clip p-0 btn p-2 lh-24 w125 ms-1  d-inline-block rounded-xl creatorButton border-red detailTitle fw-700 text-white">
                                          {this.props.t(
                                            "clip.unlockContentFor$"
                                          )}
                                          {`${value.price}`}
                                        </button>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              className="story-box-extra hover"
                              style={{ zIndex: 2 }}
                            >
                              <div className="story-box story-box-clip">
                                <DefaultAvatar
                                  className={`story-box-container hover`}
                                  width={"52px"}
                                  height={"52px"}
                                  fontSize={"20px"}
                                  id={value.creatorDetails._id}
                                  userName={value.creatorDetails.name}
                                >
                                  {value.creatorDetails.profilePicture ? (
                                    <Image
                                      width={52}
                                      height={52}
                                      title={value.creatorDetails.name}
                                      alt={value.creatorDetails.name}
                                      className={`story-box-container hover`}
                                      src={getUserProfilePic(
                                        value.creatorDetails._id,
                                        value.creatorDetails.profilePicture
                                      )}
                                    ></Image>
                                  ) : null}
                                </DefaultAvatar>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        {this.state.showModalTip && (
          <TipModal
            userName={
              this.state.clipsUrl[this.state.currentClip].creatorDetails
                .userName
            }
            show={this.state.showModalTip}
            onHide={() => this.setState({ showModalTip: false })}
            id={this.state.clipsUrl[this.state.currentClip].id}
            objId={this.state.clipsUrl[this.state.currentClip].clipId}
            tipArea={"CLIPS"}
          />
        )}
        {this.state.showModalReport && (
          <ReportModal
            type={4}
            reported={
              this.state.clipsUrl[this.state.currentClip].creatorDetails._id
            }
            id={this.state.clipsUrl[this.state.currentClip].clipId}
            show={this.state.showModalReport}
            onHide={() => this.setState({ showModalReport: false })}
          />
        )}
        <Modal
          show={this.state.showModalComment}
          onHide={() => this.closeCommnetModal()}
          keyboard={false}
          centered
          className="margin-modal-comment-clip"
        >
          <Modal.Header>
            <span className="filter-text">
              {this.props.t("clip.commentsOnClips")}{" "}
            </span>
            <span
              className="filter-text hover"
              onClick={() => this.closeCommnetModal()}
            >
              x
            </span>
          </Modal.Header>
          <Modal.Body>
            <InfiniteScroll
              dataLength={this.state.commentList.length}
              next={() => {
                this.loadMoreComment();
              }}
              hasMore={
                this.state.totalComment > this.state.commentList.length
                  ? true
                  : false
              }
              scrollableTarget="commentsClipScroll"
              className="right-scroll-bar h-100-perc"
            ></InfiniteScroll>
            <div id="commentsClipScroll" className="list-comment-clip nobar">
              {this.state.commentList.map((value, index) => (
                <div>
                  <SingleCommentClip
                    isCreatorVerified={value.userDetails?.isCreatorVerified}
                    name={value.userDetails?.name}
                    commentText={value.commentText}
                    createdAt={value.createdAt}
                    totalCommentReactions={value.totalCommentReactions}
                    _id={value.userDetails?._id}
                    index={index}
                    profilePicture={value.userDetails?.profilePicture}
                    showReply={true}
                    replyAction={() =>
                      this.setState({
                        targetReplyId: value._id,
                        targetReply: value.userDetails.userName,
                        commentText: "@" + value.userDetails.userName + " ",
                        isReply: true,
                      })
                    }
                    showAddReactionComment={this.state.showAddReactionComment}
                    leaveLike={() => this.leaveLike(value._id, index)}
                  ></SingleCommentClip>
                  {value?.replies && (
                    <>
                      {value?.replies.map((valueReply, indexReply) => (
                        <div className="ml-24px">
                          <SingleCommentClip
                            isCreatorVerified={
                              valueReply.userDetails?.isCreatorVerified
                            }
                            name={valueReply.userDetails?.name}
                            commentText={valueReply.commentText}
                            createdAt={valueReply.createdAt}
                            totalCommentReactions={
                              valueReply.totalCommentReactions
                            }
                            _id={valueReply.userDetails?._id}
                            index={indexReply}
                            profilePicture={
                              valueReply.userDetails?.profilePicture
                            }
                            showReply={false}
                            showAddReactionComment={
                              this.state.showAddReactionComment
                            }
                            leaveLike={() =>
                              this.leaveLike(valueReply._id, index, indexReply)
                            }
                          ></SingleCommentClip>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <div className="d-flex" style={{ width: "100%" }}>
              <span style={{ paddingRight: "8px" }}>
                <DefaultAvatar
                  width={"32px"}
                  height={"32px"}
                  id={
                    JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
                      .authentication.userData._id
                  }
                  userName={
                    JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
                      .authentication.userData.name
                  }
                >
                  {JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
                    .authentication.userData.profilePicture ? (
                    <Image
                      src={getUserProfilePic(
                        JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
                          .authentication.userData._id,
                        JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
                          .authentication.userData.profilePicture
                      )}
                      className="border-radius-40"
                      width={32}
                      height={32}
                    ></Image>
                  ) : null}
                </DefaultAvatar>
              </span>
              {this.state.isReply && (
                <div className="reply-clip-confirm">
                  <span className="flex-paginator">
                    <div style={{ width: "100%" }}>
                      {this.props.t("clip.replyTo")} {this.state.targetReply}
                    </div>
                    <div
                      onClick={() =>
                        this.setState({
                          commentText: "",
                          isReply: false,
                          targetReply: "",
                          targetReplyId: "",
                        })
                      }
                      style={{ paddingRight: "10px" }}
                    >
                      x
                    </div>
                  </span>
                </div>
              )}
              <input
                style={{ width: "100%" }}
                placeholder={this.props.t("clip.writeYourComment")}
                className="clip-comment-input"
                value={this.state.commentText}
                onChange={(value) =>
                  this.setState({ commentText: value.target.value })
                }
              ></input>
            </div>
            <div
              className="d-flex"
              style={{ width: "100%", justifyContent: "right" }}
            >
              <Button
                onClick={() => this.closeCommnetModal()}
                className="cancel-button-clip"
              >
                {this.props.t("common.cancel")}
              </Button>
              <Button
                onClick={() => {
                  if (
                    this.state.commentText === "" ||
                    this.state.commentText === null ||
                    this.state.commentText === undefined
                  ) {
                    toast.error(this.props.t("clip.needMessage"));
                  } else {
                    this.addComment(false, null);
                  }
                }}
                className="cancel-button-clip send-button-clip"
              >
                {this.props.t("common.send")}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation()(Clip);
