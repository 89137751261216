import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUserProfilePic } from "./helper/GeneralHelper";
import DefaultAvatar from "./common/DefaultAvatar";

class Appfooter extends Component {
  render() {
    return (
      <div className="app-footer border-0 shadow-lg bg-light-black-gradient">
        <Link to="/" className="nav-content-bttn nav-center">
          <i className="feather-home"></i>
        </Link>
        <Link to="/profile" className="nav-content-bttn mt-3">
          <DefaultAvatar
            id={this?.props?.loggedUserInfo?.userData?._id}
            userName={this?.props?.loggedUserInfo?.userData?.name}
          >
            {this?.props?.loggedUserInfo?.userData?.profilePicture ? (
              <figure
                alt={this?.props?.loggedUserInfo?.userData?.name}
                title={this?.props?.loggedUserInfo?.userData?.name}
                className="avatar z-index-1 user-profile-figure"
                style={{
                  background: getUserProfilePic(
                    this?.props?.loggedUserInfo?.userData?._id,
                    this?.props?.loggedUserInfo?.userData?.profilePicture,
                    true,
                    "0% 0% / cover no-repeat"
                  ),
                }}
              ></figure>
            ) : null}
          </DefaultAvatar>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedUserInfo: state.authentication,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Appfooter);
