import { Types } from '../../components/config/actionTypes'

var initialState = {
    loggedIn: false,
    token: null,
    userData: null
}

export function authentication(state = initialState, action) {

    switch (action.type) {
        case Types.LOGIN:
            return {
                loggedIn: true,
                token: action.payload.token,
                userData: action.payload.userData
            };

        case Types.EDIT:
            return {
                ...state,
                token: action.payload.token,
                userData: action.payload.userData
            };

        case Types.EDIT_TOKEN:
            return {
                ...state,
                token: action.payload.token,
                userData: action.payload.userData
            };

        case Types.UPDATE_SUBSCRIBED_USER_COUNT:
            return {
                ...state,
                token: action.payload.token,
                userData: action.payload.userData
            }

        case Types.ADD_STRIPE_CUSTOMER_ID:
            return {
                ...state,
                token: action.payload.token,
                userData: action.payload.userData
            }

        case Types.LOGOUT:
            return {
                ...state,
                loggedIn: false,
                token: null,
                userData: null,
            }

        default:
            return state
    }
}