import RequestService from "./RequestService";

const StoryService = {
    getStoryById: function (id) {
        return RequestService.get(`/story/${id}`);
    },
    myStories: function () {
        return RequestService.get(`/story/me`);
    },
    visualizeStory: function (params) {
        return RequestService.post(`/story/view`, params);
    },
    likeStory: function (params) {
        return RequestService.post(`/story/like`, params);
    },
    getStoryLike: function (params) {
        return RequestService.post(`/story/get-likes`, params);
    },
    storyList: function () {
        return RequestService.post(`/story/list`, {});
    },
    createStorie: function (params) {
        return RequestService.post(`/story/add`, params);
    },
    deleteStorie: function (params) {
        return RequestService.post(`/story/delete`, params);
    },
}

export default StoryService;