import React from "react";

export const MultiNumber = ({ numberToFix, myClass }) => {
  const fixNumber = (number1) => {
    if (number1 > 999) {
      if (number1 > 999999) {
        if (number1 > 999999999) {
          return parseFloat(number1 / 1000000000).toFixed(1) + "B";
        } else {
          return parseFloat(number1 / 1000000).toFixed(1) + "M";
        }
      } else {
        return parseFloat(number1 / 1000).toFixed(1) + "K";
      }
    } else {
      return number1;
    }
  };

  const loadingNumber = (value) => {
    return typeof value === "number" ? value : <span>0</span>;
  };

  return (
    <span className={myClass}>{fixNumber(loadingNumber(numberToFix))}</span>
  );
};
