import { ThemeProvider, createTheme } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { withTranslation } from "react-i18next";
import { Component } from "react";
import { grey } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers";

import {
  getLanguageDateLocale,
  getLanguageDatePicker,
} from "../helper/LanguageHelper";

export class CustomDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: this.props.date,
      minDate: this.props.minDate || null,
      maxDate: this.props.maxDate || null,
      darkTheme: createTheme({
        palette: {
          mode:
            localStorage.getItem("theme") === "theme-light" ? "light" : "dark",
          ...(localStorage.getItem("theme") === "theme-light"
            ? {
                // palette values for light mode
                primary: { main: "#cc0000" },
                text: {
                  secondary: "#fff",
                  primary: grey[800],
                },
              }
            : {
                // palette values for dark mode
                primary: { main: "#cc0000" },
                text: {
                  primary: "#fff",
                  secondary: grey[500],
                },
              }),
        },
        components: {
          MuiOutlinedInput: {
            defaultProps: {
              className: "datepicker",
            },
            styleOverrides: {
              input: {
                borderWidth: "0px !important",
              },
              error: {
                borderWidth: "0px !important",
              },
              focused: {
                borderWidth: "0px !important",
              },
              notchedOutline: {
                borderWidth: "0px !important",
              },
            },
          },
          MuiFormControl: {
            styleOverrides: {
              fullWidth: "100%",
            },
            defaultProps: {
              className: "datepicker-form-control",
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                width: "100%",
              },
            },
          },
        },
      }),
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleProjectChange);
    document.addEventListener("touch", this.handleProjectChange);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleProjectChange);
    document.removeEventListener("touch", this.handleProjectChange);
  }

  handleProjectChange = () => {
    this.setState({
      darkTheme: createTheme({
        palette: {
          mode:
            localStorage.getItem("theme") === "theme-light" ? "light" : "dark",
          ...(localStorage.getItem("theme") === "theme-light"
            ? {
                // palette values for light mode
                primary: { main: "#cc0000" },
                text: {
                  secondary: "#fff",
                  primary: grey[800],
                },
              }
            : {
                // palette values for dark mode
                primary: { main: "#cc0000" },
                text: {
                  primary: "#fff",
                  secondary: grey[500],
                },
              }),
        },
        components: {
          MuiOutlinedInput: {
            defaultProps: {
              className: "datepicker",
            },
            styleOverrides: {
              input: {
                borderWidth: "0px !important",
              },
              error: {
                borderWidth: "0px !important",
              },
              focused: {
                borderWidth: "0px !important",
              },
              notchedOutline: {
                borderWidth: "0px !important",
              },
            },
          },
          MuiFormControl: {
            styleOverrides: {
              fullWidth: "100%",
            },
            defaultProps: {
              className: "datepicker-form-control",
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                width: "100%",
              },
            },
          },
        },
      }),
    });
  };

  render() {
    return (
      <ThemeProvider theme={this.state.darkTheme}>
        <LocalizationProvider
          localeText={getLanguageDatePicker()}
          adapterLocale={getLanguageDateLocale()}
          dateAdapter={AdapterMoment}
        >
          <Stack spacing={3} className="w-100">
            <div
              style={{ borderRadius: "50px", overflow: "hidden" }}
              className="m-auto scheduleInputtype w-100"
            >
              <DatePicker
                value={this.state.date}
                onChange={(newDate) => this.props.onDatePickerChange(newDate)}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
              />
            </div>
          </Stack>
        </LocalizationProvider>
      </ThemeProvider>
    );
  }
}

export default withTranslation()(CustomDatePicker);
