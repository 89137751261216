import RequestService from "./RequestService";

const PostService = {
    creatorPost: function (params) {
        return RequestService.post(`/post/create`, params);
    },
    deletePostMedia: function (params) {
        return RequestService.post(`/post/delete-media`, params);
    },
    updatePostDetails: function (params) {
        return RequestService.post(`/post/update`, params);
    },
    creatorPostList: function (params) {
        return RequestService.post(`/post/created-post-list`, params);
    },
    creatorSinglePostList: function (params) {
        return RequestService.post(`/post/creator-profile`, params);
    },
    scheduledPostList: function (params) {
        return RequestService.post(`/post/scheduled-post-list`, params);
    },
    scheduledPostListByDate: function (params) {
        return RequestService.post(`/post/scheduled-post-by-date`, params);
    },
    queuePostMediaCountByDate: function (params) {
        return RequestService.post(`/post/scheduled-post-media-count`, params);
    },
    deletePost: function (params) {
        return RequestService.post(`/post/delete`, params);
    },
    publishPost: function (params) {
        return RequestService.post(`/post/publish-now`, params);
    },
    putFile: function (path, file) {
        return RequestService.putFile(path, file);
    },
    addRemoveReactionOnPost: function (params) {
        return RequestService.post(`/post/add-remove-reaction`, params);
    },
    reactionsOnPost: function (params) {
        return RequestService.post(`/post/reactions`, params);
    },
    addCommentOnPost: function (params) {
        return RequestService.post(`/post/add-comment`, params);
    },
    updateCommentOnPost: function (params) {
        return RequestService.post(`/post/edit-comment`, params);
    },
    deleteComment: function (params) {
        return RequestService.post(`/post/delete-comment`, params);
    },
    commentsOnPost: function (params) {
        return RequestService.post(`/post/comments`, params);
    },
    addRemoveLikeOnPostComment: function (params) {
        return RequestService.post(`/post/comments/add-remove-like`, params);
    },
    postDetails: function (params) {
        return RequestService.get(`/post/details/${params.id}`);
    },
    postMedias: function (params) {
        return RequestService.post(`/post/medias`, params);
    },
    postMediaByType: function (params) {
        return RequestService.post(`/post/media-type`, params);
    },
    getTrendingPosts: function () {
        return RequestService.get(`/post/trending-posts`);
    },
}

export default PostService;