import React, { Component, Fragment } from "react";
import UserService from "../../../services/UserService.js";
import StoryService from "../../../services/StoryService.js";
import { withTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  AWS_URL,
  REDUX_LOCAL_STORE_KEY,
} from "../../../components/config/constants";
import Stories from "react-insta-stories";
import Loader from "../../../components/Loader.jsx";
import { Link } from "react-router-dom";
import { FaXmark } from "react-icons/fa6";
import { GoEye, GoMute, GoUnmute } from "react-icons/go";
import { BsThreeDots } from "react-icons/bs";
import TipModal from "../Tip/TipModal";
import { GoAlertFill } from "react-icons/go";
import { BiDollarCircle } from "react-icons/bi";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import { BiMessageRoundedDots, BiSolidTrashAlt } from "react-icons/bi";
import { SocketContext } from "../../../context/SocketContext";
import ReportModal from "../../../components/common/commonModal/ReportModal.jsx";
import { Modal, Button } from "react-bootstrap";
import { getUserProfilePic } from "../../../components/helper/GeneralHelper.js";
import DefaultAvatar from "../../../components/common/DefaultAvatar.jsx";
import AccountVerifyTick from "../../../components/common/AccountVerifyTick.jsx";
import { IoMdThumbsUp } from "react-icons/io";
import { MultiNumber } from "../../../components/common/MultiNumber.jsx";

class CreatorStories extends Component {
  static contextType = SocketContext;

  constructor(props) {
    super(props);
    this.clickInput = React.createRef();
    this.carouselRef = React.createRef();
    this.clickSendMessage = React.createRef();
    this.width = window.innerWidth;
    this.isMobile = this.width <= 512;
    this.isTab = this.width <= 9999;

    this.state = {
      arrayStoryUrl: [],
      storyList: [],
      currentStory: 0,
      isStoryMuted: false,
      showOption: false,
      showModalTip: false,
      showModalReport: false,
      loading: true,
      creatorId: null,
      isMe: false,
      showModalDelete: false,
    };
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    const me = JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
      .authentication.userData._id;

    this.setState(
      {
        creatorId: id,
        isMe: id === me,
      },
      () => {
        this.getStoryList();
      }
    );
  };

  isImage = (file) => {
    return file.type.startsWith("image/");
  };

  isVideo = (file) => {
    return file.type.startsWith("video/");
  };

  getStoryLike = (storyId) => {
    let params = {
      storyId: storyId,
      page: 0,
      limit: 250,
    };
    StoryService.getStoryLike({ ...params })
      .then((res) =>
        this.setState({
          storyLikeList: res.data.data[0].data,
          showModalStatistics: true,
        })
      )
      .catch((err) => console.log(err.message));
  };

  getStoryList = () => {
    if (this.state.storyId) {
      StoryService.getStoryById(this.state.storyId).then((res) => {});
    } else {
      if (!this.state.isMe) {
        let params = {
          creatorId: this.state.creatorId,
        };
        UserService.storiesCreator({ ...params })
          .then((res) => {
            const arrayStoryUrl = res.data.data.map((value) => ({
              url: value.storyMedia.storyUrl,
              type: value.storyMedia.type,
              id: value._id,
              liked: value.userStoryDetails[0]?.liked
                ? value.userStoryDetails[0]?.liked
                : false,
              creatorDetails: value.creatorDetails,
            }));
            this.setState({ arrayStoryUrl: arrayStoryUrl, loading: false });
          })
          .catch((err) => {
            console.log(err.message);
            this.props.history.push("/page-not-found");
          });
      } else {
        StoryService.myStories()
          .then((res) => {
            const arrayStoryUrl = res.data.data.map((value) => ({
              url: value.storyMedia.storyUrl,
              type: value.storyMedia.type,
              id: value._id,
              liked: value.userStoryDetails[0]?.liked
                ? value.userStoryDetails[0]?.liked
                : false,
              creatorDetails: value.creatorDetails,
            }));
            this.setState({ arrayStoryUrl: arrayStoryUrl, loading: false });
          })
          .catch((err) => {
            console.log(err.message);
            this.props.history.red("/page-not-found");
          });
      }
    }
  };

  scrollNext = () => {
    this.props.history.goBack();
  };

  muteStory = () => {
    this.setState({ isStoryMuted: !this.state.isStoryMuted }, () =>
      this.muteAllVideos()
    );
  };

  muteAllVideos = () => {
    const videoElements = document.getElementsByTagName("video");
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i];
      videoElement.muted = this.state.isStoryMuted;
    }
  };

  sendMessage = () => {
    let params = {
      senderId: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
        .authentication.userData._id,
      receiverId: this.state.creatorId,
      message: {
        text: this.state.messageValue,
        messagePrice: null,
      },
    };
    this.context?.socket?.emit("add_message", params);
    this.setState({ messageValue: "" });
  };

  visualizeStory = () => {
    let params = {
      storyId:
        this.state.arrayStoryUrl[
          this.state.currentStory - (this.state.currentStory > 0 ? 1 : 0)
        ].id,
    };
    StoryService.visualizeStory({ ...params })
      .then(() => {})
      .catch((err) => toast.error(err.message));
  };

  likeOrDislike = () => {
    let params = {
      storyId: this.state.arrayStoryUrl[this.state.currentStory].id,
    };
    StoryService.likeStory({ ...params })
      .then((res) => {
        this.adjustArrayForLike();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  adjustArrayForLike = () => {
    let arrayStoryUrl = this.state.arrayStoryUrl;
    arrayStoryUrl[this.state.currentStory].liked = arrayStoryUrl[
      this.state.currentStory
    ].liked
      ? !arrayStoryUrl[this.state.currentStory].liked
      : true;
    this.setState({ arrayStoryUrl: arrayStoryUrl });
  };

  deleteStory = (storyId) => {
    let params = {
      storyId: storyId,
    };
    StoryService.deleteStorie({ ...params })
      .then((res) => {
        this.props.history.push("/");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  render() {
    return (
      <Fragment>
        <div
          onClick={() => {
            if (this.state.showOption === true) {
              this.setState({ showOption: false });
            }
          }}
          className="stories-page-single"
        >
          {this.state.loading ? (
            <Loader />
          ) : (
            <>
              {this.state.arrayStoryUrl[this.state.currentStory] !==
                undefined && (
                <div className="story-card-current hover flex-paginator">
                  <span className="story-style">
                    <span
                      style={{ position: "absolute" }}
                      className="story-creator-icon"
                    >
                      <div className="flex-paginator fix-story-icon">
                        <DefaultAvatar
                          id={this.state.creatorId}
                          userName={
                            this.state.arrayStoryUrl[this.state.currentStory]
                              .creatorDetails?.name
                          }
                          width={"32px"}
                          height={"32px"}
                          className="picture-story"
                        >
                          {this.state.arrayStoryUrl[this.state.currentStory]
                            .creatorDetails?.profilePicture ? (
                            <Image
                              className="picture-story"
                              style={{ borderRadius: "99px" }}
                              width={32}
                              height={32}
                              alt={
                                this.state.arrayStoryUrl[
                                  this.state.currentStory
                                ].creatorDetails?.name
                              }
                              title={
                                this.state.arrayStoryUrl[
                                  this.state.currentStory
                                ].creatorDetails?.name
                              }
                              src={getUserProfilePic(
                                this.state.creatorId,
                                this.state.arrayStoryUrl[
                                  this.state.currentStory
                                ].creatorDetails?.profilePicture
                              )}
                            ></Image>
                          ) : null}
                        </DefaultAvatar>
                        <span className="story-creators-name">
                          {
                            this.state.arrayStoryUrl[this.state.currentStory]
                              .creatorDetails?.name
                          }
                          {this.state.arrayStoryUrl[this.state.currentStory]
                            .creatorDetails?.isCreatorVerified ? (
                            <span
                              style={{
                                marginLeft: "2px",
                                verticalAlign: "text-bottom",
                              }}
                            >
                              <AccountVerifyTick width="15px"></AccountVerifyTick>
                            </span>
                          ) : null}
                        </span>
                      </div>
                    </span>
                    <span className="remove-option-image-iphone-chrome">
                      <Stories
                        stories={this.state.arrayStoryUrl}
                        defaultInterval={15000}
                        width={
                          this.isMobile
                            ? "100vw"
                            : "calc(86.6666666vh * 0.560897)"
                        }
                        height={this.isMobile ? "100vh" : "86.6666666vh"}
                        isPaused={
                          this.state.showModalTip ||
                          this.state.showModalReport ||
                          this.state.showModalDelete
                        }
                        loop={false}
                        onAllStoriesEnd={this.scrollNext}
                        loader={<Loader />}
                        muted={this.state.isStoryMuted}
                        onStoryEnd={() =>
                          this.setState(
                            { currentStory: this.state.currentStory + 1 },
                            () => this.visualizeStory()
                          )
                        }
                        onNext={() =>
                          this.setState(
                            { currentStory: this.state.currentStory + 1 },
                            () => this.visualizeStory()
                          )
                        }
                        onPrevious={() =>
                          this.setState({
                            currentStory:
                              this.state.currentStory -
                              (this.state.currentStory > 0 ? 1 : 0),
                          })
                        }
                      />
                    </span>
                  </span>
                  <span
                    onClick={() =>
                      this.setState({ showOption: !this.state.showOption })
                    }
                    className="story-option-button"
                  >
                    <BsThreeDots></BsThreeDots>
                    {this.state.showOption && (
                      <div className="option-story-div">
                        {this.state.creatorId !==
                          JSON.parse(
                            localStorage.getItem(REDUX_LOCAL_STORE_KEY)
                          ).authentication.userData._id && (
                          <div
                            className="d-flex justify-content-start align-items-center"
                            onClick={() =>
                              this.setState({ showModalReport: true })
                            }
                          >
                            <GoAlertFill
                              style={{ position: "relative" }}
                            ></GoAlertFill>{" "}
                            {this.props.t("stories.report")}
                          </div>
                        )}
                        {this.state.creatorId !==
                          JSON.parse(
                            localStorage.getItem(REDUX_LOCAL_STORE_KEY)
                          ).authentication.userData._id && (
                          <div
                            className="d-flex justify-content-start align-items-center"
                            onClick={() =>
                              this.setState({ showModalTip: true })
                            }
                          >
                            <BiDollarCircle
                              style={{ position: "relative" }}
                            ></BiDollarCircle>{" "}
                            {this.props.t("stories.tip")}
                          </div>
                        )}
                        {this.state.creatorId ===
                          JSON.parse(
                            localStorage.getItem(REDUX_LOCAL_STORE_KEY)
                          ).authentication.userData._id && (
                          <div
                            name="no-close"
                            className="d-flex justify-content-start align-items-center"
                            onClick={() => {
                              this.setState({ showModalDelete: true });
                            }}
                          >
                            <BiSolidTrashAlt
                              style={{
                                position: "relative",
                              }}
                            ></BiSolidTrashAlt>{" "}
                            {this.props.t("stories.delete")}
                          </div>
                        )}
                      </div>
                    )}
                  </span>
                  <span
                    onClick={() => this.muteStory()}
                    className="mute-button"
                  >
                    {this.state.isStoryMuted === false ? (
                      <GoUnmute></GoUnmute>
                    ) : (
                      <GoMute></GoMute>
                    )}
                  </span>
                  {this.isMobile && (
                    <span
                      onClick={() => this.props.history.goBack()}
                      className="close-story-button"
                    >
                      <FaXmark style={{ color: "white" }}></FaXmark>
                    </span>
                  )}
                </div>
              )}
              {this.state.isMe ? (
                <div
                  onClick={() => {
                    this.getStoryLike(
                      this.state.arrayStoryUrl[this.state.currentStory].id
                    );
                  }}
                  className="footer-story-extra hover"
                  style={{ color: "white", zIndex: 999 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center text-shadow-20px-black"
                    style={{ gap: "8px" }}
                  >
                    <GoEye />
                    <span className="f-14px-16px">
                      {this.props.t("archive.seeStatistics")}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="footer-story-extra">
                  <div className="footer-story">
                    <span
                      style={{ height: "35px" }}
                      className="border-message-story d-flex justify-content-center align-items-center"
                    >
                      <input
                        placeholder={this.props.t("stories.sendMessage")}
                        style={{
                          width: this.isMobile
                            ? window.innerWidth - 60 + "px"
                            : "280px",
                        }}
                        onKeyUp={(e) => {
                          if (e.code === "Enter") {
                            this.clickSendMessage.current.click();
                          }
                        }}
                        onChange={(value) =>
                          this.setState({
                            messageValue: value.target.value,
                          })
                        }
                        className="message-story"
                        type="text"
                        value={this.state.messageValue}
                      ></input>
                    </span>
                    <span
                      style={{ display: "none" }}
                      onClick={() => this.sendMessage()}
                      ref={this.clickSendMessage}
                      className="footer-story-icon hover"
                    >
                      {/* <Link
                            to={{
                              pathname: `/chats`,
                              state: {
                                creatorDetails:
                                  this.state.storyList[
                                    this.state.currentCreator -
                                      (this.isMobile ? 0 : 1)
                                  ].creatorDetails,
                                message: this.state.messageValue,
                              },
                            }}
                            ref={this.clickSendMessage}
                          > */}
                      <BiMessageRoundedDots
                        style={{ color: "white" }}
                      ></BiMessageRoundedDots>
                      {/* </Link> */}
                    </span>
                    <span
                      style={{ height: "35px" }}
                      className="footer-story-icon hover d-flex justify-content-center align-items-center"
                      onClick={() => this.likeOrDislike()}
                    >
                      {this.state?.arrayStoryUrl[this.state.currentStory]
                        ?.liked === true ? (
                        <AiTwotoneHeart></AiTwotoneHeart>
                      ) : (
                        <AiOutlineHeart></AiOutlineHeart>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}

          {this.isMobile === false && (
            <>
              <div className="red-circle-story">
                <Link to="/" style={{ paddingLeft: "24px", gap: "7.24px" }}>
                  <img
                    className="logo-red-circle"
                    src={`${AWS_URL}logo/logoRedCircle.png`}
                    alt=""
                  ></img>
                  <img
                    className="logo-red-circle"
                    src={`${AWS_URL}logo/${
                      localStorage.getItem("theme") === "theme-light"
                        ? "RedCircleBlack.png"
                        : "RedCircleRed.png"
                    }`}
                    alt=""
                  ></img>
                  {localStorage.getItem("theme") === "theme-dark"}
                </Link>
              </div>
              <div className="close-story">
                <div
                  onClick={() => this.props.history.goBack()}
                  style={{ justifyContent: "center", gap: "7.24px" }}
                  className="hover"
                >
                  <FaXmark className="close-x"></FaXmark>
                </div>
              </div>
            </>
          )}
        </div>
        {this.state.showModalTip && (
          <TipModal
            userName={this.state.creatorUserName}
            show={this.state.showModalTip}
            onHide={() => this.setState({ showModalTip: false })}
            id={this.state.creatorId}
            objId={this.state.currentStory}
            tipArea={"STORIES"}
          />
        )}
        <Modal
          show={this.state.showModalDelete}
          onHide={() => this.setState({ showModalDelete: false })}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton className="ps-3 pt-2 pb-2">
            <span className="text-color-dark fw-700 font-xssss">
              {this.props.t("stories.delete")}
            </span>
          </Modal.Header>
          <Modal.Body
            className={`p-4 add-card-modal ${
              this.state.doMessage === true ? "pb-0" : ""
            }`}
          >
            <span className="text-color-dark fw-400 font-xssss">
              {this.props.t("stories.deleteDescription")}
            </span>
          </Modal.Body>
          <Modal.Footer className="d-flex p-2 justify-content-end">
            <div
              className="pointer fw-bold me-4 font-xsss text-silver"
              onClick={() => this.setState({ showModalDelete: false })}
            >
              {this.props.t("common.cancel")}
            </div>
            <Button
              className="fix-tip-button fw-bold me-2 rounded-pill font-xssss text-white px-3 py-2 pointer"
              // onClick={() =>
              //   this.state.tipValue >= 1
              //     ? this.setState({ doPayment: true })
              //     : toast.error(this.props.t("tip.selectTip"))
              // }
              onClick={() => {
                this.deleteStory(
                  this.state.arrayStoryUrl[this.state.currentStory].id
                );
              }}
            >
              {this.props.t("account.deleteAccount.modal.confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.showModalStatistics && (
          <Modal
            show={this.state.showModalStatistics}
            onHide={() => this.setState({ showModalStatistics: false })}
            keyboard={false}
            centered
          >
            <Modal.Header closeButton className="ps-3 pt-2 pb-2">
              <span className="text-color-dark fw-700 font-xssss">
                {this.props.t("archive.storyStatistics")}
              </span>
            </Modal.Header>
            <Modal.Body
              className={`p-4 add-card-modal stats-modal ${
                this.state.doMessage === true ? "pb-0" : ""
              }
                          `}
            >
              <div>
                <span className="text-12px">
                  {this.props.t("archive.totalView")}:{" "}
                </span>
                <span className="select-category-clip">
                  <MultiNumber
                    numberToFix={
                      this.state.arrayStoryUrl[this.state.currentStory]
                        .storyTotalLikes
                        ? this.state.arrayStoryUrl[this.state.currentStory]
                            .storyTotalLikes
                        : this.state.arrayStoryUrl[this.state.currentStory]
                            ?.totalViews
                    }
                  />{" "}
                  {this.props.t("archive.followers")}
                </span>
                <span className="text-12px" style={{ marginLeft: "16px" }}>
                  {this.props.t("archive.totalLkes")}:{" "}
                </span>
                <span className="select-category-clip">
                  <MultiNumber
                    numberToFix={
                      this.state.arrayStoryUrl[this.state.currentStory]
                        .totalViews
                        ? this.state.arrayStoryUrl[this.state.currentStory]
                            .totalViews
                        : this.state.arrayStoryUrl[this.state.currentStory]
                            ?.storyTotalLikes
                    }
                  />{" "}
                  {this.props.t("archive.followers")}
                </span>
              </div>

              <div className="scroll-like-story">
                {this.state.storyLikeList.map((value, index) => {
                  return (
                    <div className="container-user-like-story flex-paginator align-center-only">
                      <span>
                        <Image
                          style={{ borderRadius: "50px" }}
                          width={48}
                          height={48}
                          src={getUserProfilePic(
                            value.userDetails._id,
                            value.userDetails.profilePicture
                          )}
                        ></Image>
                        <span className="user-like-thumbs">
                          <IoMdThumbsUp />
                        </span>
                      </span>
                      <span>
                        <div className="name-user-story">
                          {value.userDetails.userName}
                        </div>
                        <div className="wallet-info">
                          @{value.userDetails.name}
                        </div>
                      </span>
                    </div>
                  );
                })}
              </div>
              <Modal.Footer className="d-flex p-2 justify-content-end border-0"></Modal.Footer>
            </Modal.Body>
          </Modal>
        )}
        {this.state.showModalReport && (
          <ReportModal
            type={3}
            reported={this.state.creatorId}
            id={this.state.storyList[this.state.creatorId]?.stories[0]._id}
            show={this.state.showModalReport}
            onHide={() => this.setState({ showModalReport: false })}
          />
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(CreatorStories);
