import { toast } from "react-toastify";
import { FILE_LIMIT } from "../config/constants.js";

const controlMediaSize = async (attachments, propsT) => {
  const promises = attachments.map((attachment, index) => {
    return controlFile(attachment, propsT);
  });
  const response = await Promise.all(promises);
  const isAllValid = response.every((item) => item === "valid");
  return isAllValid;
};

const controlFile = (attachment, propsT) => {
  return new Promise(async (resolve) => {
    if (attachment.isVaultMedia === true) {
      resolve("valid");
    } else {
      if (controlExtension(attachment)) {
        if (attachment.file.size < FILE_LIMIT.SIZE) {
          if (attachment.file.type.includes("image")) {
            const img = new Image();
            img.style.display = "none";
            img.onload = function () {
              if (
                img.width <= FILE_LIMIT.RESOLUTION_X &&
                img.height <= FILE_LIMIT.RESOLUTION_Y
              ) {
                if (
                  img.width >= FILE_LIMIT.MIN_RESOLUTION_X &&
                  img.height >= FILE_LIMIT.MIN_RESOLUTION_Y
                ) {
                  resolve("valid");
                } else {
                  toast.error(propsT("common.errorMinResolution"));
                  resolve("resolution");
                }
              } else {
                toast.error(propsT("common.errorResolution"));
                resolve("resolution");
              }
            };
            img.src = URL.createObjectURL(attachment.file);
          } else {
            if (attachment.file.type.includes("video")) {
              const video = document.createElement("video");
              video.style.display = "none";
              video.onloadedmetadata = function () {
                if (
                  video.videoWidth <= FILE_LIMIT.RESOLUTION_X &&
                  video.videoHeight <= FILE_LIMIT.RESOLUTION_Y
                ) {
                  if (
                    video.videoWidth >= FILE_LIMIT.MIN_RESOLUTION_X &&
                    video.videoHeight >= FILE_LIMIT.MIN_RESOLUTION_Y
                  ) {
                    resolve("valid");
                  } else {
                    toast.error(propsT("common.errorMinResolution"));
                    resolve("resolution");
                  }
                } else {
                  toast.error(propsT("common.errorResolution"));
                  resolve("resolution");
                }
              };

              video.src = URL.createObjectURL(attachment.file);
              document.body.appendChild(video);
            } else {
              resolve("valid");
            }
          }
        } else {
          toast.error(propsT("common.errorSize"));
          resolve("big");
        }
      } else {
        toast.error(propsT("common.errorExtension"));
        resolve("extension");
      }
    }
  });
};

const controlExtension = (attachment) => {
  const supportedTypes = [
    "jpg",
    "jpeg",
    "gif",
    "png",
    "mp4",
    "mov",
    "quicktime",
    "moov",
    "m4v",
    "mpg",
    "mpeg",
    "wmv",
    "avi",
    "webm",
    "mkv",
    "mp3",
    "wav",
    "ogg",
  ];
  if (supportedTypes.some((type) => attachment?.file?.type.includes(type))) {
    return true;
  } else {
    return false;
  }
};

export default controlMediaSize;
