// src/i18n/config.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    resources: {
      en: {
        translations: require("./locales/en/translations.json"),
      },
      es: {
        translations: require("./locales/es/translations.json"),
      },
      it: {
        translations: require("./locales/it/translations.json"),
      },
      fr: {
        translations: require("./locales/fr/translations.json"),
      },
      pt: {
        translations: require("./locales/pt/translations.json"),
      },
      de: {
        translations: require("./locales/de/translations.json"),
      },
      hi: {
        translations: require("./locales/hi/translations.json"),
      },
      ro: {
        translations: require("./locales/ro/translations.json"),
      },
      ru: {
        translations: require("./locales/ru/translations.json"),
      },
      uk: {
        translations: require("./locales/uk/translations.json"),
      },
      ar: {
        translations: require("./locales/ar/translations.json"),
      },
      bg: {
        translations: require("./locales/bg/translations.json"),
      },
      cz: {
        translations: require("./locales/cz/translations.json"),
      },
      id: {
        translations: require("./locales/id/translations.json"),
      },
      ja: {
        translations: require("./locales/ja/translations.json"),
      },
      ko: {
        translations: require("./locales/ko/translations.json"),
      },
      sq: {
        translations: require("./locales/sq/translations.json"),
      },
      zhcn: {
        translations: require("./locales/zhcn/translations.json"),
      },
      zhtw: {
        translations: require("./locales/zhtw/translations.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });
i18n.languages = ["en"];
export default i18n;
