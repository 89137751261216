import RequestService from "./RequestService";

const ClipService = {
    getCommentClip: function (params) {
        return RequestService.post(`/clip/comments`, params);
    },
    getClipDetails: function (id) {
        return RequestService.get(`/clip/details2/${id}`);
    },
    getClipInfo: function (id) {
        return RequestService.get(`/clip/details/${id}`);
    },
    addCommentClip: function (params) {
        return RequestService.post(`/clip/addComment`, params);
    },
    addClipView: function (params) {
        return RequestService.post(`/clip/view`, params);
    },
    createClip: function (params) {
        return RequestService.post(`/clip/create`, params);
    },
    udpateClip: function (params) {
        return RequestService.post(`/clip/update`, params);
    },
    scheduledClipList: function (params) {
        return RequestService.post(`/clip/get-scheduled-clips`, params);
    },
    deleteClip: function (params) {
        return RequestService.post(`/clip/delete`, params);
    },
    publishClip: function (params) {
        return RequestService.post(`/clip/publish-now`, params);
    },
    newClip: function (params) {
        return RequestService.post(`/clip/new`, params);
    },
    leaveLikeComment: function (params) {
        return RequestService.post(`/clip/addCommentReaction`, params);
    },
    addReactionClip: function (params) {
        return RequestService.post(`/clip/addRemoveReaction`, params);
    },
    mostSellClip: function (params) {
        return RequestService.post(`/clip/topSellers`, params);
    },
    mostViewedClip: function (params) {
        return RequestService.post(`/clip/mostViewed`, params);
    },
    listClipSingleCreator: function (params) {
        return RequestService.post(`/clip/list-by-creator`, params);
    },
    randomClip: function (params) {
        return RequestService.post(`/clip/list-random`, params);
    },
}

export default ClipService;