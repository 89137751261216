import RequestService from "./RequestService";

const BookmarkService = {
    addRemoveBookMarkOnPost: function (params) {
        return RequestService.post(`/bookmark/add-remove`, params);
    },
    getBookmarkClip: function (params) {
        return RequestService.post(`/bookmark/list-clips`, params);
    },
    bookmarkList: function (params) {
        return RequestService.post(`/bookmark/list-by-user`, params);
    },
    bookmarkCounters: function (params) {
        return RequestService.get(`/bookmark/get-counter-v2`, params);
    },
}

export default BookmarkService;