import {
  itIT,
  enUS,
  esES,
  frFR,
  ptBR,
  deDE,
  roRO,
  ruRU,
  ukUA,
  jaJP,
  koKR,
  zhCN,
} from "@mui/x-date-pickers/locales";
import "moment/min/locales";

const getLanguageDateLocale = () => {
  const locale = localStorage.getItem("i18nextLng");
  switch (locale) {
    case "zhcn":
      return "zh-cn";
    case "zhtw":
      return "zh-tw";
    default:
      if (locale !== "en") return locale;
      return "en";
  }
};

const getLanguageDatePicker = () => {
  const locale = localStorage.getItem("i18nextLng");
  switch (locale) {
    case "it":
      return itIT.components.MuiLocalizationProvider.defaultProps.localeText;
    case "en":
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
    case "es":
      return esES.components.MuiLocalizationProvider.defaultProps.localeText;
    case "fr":
      return frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    case "pt":
      return ptBR.components.MuiLocalizationProvider.defaultProps.localeText;
    case "de":
      return deDE.components.MuiLocalizationProvider.defaultProps.localeText;
    case "ro":
      return roRO.components.MuiLocalizationProvider.defaultProps.localeText;
    case "ru":
      return ruRU.components.MuiLocalizationProvider.defaultProps.localeText;
    case "uk":
      return ukUA.components.MuiLocalizationProvider.defaultProps.localeText;
    case "ja":
      return jaJP.components.MuiLocalizationProvider.defaultProps.localeText;
    case "ko":
      return koKR.components.MuiLocalizationProvider.defaultProps.localeText;
    case "zh-cn":
      return zhCN.components.MuiLocalizationProvider.defaultProps.localeText;
    default:
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
  }
};

export { getLanguageDateLocale, getLanguageDatePicker };
