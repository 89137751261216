import React, { Component } from "react";
import { AWS_URL } from "../components/config/constants";

class LoaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStory: this.props.stories ? true : false,
    };
  }

  componentDidMount = () => {};

  render() {
    return (
      <>
        {" "}
        {this.state.isStory ? (
          <div className="loader-image">
            <img
              width={100}
              alt="loader"
              src={`${AWS_URL}logo/logoRedCircle.png`}
            ></img>
          </div>
        ) : (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              left: "0px",
              top: "0px",
              display: "grid",
              alignItems: "center",
              background: "rgba(0, 0, 0, 0.25)",
            }}
            className="z9999999"
          >
            <div>
              <div className="loader-image">
                <img
                  width={100}
                  alt="loader"
                  src={`${AWS_URL}logo/logoRedCircle.png`}
                ></img>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default LoaderComponent;
