import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import { Route } from "react-router";
import LazyLoader from "@loadable/component";
import { connect } from "react-redux";

// Layouts
import GuestLayout from "../../layout/GuestLayout.jsx";
import PublicLayout from "../../layout/PublicLayout.jsx";
import ProtectedLayout from "../../layout/ProtectedLayout";
import ProtectedSupportLayout from "../../layout/ProtectedSupportLayout";
import Loader from "../Loader.jsx";
import ErrorPageLayout from "../../layout/ErrorPageLayout";
import Clip from "../../pages/modules/Clip/Clip.js";
import UploadClip from "../../pages/modules/Clip/UploadClip.js";
import UploadStories from "../../pages/modules/Stories/UploadStories.js";
import StoriesComponent from "../../pages/modules/Stories/Stories.js";
import CreatorStories from "../../pages/modules/Stories/CreatorStories.js";
import SingleClip from "../../pages/modules/Clip/SingleClip.js";
import { USER_TYPES } from "../config/constants.js";
import EmptyLayout from "../../layout/EmptyLayout.jsx";
import SingleStory from "../../pages/modules/Stories/SingleStory.js";

// Pages
const Login = LazyLoader(() => import("../../pages/modules/Auth/Login"), {
  fallback: <Loader />,
});
const Register = LazyLoader(() => import("../../pages/modules/Auth/Register"), {
  fallback: <Loader />,
});
const ForgotPassword = LazyLoader(
  () => import("../../pages/modules/Auth/ForgotPassword"),
  { fallback: <Loader /> }
);
const EmailVerification = LazyLoader(
  () => import("../../pages/modules/Auth/EmailVerification"),
  { fallback: <Loader /> }
);
const ResetPassword = LazyLoader(
  () => import("../../pages/modules/Auth/ResetPassword"),
  { fallback: <Loader /> }
);

const Home = LazyLoader(() => import("../../pages/modules/Home/Home"), {
  fallback: <Loader />,
});
const Settings = LazyLoader(
  () => import("../../pages/modules/Settings/Settings"),
  { fallback: <Loader /> }
);

const Faq = LazyLoader(() => import("../../pages/modules/web/pages/Faq.js"), {
  fallback: <Loader />,
});

const Terms = LazyLoader(
  () => import("../../pages/modules/web/pages/Term.js"),
  {
    fallback: <Loader />,
  }
);
const TermsUser = LazyLoader(
  () => import("../../pages/modules/web/pages/TermsUser.js"),
  {
    fallback: <Loader />,
  }
);
const TermsCreator = LazyLoader(
  () => import("../../pages/modules/web/pages/TermsCreator.js"),
  {
    fallback: <Loader />,
  }
);
const TermsUserCreator = LazyLoader(
  () => import("../../pages/modules/web/pages/TermsUserCreator.js"),
  {
    fallback: <Loader />,
  }
);

const AML = LazyLoader(
  () => import("../../pages/modules/web/pages/AMLPolicy.js"),
  {
    fallback: <Loader />,
  }
);

const Refund = LazyLoader(
  () => import("../../pages/modules/web/pages/RefundPolicy.js"),
  {
    fallback: <Loader />,
  }
);

const Privacy = LazyLoader(
  () => import("../../pages/modules/web/pages/Privacy.js"),
  {
    fallback: <Loader />,
  }
);

const Mission = LazyLoader(
  () => import("../../pages/modules/web/pages/OurMission.js"),
  {
    fallback: <Loader />,
  }
);

const Complaint = LazyLoader(
  () => import("../../pages/modules/web/pages/Complaint.js"),
  {
    fallback: <Loader />,
  }
);

const Cookie = LazyLoader(
  () => import("../../pages/modules/web/pages/Complaint.js"),
  {
    fallback: <Loader />,
  }
);

const ProfileNew = LazyLoader(
  () => import("../../pages/modules/Account/ProfileNew"),
  { fallback: <Loader /> }
);
const CreatorProfileNew = LazyLoader(
  () => import("../../pages/modules/Account/CreatorProfileNew"),
  { fallback: <Loader /> }
);
const Queue = LazyLoader(() => import("../../pages/modules/Queue/Queue"), {
  fallback: <Loader />,
});
const Bookmarks = LazyLoader(
  () => import("../../pages/modules/Bookmarks/Bookmarks"),
  { fallback: <Loader /> }
);
const MyPurchases = LazyLoader(
  () => import("../../pages/modules/MyPurchases/MyPurchases"),
  { fallback: <Loader /> }
);
const EditPost = LazyLoader(() => import("../../pages/modules/Post/EditPost"), {
  fallback: <Loader />,
});
const SinglePost = LazyLoader(
  () => import("../../pages/modules/Post/SinglePost.js"),
  { fallback: <Loader /> }
);
const Archive = LazyLoader(
  () => import("../../pages/modules/Archive/Archive"),
  { fallback: <Loader /> }
);
const CollectionsList = LazyLoader(
  () => import("../../pages/modules/Collection/CollectionsList"),
  {
    fallback: <Loader />,
  }
);
const Notification = LazyLoader(
  () => import("../../pages/modules/Notification/Notification"),
  { fallback: <Loader /> }
);
const NotFound = LazyLoader(
  () => import("../../pages/modules/NotFound/NotFound"),
  { fallback: <Loader /> }
);
const SubscriptionPlans = LazyLoader(
  () => import("../../pages/modules/SubscriptionPlans/SubscriptionPlans"),
  { fallback: <Loader /> }
);
const CheckoutPage = LazyLoader(
  () => import("../../pages/modules/SubscriptionPlans/CheckoutPage"),
  { fallback: <Loader /> }
);
const FindACreator = LazyLoader(
  () => import("../../pages/modules/FindCreator/FindCreator"),
  { fallback: <Loader /> }
);
const CardManagement = LazyLoader(
  () => import("../../pages/modules/SubscriptionPlans/CardManagement"),
  { fallback: <Loader /> }
);
const SubscriptionManagement = LazyLoader(
  () => import("../../pages/modules/SubscriptionPlans/SubscriptionManagement"),
  { fallback: <Loader /> }
);
const Chats = LazyLoader(() => import("../../pages/modules/Chat/Chats"), {
  fallback: <Loader />,
});
const CollectionDetails = LazyLoader(
  () => import("../../pages/modules/Collection/CollectionDetails"),
  { fallback: <Loader /> }
);
const Payment = LazyLoader(
  () => import("../../pages/modules/Payment/Payment"),
  { fallback: <Loader /> }
);
const Support = LazyLoader(
  () => import("../../pages/modules/Support/Support"),
  {
    fallback: <Loader />,
  }
);
const SupportCreate = LazyLoader(
  () => import("../../pages/modules/Support/SupportCreate"),
  {
    fallback: <Loader />,
  }
);
const SupportDetail = LazyLoader(
  () => import("../../pages/modules/Support/SupportDetail"),
  {
    fallback: <Loader />,
  }
);

class Main extends Component {
  render() {
    const GuestRoute = ({ component: Component, ...rest }) => {
      return (
        <GuestLayout>
          <Route
            {...rest}
            render={(props) =>
              this.props.loggedIn &&
              (window.location.pathname !== "/support/create" ||
                !window.location.pathname.includes("/support/details")) ? (
                <Redirect to="/" />
              ) : (
                <Component {...props} />
              )
            }
          />
        </GuestLayout>
      );
    };

    const PublicRoute = ({ component: Component, ...rest }) => {
      return (
        <PublicLayout>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </PublicLayout>
      );
    };

    const EmptyRoute = ({ component: Component, ...rest }) => {
      return (
        <EmptyLayout>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </EmptyLayout>
      );
    };

    const ProtectedRoute = ({
      component: Component,
      isForCreator,
      ...rest
    }) => {
      return (
        <ProtectedLayout>
          <Route
            {...rest}
            render={(props) =>
              (this.props.loggedIn &&
                (this.props.isCreator === isForCreator ||
                  isForCreator === false)) ||
              window.location.pathname === "/support/create" ||
              window.location.pathname.includes("/support/details") ? (
                <Component {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        </ProtectedLayout>
      );
    };

    const ProtectedSupportRoute = ({ component: Component, ...rest }) => {
      return (
        <ProtectedSupportLayout>
          <Route
            {...rest}
            render={(props) =>
              this.props.loggedIn ||
              window.location.pathname === "/support/create" ||
              window.location.pathname.includes("/support/details") ? (
                <Component {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        </ProtectedSupportLayout>
      );
    };

    const ErrorPageRoute = ({ component: Component, ...rest }) => {
      return (
        <ErrorPageLayout>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </ErrorPageLayout>
      );
    };

    return (
      <div>
        <Switch>
          <PublicRoute
            exact={true}
            path="/email-verification"
            component={EmailVerification}
          />
          <EmptyRoute exact={true} path="/not-found" component={NotFound} />
          {!this.props.loggedIn ? (
            <GuestRoute exact={true} path="/" component={Login} />
          ) : (
            <ProtectedRoute
              isForCreator={false}
              exact={true}
              path="/"
              component={Home}
            />
          )}
          <GuestRoute exact={true} path="/register" component={Register} />
          <GuestRoute
            exact={true}
            path="/forgot-password"
            component={ForgotPassword}
          />
          <GuestRoute
            exact={true}
            path="/reset-password"
            component={ResetPassword}
          />

          <EmptyRoute exact={true} path="/terms-conditions" component={Terms} />
          <EmptyRoute exact={true} path="/privacy-policy" component={Privacy} />
          <EmptyRoute exact={true} path="/mission" component={Mission} />
          <EmptyRoute exact={true} path="/cookie-policy" component={Cookie} />
          <EmptyRoute exact={true} path="/aml-policy" component={AML} />
          <EmptyRoute exact={true} path="/refund-policy" component={Refund} />
          <EmptyRoute exact={true} path="/complaint" component={Complaint} />
          <EmptyRoute exact={true} path="/terms-user" component={TermsUser} />
          <EmptyRoute exact={true} path="/faq" component={Faq} />

          <EmptyRoute
            exact={true}
            path="/terms-creator"
            component={TermsCreator}
          />
          <EmptyRoute
            exact={true}
            path="/terms-user-creator"
            component={TermsUserCreator}
          />

          <ProtectedRoute
            isForCreator={false}
            path="/settings"
            component={Settings}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/profile"
            component={ProfileNew}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/story"
            component={SingleStory}
          />
          <ProtectedRoute
            isForCreator={true}
            exact={true}
            path="/queue"
            component={Queue}
          />
          <ProtectedRoute
            isForCreator={true}
            exact={true}
            path="/edit-post"
            component={EditPost}
          />
          <ProtectedRoute
            isForCreator={true}
            exact={true}
            path="/upload-stories"
            component={UploadStories}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/stories"
            component={StoriesComponent}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/creator-stories/:id"
            component={CreatorStories}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/bookmarks"
            component={Bookmarks}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/my-purchases"
            component={MyPurchases}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/post-detail"
            component={SinglePost}
          />
          <ProtectedRoute
            isForCreator={true}
            exact={true}
            path="/collection-details"
            component={CollectionDetails}
          />
          <ProtectedRoute
            isForCreator={true}
            exact={true}
            path="/archive"
            component={Archive}
          />
          <ProtectedRoute
            isForCreator={true}
            exact={true}
            path="/collection"
            component={CollectionsList}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/notifications"
            component={Notification}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/subscription-plans"
            component={SubscriptionPlans}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/checkout-page"
            component={CheckoutPage}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/find-a-creator"
            component={FindACreator}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/clips"
            component={Clip}
          />
          <ProtectedRoute
            isForCreator={true}
            exact={true}
            path="/upload-clip"
            component={UploadClip}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/single-clip"
            component={SingleClip}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/story"
            component={SingleStory}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/card-management"
            component={CardManagement}
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/subscription-management"
            component={SubscriptionManagement}
          />
          <ProtectedRoute
            isForCreator={false}
            path="/chats"
            component={Chats}
          />
          <ProtectedSupportRoute
            exact={true}
            path="/support/create"
            component={SupportCreate}
          />
          <ProtectedSupportRoute
            exact={true}
            path="/support/details/:_id"
            component={SupportDetail}
          />
          <ProtectedSupportRoute
            exact={true}
            path="/support"
            component={Support}
          />
          <Route
            exact={true}
            path="/payment"
            render={(props) =>
              this.props.loggedIn ? <Payment {...props} /> : <Redirect to="/" />
            }
          />
          <ProtectedRoute
            isForCreator={false}
            exact={true}
            path="/:name"
            component={CreatorProfileNew}
          />
          <ErrorPageRoute path="*" component={NotFound} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authentication.loggedIn,
    isCreator: state.authentication?.userData?.userType === USER_TYPES.CREATOR,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
