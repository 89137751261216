import RequestService from "./RequestService";

const NotificationService = {
    notificationTypeList: function (params) {
        return RequestService.get(`/notification/list-notification-type`);
    },
    notificationCounters: function (params) {
        return RequestService.get(`/notification/counters`);
    },
    notificationListByType: function (params) {
        return RequestService.post(`/notification/list`, params);
    },
    unreadNotificationCount: function () {
        return RequestService.get(`/notification/count`);
    },
    updateReadNotificationCount: function (params) {
        return RequestService.post("/notification/update-readed", params);
    },
    
}

export default NotificationService;