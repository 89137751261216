import RequestService from "./RequestService";

const GeneralService = {
  myCategory: function (params) {
    return RequestService.post(`/category/list`, params);
  },
  emojisList: function (location) {
    return RequestService.get(`/emoji/list`);
  },
  getAllCategory: function () {
    return RequestService.get(`/category/all`);
  },
  addNewCategory: function (params) {
    return RequestService.post(`/category/add`, params);
  },
  vaultCategoryList: function (params) {
    return RequestService.post(`/category/list`, params);
  },
  vaultListByCategory: function (params) {
    return RequestService.post(`/vault/list-by-category`, params);
  },
  vaultListByCategoryWithMedia: function (params) {
    return RequestService.post(`/category/list-with-media`, params);
  },
  categoryDetails: function (params) {
    return RequestService.post(`/category/details`, params);
  },
  editCategoryDetails: function (params) {
    return RequestService.post(`/category/edit`, params);
  },
  deleteCategory: function (params) {
    return RequestService.post(`/category/delete`, params);
  },
  addCategoryVault: function (params) {
    return RequestService.post(`/vault/add`, params);
  },
  deleteVault: function (params) {
    return RequestService.post(`/vault/delete`, params);
  },
  getCountryList: function (params) {
    return RequestService.post("/countries/list", params);
  },
  obtainUrlUpload: function (params) {
    return RequestService.post("/post/upload-signed-url", params);
  },
};

export default GeneralService;
