import React, { Component, Fragment } from "react";
import ClipService from "../../../services/ClipService.js";
import PostService from "../../../services/PostService.js";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import CollectionModal from "../../modules/Collection/CollectionModal.js";
import {
  AWS_URL,
  REDUX_LOCAL_STORE_KEY,
} from "../../../components/config/constants";
import { BiCalendarEvent } from "react-icons/bi";
import { MdOutlineInsertPhoto } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Loader from "../../../components/Loader";
import { getUserProfilePic } from "../../../components/helper/GeneralHelper.js";
import GeneralService from "../../../services/GeneralService.js";
import CollectionService from "../../../services/CollectionService.js";
import { BiFolder } from "react-icons/bi";
import DefaultAvatar from "../../../components/common/DefaultAvatar.jsx";
import CustomDateTimePicker from "../../../components/common/CustomDateTimePicker.jsx";
import { CustomDatePicker } from "../../../components/common/CustomDatepicker.jsx";
import controlMediaSize from "../../../components/helper/ControlMedia.js";

class UploadClip extends Component {
  constructor(props) {
    super(props);
    this.clickInput = React.createRef();
    this.clickThumbnail = React.createRef();
    this.state = {
      showFilters: false,
      file: null,
      description: "",
      urlForUpload: "",
      uploadedMedia: "",
      uploadedMediaDataUrl: "",
      userId: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
        .authentication.userData._id,
      fileName: "",
      fileType: "",
      isClipFree: true,
      price: 0,
      discount: 0,
      schedule: false,
      dateClip: moment(moment().add(15, "minutes")),
      startDiscountDate: moment
        .utc()
        .locale(localStorage.getItem("i18nextLng")),
      startCalendarOpen: false,
      endDiscountDate: null,
      endCalendarOpen: false,
      myCategory: [],
      categoryList: [],
      thumbnail: "",
      thumbnailDataUrl: "",
      thumbnailFile: null,
      inputFileClip: null,
      inputEventClip: null,
      loading: false,
      blurredThumbNailImage: "",
      openVaultModal: false,
      isVaultMedia: false,
      minTime: moment(moment().add(15, "minutes")),
      allMedia: [],
    };
  }

  componentDidMount = () => {
    this.getAllCategory();
    this.getAllMedias();
  };

  handleDragOver = (event) => {
    event.preventDefault();
  };

  submitClip = async () => {
    if (this.state.price < 3 && this.state.isClipFree === false) {
      toast.error(this.props.t("clip.min1"));
      return;
    }

    if (this.state.myCategory.length === 0) {
      toast.error(this.props.t("category.selectCategory"));
      return;
    }

    if (this.state.isVaultMedia === true) {
      this.createClip();
    } else {
      this.setState({ loading: true });
      let file = this.state.inputFileClip;
      let event = this.state.inputEventClip;

      if (file) {
        const reader = new FileReader();

        reader.onload = (event) => {
          const dataURL = event.target.result;
          this.setState({ uploadedMediaDataUrl: dataURL }, () => {});
        };

        reader.readAsDataURL(file);
      } else {
        toast.error(this.props.t("clip.invalidFile"));
        this.setState({ loading: false });
        return;
      }

      event.preventDefault();
      this.setState({ fileType: "" });
      if (
        file.name.includes("jpeg") ||
        file.name.includes("jpg") ||
        file.name.includes("png")
      ) {
        this.setState({ fileType: "undefined" });
        toast.error(this.props.t("clip.invalidFile"));
        this.setState({ loading: false });
      } else {
        const duration = await this.getVideoDuration(file);
        if (duration <= 60) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("_id", this?.props?.loggedUserInfo?.userData?._id);
          let params = {
            folder: "media",
            filename: `${new Date().getTime("L")}${file.name}`,
          };

          GeneralService.obtainUrlUpload({ ...params })
            .then((res) => {
              this.setState(
                { urlForUpload: res.data.data, file: formData },
                () =>
                  this.putFile(this.state.urlForUpload, file, params.filename)
              );
            })
            .catch((err) => console.log(err));
        } else {
          toast.error(this.props.t("clip.maxOneMinute"));
          this.setState({ loading: false });
        }
      }
    }
  };

  creatPreviewClip = async () => {
    let file = this.state.inputFileClip;

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const dataURL = event.target.result;
        this.setState({
          uploadedMediaDataUrl: dataURL,
          uploadedMedia: "s",
          fileType: "video",
        });
      };

      reader.readAsDataURL(file);
    }
  };

  handleAddMedia = (medias) => {
    if (medias.length > 1 || !medias[0].type.includes("video")) {
      toast.error(this.props.t("clip.selectOnlyOneFile"));
    } else {
      const videoElement = document.createElement("video");
      videoElement.style.display = "none";
      videoElement.onloadedmetadata = () => {
        const duration = videoElement.duration;
        if (duration <= 60) {
          this.setState({
            isVaultMedia: true,
            name: medias[0].name,
            thumbNailImage: medias[0].thumbNailImage,
            thumbnail: medias[0].thumbNailImage,
            blurredThumbNailImage: medias[0].blurredThumbNailImage,
            type: medias[0].type,
            fileType: medias[0].type,
            uploadedMediaDataUrl: medias[0].url,
            uploadedMedia: medias[0].name,
          });
        } else {
          toast.error(this.props.t("clip.maxOneMinute"));
        }
        videoElement.remove();
      };

      videoElement.onerror = () => {
        videoElement.remove();
      };
      videoElement.src = medias[0].url;
    }
  };

  submitThumbnail = async (file, isWithThumnnail) => {
    if (isWithThumnnail === false) {
      const url = URL.createObjectURL(file);
      const videoElement = document.createElement("video");
      videoElement.style.display = "none";
      videoElement.src = url;
      const onCanPlayThrough = async () => {
        videoElement.removeEventListener("canplaythrough", onCanPlayThrough);

        videoElement.currentTime = 0; // Set current time to beginning

        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;

        const context = canvas.getContext("2d");

        const onSeeked = async () => {
          videoElement.removeEventListener("seeked", onSeeked);

          context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

          const DataUrl = canvas.toDataURL();
          this.setState({ thumbnailDataUrl: DataUrl });

          try {
            // Convert canvas content to a Blob
            canvas.toBlob(async (blob) => {
              // Create a new File object from the Blob
              const imageFile = new File([blob], "thumbnail.jpg", {
                type: "image/jpeg",
              });
              const formData = new FormData();
              formData.append("file", imageFile);
              formData.append(
                "_id",
                this?.props?.loggedUserInfo?.userData?._id
              );

              let params = {
                folder: "thumbnail",
                filename: `${new Date().getTime("L")}${imageFile.name}`,
              };

              GeneralService.obtainUrlUpload({ ...params })
                .then((res) => {
                  // this.setState(
                  //   { urlForUpload: res.data.data, file: formData },
                  //   () => this.putFile(this.state.urlForUpload, file)
                  // );
                  this.putFileThumbnail(
                    res.data.data,
                    imageFile,
                    params.filename
                  );
                })
                .catch((err) => console.log(err));
            }, "image/jpeg");
          } catch (error) {
            console.error("Error converting canvas to Blob:", error);
          }
        };

        videoElement.addEventListener("seeked", onSeeked);
        videoElement.currentTime = 0;
      };

      videoElement.addEventListener("canplaythrough", onCanPlayThrough);
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("_id", this?.props?.loggedUserInfo?.userData?._id);
      this.setState(
        { fileName: `${new Date().getTime("L")}${file.name}` },
        () => {
          let params = {
            folder: "thumbnail",
            filename: `${new Date().getTime("L")}${file.name}`,
          };

          GeneralService.obtainUrlUpload({ ...params })
            .then((res) => {
              this.setState(
                { urlForUpload: res.data.data, file: formData },
                () =>
                  this.putFileThumbnail(
                    this.state.urlForUpload,
                    file,
                    params.filename
                  )
              );
            })
            .catch((err) => console.log(err));
        }
      );
    }
  };

  getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.style.display = "none";
      videoElement.onloadedmetadata = () => {
        const duration = videoElement.duration;
        videoElement.remove();
        resolve(duration);
      };

      videoElement.onerror = () => {
        videoElement.remove();
        reject("error");
      };
      videoElement.src = URL.createObjectURL(file);
    });
  };

  closeCalendar = () => {
    this.setState({ startCalendarOpen: false, endCalendarOpen: false });
  };

  updateCategory = (id) => {
    const indexInFilter = this.state.categoryList.findIndex(
      (item) => item._id === id
    );
    const indexInMyCategory = this.state.myCategory.findIndex(
      (item) => item._id === id
    );

    if (indexInFilter !== -1) {
      const removedItem = this.state.categoryList.splice(indexInFilter, 1)[0];
      this.setState((prevState) => ({
        myCategory: [...prevState.myCategory, removedItem],
        categoryList: prevState.categoryList,
      }));
    } else if (indexInMyCategory !== -1) {
      const removedItem = this.state.myCategory.splice(indexInMyCategory, 1)[0];
      this.setState((prevState) => ({
        myCategory: prevState.myCategory,
        categoryList: [...prevState.categoryList, removedItem],
      }));
    }
  };

  handleChangePrice = (value) => {
    let sanitizedValue = value.replace(/[^0-9.,]/g, "");
    if (/^[0-9]*\.?[0-9]{0,2}$/.test(sanitizedValue)) {
      if (sanitizedValue <= 1000 && value >= 0) {
        this.setState({ price: sanitizedValue });
      } else {
        this.setState({
          price: 1000,
        });
      }
    }
  };

  handleDiscount = (value) => {
    let sanitizedValue = value.replace(/[^0-9]/g, "");
    if (/^[0-9]*\.?[0-9]{0,2}$/.test(sanitizedValue)) {
      if (sanitizedValue <= 100 && sanitizedValue >= 0) {
        this.setState({ discount: sanitizedValue });
      } else {
        this.setState({
          discount: 100,
        });
      }
    }
  };

  getAllCategory = () => {
    this.setState({ loading: true });
    GeneralService.getAllCategory()
      .then((res) => {
        this.setState(
          { categoryList: res.data.data, loading: false },
          () => {}
        );
      })
      .catch((err) => {});
  };

  getAllMedias = () => {
    let params = {
      limit: 3,
      page: 0,
      mediaType: "video",
    };

    CollectionService.getAllCollection({ ...params })
      .then((res) => {
        this.setState({ allMedia: res.data.data.mediasList });
      })
      .catch((err) => {
        this.setState({ allMedia: [] });
      });
  };

  putFile = (url, file, name) => {
    PostService.putFile(url, file)
      .then((res) => {
        this.setState(
          {
            fileType:
              name.includes("jpeg") ||
              name.includes("png") ||
              name.includes("jpg")
                ? "image"
                : name.includes("mp4")
                ? "video"
                : "undefined",
            uploadedMedia: name,
          },
          () => {
            if (this.state.thumbnailFile === null) {
              this.submitThumbnail(this.state.inputFileClip, false);
            } else {
              this.submitThumbnail(this.state.thumbnailFile, true);
            }
            //segnaposto 1
          }
        );
      })
      .catch((err) => console.log(err));
  };

  putFileThumbnail = (url, file, name) => {
    PostService.putFile(url, file)
      .then((res) => {
        this.setState(
          {
            thumbnail: name,
          },
          () => {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;

              const ctx = canvas.getContext("2d");
              ctx.filter = "blur(20px)"; // Imposta l'intensità della sfocatura

              ctx.drawImage(img, 0, 0, img.width, img.height);
              try {
                // Convert canvas content to a Blob
                canvas.toBlob(async (blob) => {
                  // Create a new File object from the Blob
                  const imageFile = new File([blob], "thumbnailBlur.jpg", {
                    type: "image/jpeg",
                  });
                  const formData = new FormData();
                  formData.append("file", imageFile);
                  formData.append(
                    "_id",
                    this?.props?.loggedUserInfo?.userData?._id
                  );

                  let params2 = {
                    folder: "thumbnailBlur",
                    filename: `blurredThumbnail${new Date().getTime("L")}${
                      imageFile.name
                    }`,
                  };

                  GeneralService.obtainUrlUpload({ ...params2 })
                    .then((res) => {
                      // this.setState(
                      //   { urlForUpload: res.data.data, file: formData },
                      //   () => this.putFile(this.state.urlForUpload, file)
                      // );

                      PostService.putFile(res.data.data, imageFile)
                        .then((res) => {
                          this.setState(
                            {
                              blurredThumbNailImage: params2.filename,
                            },
                            () => {
                              this.createClip();
                            }
                          );
                        })
                        .catch((err) => console.log(err));
                    })
                    .catch((err) => console.log(err));
                }, "image/jpeg");
              } catch (error) {
                console.error("Error converting canvas to Blob:", error);
              }
            };

            //
          }
        );
      })
      .catch((err) => console.log(err));
  };

  putFileThumbnailWithout = (url, file, name) => {
    PostService.putFile(url, file)
      .then((res) => {
        this.setState({
          thumbnail: name,
        });
      })
      .catch((err) => console.log(err));
  };

  getImageUrl = (id) => {
    this.pictureService
      .get(id)
      .then((res) => this.setState({ profileImage: res.data.link }));
  };

  onDatePickerChange = (newDate) => {
    this.setState({
      dateClip: newDate,
      minTime: this.calculateMinTime(newDate),
    });
  };

  calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAdd15min = moment(moment().add(15, "minutes"));
      return nowAdd15min;
    } else {
      return moment().startOf("day");
    }
  };

  createClip = () => {
    if (this.state.price < 3 && this.state.isClipFree === false) {
      toast.error(this.props.t("clip.min1"));
    } else {
      if (this.state.uploadedMedia !== "") {
        if (
          this.state.fileType === "undefined" ||
          this.state.fileType === "image"
        ) {
          toast.error(this.props.t("clip.invalidFile"));
        } else {
          let params = {
            description: this.state.description,
            clipMedia: {
              isVaultMedia: this.state.isVaultMedia,
              name: this.state.uploadedMedia,
              thumbNailImage: this.state.thumbnail,
              blurredThumbNailImage: this.state.blurredThumbNailImage,
              type: this.state.fileType,
            },
            isFree: this.state.isClipFree,
            categoryIds: this.state.myCategory.map((value) => value._id),
            clipType: this.state.schedule ? "Scheduled" : "Normal",
            price: this.state.price === 0 ? undefined : this.state.price,
            discount: this.state.discount > 0 ? this.state.discount : 0,
            startDiscountDate:
              this.startDiscountDate !== null
                ? this.state.startDiscountDate
                : moment.utc().locale(localStorage.getItem("i18nextLng")),
            ...(this.state.endDiscountDate && {
              endDiscountDate: this.state.endDiscountDate,
            }),
            clipScheduledDateTime: this.state?.dateClip
              ? this.state.dateClip
              : null,
          };
          ClipService.createClip({ ...params })
            .then((res) => {
              this.setState({ loading: false });
              toast.success(this.props.t("clip.clipCreated"));
              this.props.history.push("/");
            })
            .catch((err) => toast.error(err.message));
        }
      } else {
        toast.error(this.props.t("clip.selectVideo"));
      }
    }
  };

  submitCustomThumbnail = (file, event) => {
    event.preventDefault();
    if (
      file.name.includes(".jpeg") ||
      file.name.includes(".jpg") ||
      file.name.includes(".png")
    ) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("_id", this?.props?.loggedUserInfo?.userData?._id);
      this.setState(
        { fileName: `${new Date().getTime("L")}${file.name}` },
        () => {
          let params = {
            folder: "thumbnail",
            filename: `${new Date().getTime("L")}${file.name}`,
          };

          GeneralService.obtainUrlUpload({ ...params })
            .then((res) => {
              this.setState(
                { urlForUpload: res.data.data, file: formData },
                () =>
                  this.putFileThumbnail(
                    this.state.urlForUpload,
                    file,
                    params.filename
                  )
              );
            })
            .catch((err) => console.log(err));
        }
      );
    } else {
      toast.error(this.props.t("clip.invalidFile"));
    }
  };

  submitCustomThumbnailWithout = (file, event) => {
    event.preventDefault();
    if (
      file.name.includes(".jpeg") ||
      file.name.includes(".jpg") ||
      file.name.includes(".png")
    ) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("_id", this?.props?.loggedUserInfo?.userData?._id);
      this.setState(
        {
          fileName: `${new Date().getTime("L")}${file.name}`,
          thumbnailFile: file,
        },
        () => {
          if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
              const dataURL = event.target.result;
              this.setState({ thumbnailDataUrl: dataURL }, () => {});
            };

            reader.readAsDataURL(file);
          }

          // api
          //   .obtainUrlUpload({ ...params })
          //   .then((res) => {
          //     this.setState({ urlForUpload: res.data.data, file: formData }, () =>
          //       // this.putFileThumbnailWithout(
          //       //   this.state.urlForUpload,
          //       //   file,
          //       //   params.filename
          //       // )
          //       {

          //       }
          //     );
          //   })
          //   .catch((err) => console.log(err));
        }
      );
    } else {
      toast.error(this.props.t("clip.invalidFile"));
    }
  };

  getImageFromType = (media) => {
    console.log("media", media);
    if (media.mediaType?.includes("image")) return media.url;
    return media.thumbnailUrl;
  };

  render() {
    const width = window.innerWidth;
    const userId = JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
      .authentication.userData._id;
    const profilePicture = JSON.parse(
      localStorage.getItem(REDUX_LOCAL_STORE_KEY)
    ).authentication.userData.profilePicture;

    const name = JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
      .authentication.userData.name;

    var isMobile = width <= 512;
    var isTab = width <= 992;

    return (
      <Fragment>
        {this.state.loading ? <Loader /> : null}
        <div
          style={
            isMobile
              ? { paddingLeft: "20px", paddingRight: "20px", paddingTop: "8px" }
              : {}
          }
          className="right-chat-active"
        >
          <div
            className={`${isMobile || isTab ? " mb-5" : ""} ${
              isTab === true
                ? "middle-sidebar-bottom"
                : "fix-content-subscription clip-page"
            }`}
          >
            <div className="mb-16px">
              <span
                className="clip-pre-url hover"
                onClick={() => this.props.history.push("/clips")}
              >
                {this.props.t("clip.clips")}{" "}
              </span>
              <span className="text-12px t-black darktheme-txt">
                {"/ "}
                {this.props.t("clip.uploadClip")}
              </span>
            </div>

            <div
              onDrop={(event) =>
                this.setState(
                  {
                    inputFileClip: event.dataTransfer.files[0],
                    inputEventClip: event,
                  },
                  () => {
                    this.creatPreviewClip();
                  }
                )
              }
              onDragOver={(event) => this.handleDragOver(event)}
              className="card w-100 clip-container no-border-down"
            >
              <input
                onChange={async (event) => {
                  const controlledFile = await controlMediaSize(
                    [{ file: event.target.files[0] }],
                    this.props.t
                  );
                  if (controlledFile) {
                    this.setState(
                      {
                        inputFileClip: event.target.files[0],
                        inputEventClip: event,
                      },
                      () => {
                        this.creatPreviewClip();
                      }
                    );
                  }
                }}
                type="file"
                accept="video/mp4,video/quicktime"
                ref={this.clickInput}
                style={{ display: "none" }}
              ></input>
              <div className="card-body p-0 mt-3">
                <div className="absolute-center">
                  {this.state.uploadedMedia === "" ? (
                    <img
                      src={`${AWS_URL}logo/upload_placeholder.png`}
                      height={80}
                      width={80}
                      alt=""
                    ></img>
                  ) : (
                    <>
                      {this.state.fileType.includes("video") && (
                        <video
                          controlsList="nodownload nocontextmenu noremoteplayback"
                          oncontextmenu="return false;"
                          className="br-8px w-100 mh-332px"
                          width={"100%"}
                          controls
                        >
                          <source
                            src={this.state.uploadedMediaDataUrl}
                            type="video/mp4"
                          />
                        </video>
                      )}
                      {(this.state.fileType.includes("undefined") ||
                        this.state.fileType.includes("image")) && (
                        <div>{this.props.t("clip.notValid")}</div>
                      )}
                    </>
                  )}
                </div>
                {this.state.uploadedMedia === "" ? (
                  <div className="absolute-center upload-media-text">
                    {this.props.t("clip.uploadMedia")}
                  </div>
                ) : null}
                {isMobile === false ? (
                  <>
                    {this.state.uploadedMedia === "" && (
                      <>
                        <div className="absolute-center wallet-info">
                          {this.props.t("clip.dragAndDrop")}
                        </div>
                        <div className="absolute-center wallet-info">
                          {this.props.t("clip.or")}
                        </div>
                      </>
                    )}
                  </>
                ) : null}
                <div
                  onClick={() => this.clickInput.current.click()}
                  className="absolute-center clip-browse hover"
                >
                  {this.props.t("clip.browseMedia")}
                </div>
              </div>
            </div>
            <div className="select-clip-from-collection clip-data">
              <span>{this.props.t("clip.orSelectCollection")}</span>
              <div className="preview-clip-medias">
                {this.state.allMedia.map((media, index) => (
                  <div
                    className="preview-clip-media pointer"
                    style={{ backgroundPosition: "cover" }}
                    onClick={() => {
                      this.setState({ openVaultModal: true });
                    }}
                  >
                    <img
                      alt=""
                      className="image-colletion"
                      src={media.thumbnailUrl}
                    />
                  </div>
                ))}
                <div
                  className="preview-clip-open-collections pointer"
                  onClick={() => {
                    this.setState({ openVaultModal: true });
                  }}
                >
                  <BiFolder size={"20px"} />
                  <span>{this.props.t("clip.openCollection")}</span>
                </div>
              </div>
            </div>
            <div className="select-category-for-clip clip-data">
              <div className="select-category-clip">
                {this.props.t("clip.selectCategory")}
              </div>
              <div>
                <div className="col-12 mt-1">
                  <div className="stripe-input rounded-pill p-2">
                    <select
                      value={this.props.t("clip.selectCategory")}
                      onChange={(value) =>
                        this.updateCategory(value.target.value)
                      }
                      className={`border-0 bg-payment-white font-dark font-xsss ms-1 w-95 text-color-dark bgc-white`}
                    >
                      <option>{this.props.t("category.selectCategory")}</option>

                      {this.state.categoryList.map((value, index) => (
                        <option value={value._id}>
                          {this.props.t(`category.${value.categoryName}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <br></br>

              {this.state.myCategory.map((value, index) => (
                <Button className="align-center fix-buttom p-0 btn p-2 lh-24 w125 ms-1 ls-3 d-inline-block rounded-xl subscriptionSubscibe border-red detailTitle fw-700 ls-lg text-white">
                  {this.props.t(`category.${value.categoryName}`)}
                  <span
                    onClick={() => this.updateCategory(value._id)}
                    className="card-money-left"
                  >
                    <i className="feather-trash-2 font-xs  text-white Subscription-text"></i>
                  </span>
                </Button>
              ))}

              <div className="select-category-clip">
                {this.props.t("clip.contentType")}
              </div>
              <div className="flex-paginator">
                <span>
                  <button
                    onClick={() => this.setState({ isClipFree: true })}
                    className={`${
                      this.state.isClipFree
                        ? "button-advance-upload-clip-active"
                        : "button-advance-upload-clip-inactive"
                    }`}
                  >
                    {this.props.t("clip.freeContent")}
                  </button>
                </span>
                <span>
                  <button
                    onClick={() => this.setState({ isClipFree: false })}
                    className={`${
                      this.state.isClipFree
                        ? "button-advance-upload-clip-inactive"
                        : "button-advance-upload-clip-active"
                    }`}
                  >
                    {this.props.t("clip.paidContent")}
                  </button>
                </span>
              </div>
              {this.state.isClipFree === false && (
                <div>
                  <div className="select-category-clip mt-16px">
                    {this.props.t("account.price")}
                  </div>
                  <div className="mt-4px">
                    <div className="form-group mb-1 add-card-modal">
                      <div className="flex-paginator align-center relative">
                        <div className="w-100 form-group icon-tab bg-payment-white rounded-3 mb-1">
                          <div style={{ display: "flex", width: "100%" }}>
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                width: "0px",
                                position: "relative",
                                left: "10px",
                              }}
                              className="wallet-url-text"
                            >
                              $
                            </div>
                            <input
                              onChange={(value) =>
                                this.handleChangePrice(value.target.value)
                              }
                              value={this.state.price}
                              type="number"
                              max={1000}
                              min={1}
                              className="modal-input rounded-pill px-4 py-2"
                              placeholder={this.props.t("clip.enterPrice")}
                            />
                          </div>
                          <div
                            style={{
                              textAlignLast: "left",
                              marginTop: "4px",
                            }}
                            className="text-min-price-chat"
                          >
                            {this.props.t("chat.minPrice3")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${isMobile ? "" : "flex-paginator"} `}>
                    <div
                      className={`${
                        isMobile ? "w-100" : "w-50 mr-8px"
                      } form-group mb-1 add-card-modal`}
                    >
                      <div className="flex-paginator align-center relative">
                        <div className="w-100 form-group icon-tab bg-payment-white rounded-3 mb-1">
                          <div className="select-category-clip mb-1">
                            {this.props.t("clip.discount")}
                          </div>
                          <div style={{ display: "flex" }}>
                            <input
                              onChange={(value) =>
                                this.handleDiscount(value.target.value)
                              }
                              value={this.state.discount}
                              type="number"
                              max={100}
                              min={1}
                              className="modal-input rounded-pill px-4 py-2"
                              placeholder={this.props.t("clip.enterPrice")}
                            />
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                width: "0px",
                                position: "relative",
                                right: "25px",
                              }}
                              className="wallet-url-text"
                            >
                              %
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${
                        isMobile ? "w-100" : " w-50 mr-8px"
                      } d-block`}
                    >
                      <div className="select-category-clip mb-1">
                        {this.props.t("clip.startDiscountDate")}
                      </div>
                      <div className="calendar-update-clip">
                        <CustomDatePicker
                          date={this.state.startDiscountDate}
                          onDatePickerChange={(value) =>
                            this.setState({
                              startDiscountDate: moment
                                .utc(value)
                                .locale(localStorage.getItem("i18nextLng")),
                              endDiscountDate: moment
                                .utc(value)
                                .locale(localStorage.getItem("i18nextLng"))
                                .isAfter(this.state.endDiscountDate)
                                ? moment
                                    .utc(value)
                                    .locale(localStorage.getItem("i18nextLng"))
                                : this.state.endDiscountDate,
                            })
                          }
                          minDate={moment
                            .utc()
                            .locale(localStorage.getItem("i18nextLng"))}
                          minTime={moment
                            .utc(this.state.minTime)
                            .locale(localStorage.getItem("i18nextLng"))}
                        />
                      </div>
                    </div>
                    <div className={`${isMobile ? "w-100" : " w-50"} d-block`}>
                      <div className="select-category-clip  mb-1">
                        {this.props.t("clip.endDiscountDate")}
                      </div>
                      <div className="calendar-update-clip">
                        <CustomDatePicker
                          date={this.state.endDiscountDate}
                          onDatePickerChange={(value) =>
                            this.setState({
                              endDiscountDate: moment
                                .utc(value)
                                .locale(localStorage.getItem("i18nextLng")),
                            })
                          }
                          minDate={this.state.startDiscountDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <br></br>
              <div className="advanced-title">
                {this.props.t("queue.advancedSettings")}
              </div>
              <div className="advance-icon-update-clip d-flex-block">
                <div
                  onClick={() =>
                    this.setState({ schedule: !this.state.schedule })
                  }
                  className={`${
                    isMobile && "mb-2"
                  }  icon-upload-clip-calendar hover`}
                >
                  <div className="d-flex align-items-center">
                    <BiCalendarEvent
                      style={{ marginRight: "4px" }}
                    ></BiCalendarEvent>
                    <span className="advance-upload-clip-text">
                      {this.props.t("clip.schedulePublish")}
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => this.clickThumbnail.current.click()}
                  className={`${
                    !isMobile && "mx-2"
                  }  icon-upload-clip-thumbnail hover`}
                >
                  <div className="d-flex align-items-center">
                    <MdOutlineInsertPhoto
                      style={{ marginRight: "4px" }}
                    ></MdOutlineInsertPhoto>
                    <span className="advance-upload-clip-text">
                      {this.props.t("clip.customThumbnail")}
                    </span>
                  </div>
                </div>
                <input
                  onChange={async (event) => {
                    const controlledFile = await controlMediaSize(
                      [{ file: event.target.files[0] }],
                      this.props.t
                    );
                    if (controlledFile) {
                      this.submitCustomThumbnailWithout(
                        event.target.files[0],
                        event
                      );
                    }
                  }}
                  className="display-none"
                  type="file"
                  accept="image/jpeg,image/jpg,image/png,image/heic,image/heif"
                  ref={this.clickThumbnail}
                ></input>
              </div>
              {this.state.thumbnailDataUrl && (
                <img
                  className="br-8px"
                  width={"100%"}
                  src={this.state.thumbnailDataUrl}
                  alt="Thumbnail"
                ></img>
              )}
              {this.state.schedule && (
                <div className="w-sm-50 m-auto scheduleInputtype border-clip-schedule">
                  <CustomDateTimePicker
                    date={this.state.dateClip}
                    minTime={this.state.minTime}
                    onDatePickerChange={(newDate) =>
                      this.onDatePickerChange(newDate)
                    }
                  />
                </div>
              )}
            </div>
            <div className="clip-what clip-data">
              <div
                className="flex-paginator align-items-center"
                style={{ gap: "10px" }}
              >
                <span>
                  <DefaultAvatar
                    id={userId}
                    height={"32px"}
                    width={"32px"}
                    userName={name}
                  >
                    {profilePicture ? (
                      <img
                        src={getUserProfilePic(userId, profilePicture)}
                        className="border-radius-40"
                        width={32}
                        height={32}
                        alt={name}
                        title={name}
                      ></img>
                    ) : null}
                  </DefaultAvatar>
                </span>
                <input
                  placeholder={this.props.t("clip.mind")}
                  className="special-input-clip w-100"
                  value={this.state.description}
                  onChange={(value) =>
                    this.setState({ description: value.target.value })
                  }
                ></input>
              </div>
              {!isMobile && (
                <div className="button-upload-clip">
                  <Button
                    onClick={() => this.submitClip()}
                    className="align-center fix-buttom p-0 btn p-2 lh-24 w125 ms-1 ls-3 d-inline-block rounded-xl subscriptionSubscibe border-red fw-700 ls-lg text-white"
                  >
                    {this.props.t("clip.uploadClip")}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {isMobile && (
            <div style={{ padding: "0px 16px 26px" }} className="w-100">
              <Button
                onClick={() => this.submitClip()}
                className="w-100 align-center p-0 btn p-2 lh-24 w125 ls-3 d-inline-block rounded-xl subscriptionSubscibe border-red fw-700 ls-lg text-white"
              >
                {this.props.t("clip.uploadClip")}
              </Button>
            </div>
          )}
        </div>
        <CollectionModal
          showCollectionModal={this.state.openVaultModal}
          handleCloseModal={() => this.setState({ openVaultModal: false })}
          handleAddMedia={(medias) => this.handleAddMedia(medias)}
          selectedMediaType={"video"}
          hideAllMedia={true}
          hideImages={true}
          hideAudios={true}
          hideVr={true}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(UploadClip);
