import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

class DisclaimerPaymentFooter extends Component {
  render() {
    return (
      <div className="fw-400 font-xssss disclaimerPaymentFooter">
        <a href="/terms-conditions" target="_blank" className="text-grey-600">
          {this.props.t("terms.title")}
        </a>

        <a href="/terms-user" className="text-grey-600">
          {this.props.t("terms.termsFans")}
        </a>
        <a href="/refund-policy" target="_blank" className="text-grey-600">
          {this.props.t("terms.refundpolicy")}
        </a>
        <a href="/terms-user-creator" target="_blank" className="text-grey-600">
          {this.props.t("terms.termsFansCreators")}
        </a>
      </div>
    );
  }
}

export default withTranslation()(DisclaimerPaymentFooter);
