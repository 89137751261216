import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TbMessages } from "react-icons/tb";
import { Image } from "react-bootstrap";
import { MdOutlineMenu } from "react-icons/md";
import { withTranslation } from "react-i18next";
import { BsBagCheck, BsBagCheckFill } from "react-icons/bs";
import {
  BiBell,
  BiCalendarEvent,
  BiBookmark,
  BiCube,
  BiSearch,
  BiBox,
  BiFolder,
  BiMenu,
  BiVideo,
  BiSolidVideo,
  BiCog,
  BiLogOut,
  BiHelpCircle,
  BiMessageAltError,
  BiSolidFolder,
  BiHome,
  BiSolidHome,
  BiSolidCalendarEvent,
  BiSolidBookmark,
  BiSolidCube,
  BiSolidSearch,
  BiSolidBox,
  BiSolidCog,
} from "react-icons/bi";
import CloseIcon from "@mui/icons-material/Close";
import DarkModeSwitch from "./DarkModeSwitch";
import {
  AWS_URL,
  REDUX_LOCAL_STORE_KEY,
  USER_TYPES,
} from "../components/config/constants";
import { notificationActions } from "../redux/actions/notification.action";
import { SocketContext } from "../context/SocketContext";
import { StarReviewModule } from "./common/StarReviewModule";
import { NavDropdown, Row } from "react-bootstrap";
import { MultiNumber } from "./common/MultiNumber";
import i18n from "../i18n";
import { Box } from "@mui/material";
import DefaultAvatar from "./common/DefaultAvatar";
import { getUserProfilePic } from "./helper/GeneralHelper";
import AccountVerifyTick from "./common/AccountVerifyTick";
import UserService from "../services/UserService.js";
import AuthService from "../services/AuthService.js";
import ChatService from "../services/ChatService.js";
import NotificationService from "../services/NotificationService.js";
import GeneralService from "../services/GeneralService.js";

const navItems = [
  {
    to: "/",
    icons: { inactive: BiHome, active: BiSolidHome },
    label: "common.newsfeed",
    onlyCreator: false,
  },
  {
    to: "/clips",
    icons: { inactive: BiVideo, active: BiSolidVideo },
    label: "common.clips",
    onlyCreator: false,
  },
  {
    to: "/queue",
    icons: { inactive: BiCalendarEvent, active: BiSolidCalendarEvent },
    label: "common.queue",
    onlyCreator: true,
    userType: "CREATOR",
  },
  {
    to: "/bookmarks",
    icons: { inactive: BiBookmark, active: BiSolidBookmark },
    label: "common.bookmark",
    onlyCreator: false,
  },
  {
    to: "/my-purchases",
    icons: { inactive: BsBagCheck, active: BsBagCheckFill },
    label: "common.myPurchases",
    onlyCreator: false,
  },
  {
    to: "/subscription-management",
    icons: { inactive: BiCube, active: BiSolidCube },
    label: "common.subscriptions",
    onlyCreator: false,
  },
  {
    to: "/find-a-creator",
    icons: { inactive: BiSearch, active: BiSolidSearch },
    label: "common.findcreator",
    onlyCreator: false,
  },
  {
    to: "/archive",
    icons: { inactive: BiBox, active: BiSolidBox },
    label: "common.archive",
    userType: "CREATOR",
    onlyCreator: true,
  },
  {
    to: "/collection",
    icons: { inactive: BiFolder, active: BiSolidFolder },
    label: "common.collections",
    userType: "CREATOR",
    onlyCreator: true,
  },
  {
    to: "/settings/account-information",
    icons: { inactive: BiCog, active: BiSolidCog },
    label: "common.settings",
    onlyCreator: false,
  },
  {
    to: "",
    icons: null,
    label: null,
    onlyCreator: false,
  },
];
class Header extends Component {
  static contextType = SocketContext;

  constructor(props) {
    super(props);

    this.state = {
      theme: localStorage.getItem("theme"),
      isOpen: false,
      isActive: false,
      activeItem: null,
      isNoti: false,
      loading: false,
      sessionId: null,
      subscribedCreatorList: [],
      searchInput: "",
      newMessageNotification: false,
      creatorList: [],
      userData: {
        name: "",
        website: "",
        description: "",
        instagram: "",
        twitter: "",
        snapChat: "",
        telegram: "",
        reddit: "",
        twitch: "",
        youtube: "",
        amazon: "",
        tiktok: "",
        spotify: "",
        profilePicture: "",
        userName: "",
        email: "",
        phoneNumber: "",
        countryCode: "",
        unreadMessageCount: 0,
      },
    };

    this.toggleSideBar = () => this.setState({ isOpen: !this.state.isOpen });
    this.handleCloseSideBar = () => this.setState({ isOpen: false });
    this.toggleActive = () => this.setState({ isActive: !this.state.isActive });
    this.toggleisNoti = () => this.setState({ isNoti: !this.state.isNoti });
  }

  componentDidMount = () => {
    this.getEmojisList();
    const { location } = this.props;
    const pathname = location.pathname;
    const matchingItem = navItems.find((item) => pathname.startsWith(item.to));
    if (matchingItem) {
      this.setState({ activeItem: matchingItem.to });
    }

    this.context?.socket?.on(
      "receive_message",
      (data, inCommingMessageUserId) => {
        this.getUnreadMessage();
      }
    );
    window.addEventListener("click", this.handleClick);
    window.addEventListener("touch", this.handleTouch);
    this.getUnreadMessage();
    window.scrollTo(0, 1);
    this.getUserDetails();
    this.context?.socket?.on("send_notification_count", (data) => {
      this.props.addNotificationCount(data?.totalCount);
    });

    this.context?.socket?.on("new_message_notification_received", (data) => {
      this.setState({ newMessageNotification: data });
    });

    this.context?.socket?.on(
      "update_status_new_message_notification_received",
      (data) => {
        this.setState({ newMessageNotification: data });
      }
    );

    this.context?.socket?.on(
      "update_status_new_message_notification_received",
      (data) => {
        this.setState({ newMessageNotification: data });
      }
    );

    if (
      Boolean(this?.props?.notificationApiCallInfo?.callNotificationApi) ===
      true
    ) {
      this.getUnreadNotificationCount();
    }
    this.context?.socket?.on("update_message_readed_status", (res) => {
      this.getUnreadMessage();
    });
  };

  getEmojisList = () => {
    GeneralService.emojisList()
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("emojis", JSON.stringify(res?.data?.data));
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  componentWillUnmount = () => {
    window.removeEventListener("click", this.handleClick);
    window.removeEventListener("touch", this.handleTouch);
  };

  handleNavLinkClick = (to) => {
    this.setState({ activeItem: to }, () => {
      this.handleCloseSideBar();
    });
  };

  handleClick = (e) => {
    this.setState({ searchInput: "", creatorList: [] });
  };
  handleTouch = (e) => {
    this.setState({ searchInput: "", creatorList: [] });
  };

  getUnreadMessage = () => {
    ChatService.getUnreadMessage()
      .then((res) => {
        this.setState({ unreadMessageCount: res?.data?.data?.count });
      })
      .catch((err) => {});
  };

  getUserDetails = () => {
    this.setState({ loading: true });
    if (JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY)) === null) {
      this.props.history.push("/");
    } else {
      if (
        JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
          ?.authentication !== undefined
      ) {
        let params = {
          userName: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
            .authentication?.userData?.userName,
        };

        UserService.userDetails({ ...params })
          .then((res) => {
            if (res.status === 200) {
              if (
                localStorage.getItem("i18nextLng") !==
                res?.data?.data?.webLanguageCode
              ) {
                localStorage.setItem(
                  "i18nextLng",
                  res?.data?.data?.webLanguageCode
                );
                i18n.changeLanguage(res?.data?.data?.webLanguageCode);
              }

              this.setState({ loading: false, userData: res.data.data });
              let userType = JSON.parse(
                localStorage.getItem(REDUX_LOCAL_STORE_KEY)
              ).authentication.userData.userType;
              let newUserDetails = JSON.parse(
                localStorage.getItem(REDUX_LOCAL_STORE_KEY)
              );
              newUserDetails.authentication.userData.subscribersVisibility =
                res.data.data.subscribersVisibility === false ? false : true;
              newUserDetails.authentication.userData.userType =
                res.data.data.userType;
              localStorage.setItem(
                REDUX_LOCAL_STORE_KEY,
                JSON.stringify(newUserDetails)
              );
              if (
                userType !== res.data.data.userType &&
                userType !== undefined
              ) {
                window.location.reload();
              }
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      } else {
        this.props.history.push("/");
      }
    }
  };

  componentDidUpdate = (prevState) => {
    if (
      this.props.location.pathname === "/chats" &&
      prevState.newMessageNotification === true
    ) {
      this.handleMessageNotification();
    }
  };

  getSubscribedCreatorList = () => {
    UserService.getSubscribedCreatorList().then((res) => {
      if (res.status === 200) {
        this.setState({ subscribedCreatorList: res?.data?.data });
      }
    });
  };

  getUnreadNotificationCount = () => {
    NotificationService.unreadNotificationCount()
      .then((res) => {
        if (res.status === 200) {
          this.props.addNotificationCount(res?.data?.data?.totalCount);
          this.props.callNotificationApi(false);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  updateNotificationReadCount = () => {
    let params = {};

    NotificationService.updateReadNotificationCount({ ...params })
      .then((res) => {
        if (res.status === 200) {
          this?.props?.updateNotificationCount(res?.data?.data?.totalCount);
          // this?.props?.callNotificationApi(false);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleMessageNotification = () => {
    this.context?.socket?.emit(
      "update_status_new_message_notification",
      this?.props?.loggedUserInfo?.userData?._id
    );
  };

  handleTextChangeSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () =>
      this.handleSearchSubmit()
    );
  };

  handleSearchSubmit = () => {
    if (this.state.searchInput === "") {
      this.setState({ creatorList: [] });
    } else {
      this.setState({ loading: true });
      let params = {
        search: this.state.searchInput,
        page: 0,
        limit: 5,
        sortMethod: 1,
      };

      UserService.creatorList({ ...params })
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              creatorList: res?.data?.data?.data,
              loading: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  };

  handleLogout = () => {
    let params = {
      sid: this?.props?.loggedUserInfo?.userData?.sessionDetails?.sid,
    };
    localStorage.removeItem(REDUX_LOCAL_STORE_KEY);
    AuthService.logout({ ...params })
      .then((res) => {
        if (res.data.status === 200) {
          this.props.logout();
          this.props.removeIpInfo();
          window.location.reload();
        }
      })
      .catch((err) => {
        window.location.reload();
        this.setState({ loading: false });
      });
  };

  render() {
    const navClass = `${this.state.isOpen ? " nav-active" : ""}`;
    const searchClass = `${this.state.isActive ? " show" : ""}`;
    const notiClass = `${this.state.isNoti ? " show" : ""}`;
    const closeClass = `${this.state.isOpen ? "active" : ""}`;

    let { isOpen, searchInput } = this.state;

    const width = window.innerWidth;
    var isMobile = width <= 512;
    var isTab = width <= 1024;

    const darkmodeSwitchStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      fontSize: "14px",
      fontWeight: "400",
      color: "#111",
    };

    const titleMore = (
      <div>
        <BiMenu size={20} style={{ marginRight: "8px" }} />
        <span>{this.props.t("common.more")}</span>
      </div>
    );

    return (
      <>
        <div
          className="nav-header bg-white border-0"
          style={{ height: "64px" }}
          ref={this.myRef}
        >
          <div className="nav-top">
            <Link
              to="/"
              style={{ paddingLeft: isMobile ? "0px" : "24px", gap: "7.24px" }}
            >
              <img
                className="logo-red-circle"
                src={`${AWS_URL}logo/logoRedCircle.png`}
                alt=""
              ></img>
              <img
                className="logo-red-circle"
                src={`${AWS_URL}logo/${
                  this.state.theme === "theme-dark"
                    ? "RedCircleRed.png"
                    : "RedCircleBlack.png"
                }`}
                alt=""
              ></img>
            </Link>

            <div className="d-flex align-items-center">
              {!isMobile && !isTab && (
                <span
                  onClick={this.toggleActive}
                  className="me-2 menu-search-icon mob-menu"
                >
                  <i
                    className={`feather-search text-grey-900 font-sm ${
                      isMobile ? " btn-small" : "btn-round-md"
                    } bg-greylight`}
                  ></i>
                </span>
              )}
              <div
                onClick={() => {
                  this.setState({ activeItem: null });
                }}
              >
                <Link
                  to="/chats"
                  onClick={() => {
                    if (window.location.pathname.includes("/chat")) {
                      window.location.reload();
                    }
                    this.handleMessageNotification();
                  }}
                  className={`pointer py-2 px-1 text-center ms-2 menu-icon chat-active-btn me-2 mob-menu `}
                >
                  <div className="navbar-circle d-flex justify-content-center align-items-center">
                    <TbMessages
                      color="#A3A3A3"
                      size={16}
                      className={` font-sm ${
                        isMobile ? " btn-small" : "btn-round-md"
                      }bg-greylight`}
                    />
                    {this.state.unreadMessageCount > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "4px",
                          height: "8px",
                          width: "8px",
                          background: "var(--primary-red-night, #C00)",
                          borderRadius: "99px",
                        }}
                      ></div>
                    )}
                  </div>
                </Link>
              </div>

              <div
                onClick={() => {
                  this.setState({ activeItem: null });
                }}
              >
                <Link
                  to="/notifications"
                  className={`pointer py-2 px-1 text-center menu-icon chat-active-btn me-2 mob-menu `}
                >
                  <div className="navbar-circle d-flex justify-content-center align-items-center">
                    <BiBell
                      color="#A3A3A3"
                      size={16}
                      className={` font-sm ${
                        isMobile ? " btn-small" : "btn-round-md"
                      }bg-greylight`}
                    />
                    {/* <i
                    className={`feather-bell tab text-grey-900 font-sm  ${isMobile ? " btn-small" : "btn-round-md"
                      } bg-greylight gray-text-important`}
                  ></i> */}
                    {this?.props?.notificationCountInfo?.notificationCount >
                    0 ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "4px",
                          height: "8px",
                          width: "8px",
                          background: "var(--primary-red-night, #C00)",
                          borderRadius: "99px",
                        }}
                      ></div>
                    ) : null}
                  </div>
                </Link>
              </div>

              {isTab && (
                <MdOutlineMenu
                  className="theme-btn"
                  style={{ fontSize: "32px" }}
                  onClick={this.toggleSideBar}
                ></MdOutlineMenu>
              )}
            </div>
          </div>
          <div>
            {!isMobile && !isTab && (
              <span
                className="float-left d-flex header-search"
                style={{ marginLeft: "48px" }}
              >
                <div className="form-group mb-0 icon-input">
                  <i className="feather-search  text-grey-400 searchIcon"></i>
                  <input
                    type="text"
                    placeholder={this.props.t("common.search")}
                    value={searchInput}
                    onChange={this.handleTextChangeSearch}
                    className="bg-gray search-input   fw-500 w300  pt-2 pb-2  pe-3"
                    style={{
                      border: "1px solid #EEE ",
                      borderRadius: "40px",
                      height: "38px",
                      fontSize: "14px",
                    }}
                  />
                </div>
                {searchInput?.length > 2 ? (
                  <div
                    onClick={this.handleSearchSubmit}
                    className="input-search-icon pointer"
                  >
                    <i className="feather-arrow-right-circle font-lg text-grey-400 ms-auto"></i>
                  </div>
                ) : null}
              </span>
            )}
            <div className="container-second-search">
              {this.state.creatorList.map((value, index) => (
                <Box
                  onClick={() => {
                    this.props.history.push(`/${value?.userName}`);
                    window.location.reload();
                    this.setState({ creatorList: [] });
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignContent: "center",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <DefaultAvatar
                    id={value._id}
                    userName={value.name}
                    className="image-2search"
                    height="32px"
                    width="32px"
                    fontSize="14px"
                  >
                    {value.profilePicture ? (
                      <Image
                        alt={value.name}
                        title={value.name}
                        className="image-2search"
                        width={32}
                        height={32}
                        src={`${getUserProfilePic(
                          value._id,
                          value.profilePicture
                        )}`}
                      ></Image>
                    ) : null}
                  </DefaultAvatar>
                  <span className="icon-find-creator">{value.name}</span>
                </Box>
              ))}
            </div>
          </div>
          <span
            className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`}
            id="dropdownMenu3"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={this.toggleisNoti}
          ></span>
          {!isMobile && !isTab && (
            <DarkModeSwitch
              onChangeTheme={() =>
                this.setState({
                  theme:
                    this.state.theme === "theme-dark"
                      ? "theme-white"
                      : "theme-dark",
                })
              }
            />
          )}

          <Link
            to="/chats"
            onClick={this.handleMessageNotification}
            className={`pointer py-2 px-1 text-center ms-2 menu-icon chat-active-btn `}
          >
            <div className="navbar-circle d-flex justify-content-center align-items-center">
              <TbMessages size={16} color="#A3A3A3" />
              {this.state.unreadMessageCount > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "4px",
                    height: "8px",
                    width: "8px",
                    background: "var(--primary-red-night, #C00)",
                    borderRadius: "99px",
                  }}
                ></div>
              )}
            </div>
          </Link>
          <Link
            to="/notifications"
            className={`pointer p-2 text-center menu-icon chat-active-btn `}
          >
            {this?.props?.notificationCountInfo?.notificationCount > 0 ? (
              <span className="notification-ball position-absolute notification-count text-white font-xsssss fw-600"></span>
            ) : null}
            <div className="navbar-circle d-flex justify-content-center align-items-center">
              <BiBell size={16} color="#A3A3A3" />
            </div>
          </Link>

          {isOpen && (
            <div
              style={{
                position: "absolute",
                top: 51,
                left: 0,
                backgroundColor: "#29314585",
                zIndex: "200",
                width: "100%",
                height: "100vh",
              }}
              onClick={this.handleCloseSideBar}
            />
          )}

          <div className={`app-header-search ${searchClass}`}>
            <form className="search-form">
              <div className="form-group searchbox mb-0 border-0 p-1">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchInput}
                  onChange={this.handleTextChangeSearch}
                  className="form-control border-0"
                />
                <i className="input-icon">
                  <ion-icon
                    name="search-outline"
                    role="img"
                    className="md hydrated"
                    aria-label="search outline"
                  ></ion-icon>
                </i>
                <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                  <i
                    className="ti-close font-xs"
                    onClick={this.toggleActive}
                  ></i>
                </span>
              </div>
            </form>
          </div>
        </div>
        <div style={{ display: "flex", height: "calc(100dvh - 64px)" }}>
          <nav className={`navigation bgc-white scroll-bar ${navClass}`}>
            <div className="container">
              {isOpen && (
                <>
                  <div className="d-flex bgc-white justify-content-between align-items-center">
                    <Link
                      to="/"
                      style={{
                        paddingLeft: "24px",
                        gap: "7.24px",
                        paddingTop: "16px",
                      }}
                    >
                      <img
                        className="logo-red-circle"
                        src={`${AWS_URL}logo/logoRedCircle.png`}
                        alt=""
                      ></img>
                      <img
                        className="logo-red-circle"
                        src={`${AWS_URL}logo/${
                          this.state.theme === "theme-dark"
                            ? "RedCircleRed.png"
                            : "RedCircleBlack.png"
                        }`}
                        alt=""
                      ></img>
                    </Link>

                    <button
                      onClick={this.toggleSideBar}
                      className={`nav-menu border-0 bgc-white me-0 me-2 ${closeClass}`}
                    >
                      <CloseIcon className="text-color-dark" />
                    </button>
                  </div>
                  <Row className="pt-4 pb-2 bgc-white">
                    <div className="bgc-white">
                      <span className="float-left d-flex header-search ms-3">
                        <div className="form-group mb-0 icon-input">
                          <i className="feather-search searchIcon navbar-search-icon"></i>
                          <input
                            type="text"
                            placeholder={this.props.t("common.search")}
                            value={searchInput}
                            onChange={this.handleTextChangeSearch}
                            className="bg-gray search-input   fw-500 w250  pt-2 pb-2  pe-3 responsiveSearch"
                            style={{
                              border: "1px solid #EEE ",
                              borderRadius: "40px",
                              height: "35px",
                              fontSize: "14px",
                            }}
                          />
                        </div>
                        {searchInput?.length > 2 ? (
                          <div
                            onClick={this.handleSearchSubmit}
                            className="input-search-icon pointer"
                          >
                            <i className="feather-arrow-right-circle font-lg text-grey-400 ms-auto"></i>
                          </div>
                        ) : null}
                      </span>
                      <div className="container-second-search-mobile">
                        {this.state.creatorList.map((value, index) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            onClick={() => {
                              this.props.history.push(`/${value?.userName}`);
                              window.location.reload();
                              this.setState({ creatorList: [] });
                            }}
                          >
                            <DefaultAvatar
                              id={value._id}
                              userName={value.name}
                              className="image-2search"
                              height="32px"
                              width="32px"
                              fontSize="14px"
                            >
                              {value.profilePicture ? (
                                <Image
                                  className="image-2search"
                                  width={32}
                                  height={32}
                                  src={`${getUserProfilePic(
                                    value._id,
                                    value.profilePicture
                                  )}`}
                                ></Image>
                              ) : null}
                            </DefaultAvatar>
                            <span className="icon-find-creator">
                              {value.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Row>
                  <div className="bgc-white" style={darkmodeSwitchStyle}>
                    <span className="darkThemetext">
                      {this.props.t("common.switch")}
                    </span>
                    <DarkModeSwitch
                      onChangeTheme={() =>
                        this.setState({
                          theme:
                            this.state.theme === "theme-dark"
                              ? "theme-white"
                              : "theme-dark",
                        })
                      }
                    />
                  </div>
                </>
              )}
              <div className="nav-content">
                <div className="nav-wrap bg-white bg-transparent-card">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      style={{ paddingTop: "40px" }}
                      to="/profile"
                      onClick={() => {
                        this.setState({ activeItem: null }, () => {
                          this.handleCloseSideBar();
                        });
                      }}
                    >
                      {
                        <DefaultAvatar
                          id={this.state.userData?._id}
                          userName={this?.props?.loggedUserInfo?.userData?.name}
                          width="50px"
                          height="50px"
                          marginBottom="1rem"
                          online={true}
                        >
                          {this?.props?.loggedUserInfo?.userData
                            ?.profilePicture ? (
                            <Image
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "99px",
                                background: "white",
                              }}
                              className={`hover`}
                              src={getUserProfilePic(
                                this.state.userData?._id,
                                this.state.userData?.profilePicture
                              )}
                            ></Image>
                          ) : null}
                        </DefaultAvatar>
                      }
                    </Link>
                    <Link
                      className="profile-name-container"
                      to="/profile"
                      onClick={this.handleCloseSideBar}
                    >
                      <span
                        style={{ display: "flex" }}
                        className="text-444444 fw-700 p-0 pointer lh-1 fullname-profile font-xsss"
                      >
                        {this?.props?.loggedUserInfo?.userData?.name}{" "}
                        {this.props?.loggedUserInfo?.userData
                          ?.isCreatorVerified ? (
                          <AccountVerifyTick width={"16px"}></AccountVerifyTick>
                        ) : null}
                      </span>
                      <span className="open-font text-user-name username-profile font-xssss">
                        @{this?.props?.loggedUserInfo?.userData?.userName}
                      </span>
                    </Link>
                    {this?.props?.loggedUserInfo?.userData?.userType ===
                    USER_TYPES.CREATOR ? (
                      <>
                        {this?.props?.loggedUserInfo?.userData
                          ?.averageRating && (
                          <div>
                            <StarReviewModule
                              averageRating={
                                this?.props?.loggedUserInfo?.userData
                                  ?.averageRating
                              }
                            />
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            gap: "16px ",
                            color: "black",
                          }}
                        >
                          <div style={{ width: "88px" }}>
                            <span
                              title={this.props.t("common.postss")}
                              className={`${
                                isMobile ? " font-xssss" : " font-xsss"
                              } `}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span className="profile-count">
                                <MultiNumber
                                  numberToFix={
                                    this.state.userData?.countObject
                                      ?.postTotalCount
                                  }
                                />
                              </span>
                              <span className="profile-title">
                                {this.props.t("common.postss")}
                              </span>
                            </span>
                            <span
                              title={this.props.t("common.likes")}
                              className={`${
                                isMobile ? " font-xssss" : " font-xsss"
                              } `}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span className="profile-count">
                                <MultiNumber
                                  numberToFix={
                                    this.state.userData?.countObject
                                      ?.reactionCount
                                  }
                                />
                              </span>
                              <span className="profile-title">
                                {this.props.t("common.likes")}
                              </span>
                            </span>
                          </div>
                          <div style={{ width: "88px" }}>
                            <Link
                              to={"/settings/manage-subscribers"}
                              className="subscribers-profile-indicator"
                            >
                              <span
                                title={this.props.t("common.subs")}
                                className={`${
                                  isMobile ? " font-xssss" : " font-xsss"
                                } `}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <span className="profile-count">
                                  <MultiNumber
                                    numberToFix={
                                      this.state.userData?.countObject
                                        ?.subscriberCount
                                    }
                                  />
                                </span>
                                <span className="profile-title">
                                  {this.props.t("common.subs")}
                                </span>
                              </span>
                            </Link>

                            <span
                              title={this.props.t("common.comments")}
                              className={`${
                                isMobile ? " font-xssss" : " font-xsss"
                              } `}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span className="profile-count">
                                <MultiNumber
                                  numberToFix={
                                    this.state.userData?.countObject
                                      ?.commentCount
                                  }
                                />
                              </span>
                              <span className="profile-title">
                                {this.props.t("common.comments")}
                              </span>
                            </span>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="navbar-container">
                  <ul>
                    {navItems.map((item) =>
                      item.label ? (
                        item.label &&
                        (!item.onlyCreator ||
                          (item.onlyCreator &&
                            this?.props?.loggedUserInfo?.userData?.userType ===
                              USER_TYPES.CREATOR)) ? (
                          <li key={item.to} className="navbar-item">
                            <NavLink
                              to={item.to}
                              activeClassName=""
                              onClick={() => this.handleNavLinkClick(item.to)}
                              className={`${
                                this.props.location.pathname === item.to
                                  ? "nav-tab-active"
                                  : "nav-content-btn open-font"
                              }`}
                            >
                              {React.createElement(
                                this.props.location.pathname === item.to
                                  ? item.icons.active
                                  : item.icons.inactive,
                                { size: 20, style: { marginRight: "8px" } }
                              )}
                              <span>{this.props.t(item.label)}</span>
                            </NavLink>
                          </li>
                        ) : null
                      ) : (
                        <li className="navbar-item">
                          <NavDropdown
                            title={titleMore}
                            className="menudropdown"
                          >
                            <NavDropdown.Item
                              href="/support"
                              className={`nav-content-btn open-font`}
                              style={{ background: "transparent" }}
                            >
                              <div>
                                <BiMessageAltError
                                  size={20}
                                  style={{ marginRight: "8px" }}
                                />
                              </div>
                              <span style={{ whiteSpace: "break-spaces" }}>
                                {this.props.t("common.support")}
                              </span>
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              href="/Faq"
                              className={`nav-content-btn open-font`}
                              style={{ background: "transparent" }}
                            >
                              <div>
                                <BiHelpCircle
                                  size={20}
                                  style={{ marginRight: "8px" }}
                                />
                              </div>
                              <span style={{ whiteSpace: "break-spaces" }}>
                                {this.props.t("common.faq")}
                              </span>
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              className={`nav-content-btn open-font`}
                              onClick={this.handleLogout}
                              style={{ background: "transparent" }}
                            >
                              <div>
                                <BiLogOut
                                  size={20}
                                  style={{ marginRight: "8px" }}
                                />
                              </div>
                              <span style={{ whiteSpace: "break-spaces" }}>
                                {this.props.t("common.logout")}
                              </span>
                            </NavDropdown.Item>
                          </NavDropdown>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                {isMobile || isTab ? (
                  // <div style={Privacy}>
                  //   <a href="/terms-conditions" className="conditionLink">
                  //     {this.props.t("common.terms")}
                  //   </a>
                  //   <span className="bullet">&#x2022;</span>
                  //   <a href="/privacy-policy" className="conditionLink">
                  //     {this.props.t("common.privacy")}
                  //   </a>
                  // </div>
                  <div className="trendingPostFooter">
                    <div>
                      <Link
                        style={{ whiteSpace: "nowrap" }}
                        to="/terms-conditions"
                        className="conditionLink"
                      >
                        {this.props.t("common.terms")}
                      </Link>
                    </div>
                    <div>
                      <span className="bullet">&#x2022;</span>
                      <Link
                        style={{ whiteSpace: "nowrap" }}
                        to="/privacy-policy"
                        className="conditionLink"
                      >
                        {this.props.t("common.privacy")}
                      </Link>
                    </div>
                    <div>
                      <span className="bullet">&#x2022;</span>
                      <Link
                        style={{ whiteSpace: "nowrap" }}
                        to="/complaint"
                        className="conditionLink"
                      >
                        {this.props.t("terms.complaint")}
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </nav>
          <div
            id="mainScroll"
            style={
              (this.props.location.pathname === "/clips" ||
                this.props.location.pathname.includes("single-clip")) &&
              !isMobile
                ? {
                    height: "100dvh",
                    position: "relative",
                    top: "-64px",
                    width: "100%",
                    overflowX: "hidden",
                  }
                : {
                    height: "calc(100dvh - 64px)",
                    overflowY: "scroll",
                    width: "100%",
                    overflowX: "hidden",
                  }
            }
          >
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedUserInfo: state.authentication,
    notificationCountInfo: state.notificationCountInfo,
    notificationApiCallInfo: state.notificationApiCallInfo,
  };
};

const mapDispatchToProps = {
  addNotificationCount: notificationActions.addNotificationCount,
  callNotificationApi: notificationActions.callNotificationApi,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header))
);
