import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ImPause2, ImPlay3 } from "react-icons/im";
import CustomVrPlayer from "../common/CustomVrPlayer.jsx";
import { MdFullscreen } from "react-icons/md";
import { Tb360View } from "react-icons/tb";
import { FaPause, FaPlay } from "react-icons/fa";
import { BiVolumeFull, BiVolumeLow, BiVolumeMute } from "react-icons/bi";
class CustomVideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.playerRef = React.createRef();

    this.state = {
      isPaused: true,
      isMuted: false,
      showChangePlayStatus: false,
      gyroscope: false,
      firstPause: true,
      isUpdate: false,
      loaded: false,
      showVideo: false,
      audioVolume: 1,
      videoTime: 0,
    };
  }

  componentDidMount = () => {
    const img = document.getElementById(this.props.id + "image");
    const base = document.getElementById(this.props?.id + "base-video");

    document.addEventListener("keydown", this.handleKeyDown);
    base.addEventListener("dblclick", this.handleDouble);
    setInterval(() => {
      var video = document.getElementById(this.props.id);
      if (video?.currentTime) {
        this.setState({ videoTime: video?.currentTime });
      }
    }, 100);
    if (img) {
      img.onload = () => {
        this.setState({ showVideo: true });
      };
    } else {
      this.setState({ showVideo: true });
    }
  };

  componentWillUnmount() {
    const base = document.getElementById(this.props?.id + "base-video");
    document.removeEventListener("keydown", this.handleKeyDown);
    base.removeEventListener("dblclick", this.handleDouble);
  }

  handleKeyDown = (key) => {
    // key.preventDefault();
    if (key.keyCode === 27) {
      this.disableFullscreen();
    }
  };

  disableFullscreen = () => {
    var video = document.getElementById(this.props.id + "top-scene");
    var baseVideo = document.getElementById(this.props?.id + "base-video");
    var scene = document
      .getElementById(this.props.id + "-vr-scene")
      .querySelector("canvas");
    if (!document.fullscreenElement) {
    } else {
      scene.style.height = "100%";
      baseVideo.style.maxHeight = "70vh";
      baseVideo.style.height = document.getElementById(
        this.props.id + "image"
      )?.clientWidth;
      baseVideo.style.height = document.getElementById(
        this.props.id + "image"
      )?.clientHeight;

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
      if (video.exitFullscreen) {
        video.exitFullscreen();
      } else if (video.mozCancelFullScreen) {
        // Firefox
        video.mozCancelFullScreen();
      } else if (video.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        video.webkitExitFullscreen();
      } else if (video.msExitFullscreen) {
        // IE/Edge
        video.msExitFullscreen();
      }
    }
  };

  handleDouble = () => {};

  goFullscreenIphone = () => {
    var menu = document.getElementById(this.props.id + "video-menu");
    var scene = document
      .getElementById(this.props.id + "-vr-scene")
      .querySelector("canvas");
    if (scene.className.includes("fixed-fullscreen-canvas-iphone")) {
      menu.classList.remove("fixed-fullscreen-menu-iphone");
      scene.classList.remove("fixed-fullscreen-canvas-iphone");
    } else {
      menu.classList.add("fixed-fullscreen-menu-iphone");
      scene.classList.add("fixed-fullscreen-canvas-iphone");
    }
  };
  goFullscreen = () => {
    var video = document.getElementById(this.props.id + "top-scene");
    var baseVideo = document.getElementById(this.props?.id + "base-video");
    var scene = document
      .getElementById(this.props.id + "-vr-scene")
      .querySelector("canvas");
    if (navigator.platform.includes("iPhone")) {
      this.goFullscreenIphone();
    } else {
      if (!document.fullscreenElement) {
        video.style.height = "100vh";
        video.style.width = "100vw";
        baseVideo.style.maxHeight = "100vh";
        scene.style.height = "100vh";
        scene.style.width = "auto";
        if (video.requestFullscreen) {
          video.requestFullscreen();
        } else if (video.mozRequestFullScreen) {
          // Firefox
          video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) {
          // Chrome, Safari and Opera
          video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) {
          // IE/Edge
          video.msRequestFullscreen();
        }
      } else {
        scene.style.height = "100%";
        scene.style.width = "100%";
        baseVideo.style.maxHeight = "70vh";
        video.style.height = "100%";

        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          // Chrome, Safari and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // IE/Edge
          document.msExitFullscreen();
        }
        if (video.exitFullscreen) {
          video.exitFullscreen();
        } else if (video.mozCancelFullScreen) {
          // Firefox
          video.mozCancelFullScreen();
        } else if (video.webkitExitFullscreen) {
          // Chrome, Safari and Opera
          video.webkitExitFullscreen();
        } else if (video.msExitFullscreen) {
          // IE/Edge
          video.msExitFullscreen();
        }
      }
    }
  };

  manageChangePlayStatus = (e, play) => {
    if (
      (e?.target?.className === "custom-video-player-menu" ||
        e?.target?.parentNode?.className === "custom-video-player-menu" ||
        e?.target?.parentNode?.parentNode?.className ===
          "custom-video-player-menu" ||
        e?.target?.parentNode?.parentNode?.parentNode?.className ===
          "custom-video-player-menu" ||
        e?.target?.parentNode?.parentNode?.parentNode?.parentNode?.className ===
          "custom-video-player-menu" ||
        e?.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
          ?.className === "custom-video-player-menu") &&
      !play
    ) {
    } else {
      if (
        (this.state.firstPause || this.state.isPaused) &&
        window.innerWidth <= 512 &&
        !(this.props.media?.isVR || this.props.media?.projectionType)
      ) {
        this.fullScreenMobile();
      }
      if (this.state.firstPause) {
        if (
          (this.props.media?.isVR || this.props.media?.projectionType) &&
          window.innerWidth <= 512
        ) {
          this.active360();
        }
        this.setState({ firstPause: false });
      }
      if (true) {
        var video = document.getElementById(this.props.id);
        if (video.paused) {
          this.setState(
            {
              isPaused: false,
              ...(this.state.firstPause
                ? { firstPause: false }
                : { showChangePlayStatus: true }),
            },
            () => {
              setTimeout(() => {
                this.setState({ showChangePlayStatus: false });
              }, 500);
              video.play();
            }
          );
        } else {
          this.setState(
            {
              isPaused: true,
              ...(this.state.firstPause
                ? { firstPause: false }
                : { showChangePlayStatus: true }),
            },
            () => {
              setTimeout(() => {
                this.setState({ showChangePlayStatus: false });
              }, 500);
              video.pause();
            }
          );
        }
      }
    }
  };

  manageAudioVolume = (volume) => {
    var video = document.getElementById(this.props.id);
    this.setState({ audioVolume: volume }, () => {
      video.volume = volume;
    });
  };

  handleMuteVideo = () => {
    if (this.state.isMuted) {
      this.setState({ isMuted: false }, () => {
        var video = document.getElementById(this.props.id);
        video.volume = this.state.audioVolume;
      });
    } else {
      this.setState({ isMuted: true }, () => {
        var video = document.getElementById(this.props.id);
        video.volume = 0;
      });
    }
  };

  changeSwipeable = (value) => {};

  manageTimeVideo = (value) => {
    var video = document.getElementById(this.props.id);
    this.setState({ videoTime: value }, () => {
      video.currentTime = value;
    });
  };

  active360 = () => {
    this.setState({ gyroscope: true }, () => {
      this.checkGyroscopePermission();
    });
  };

  checkGyroscopePermission = () => {
    // Gli alert ci sono solo per scopo di test
    if (
      typeof DeviceMotionEvent !== "undefined" &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      if (
        typeof DeviceMotionEvent !== "undefined" &&
        typeof DeviceMotionEvent.requestPermission === "function"
      ) {
        DeviceMotionEvent.requestPermission()
          .then((permissionState) => {
            if (permissionState === "granted") {
              // alert("Hai il permesso per usare il giroscopio.");
            } else if (permissionState === "denied") {
              // alert("Hai rifiutato il permesso per usare il giroscopio.");
            } else {
              // alert(
              //   "Il permesso per usare il giroscopio non è stato richiesto."
              // );
            }
          })
          .catch(console.error);
      } else if (
        typeof DeviceMotionEvent !== "undefined" &&
        typeof DeviceMotionEvent.requestPermission !== "function"
      ) {
        // alert(
        //   "Il tuo browser non supporta la richiesta di permesso per il giroscopio."
        // );
      } else {
        // alert("Il giroscopio non è disponibile su questo dispositivo.");
      }
    }
  };

  fullScreenMobile = () => {
    //
    var video = document.getElementById(this.props.id);
    if (
      navigator.platform.includes("iPhone") &&
      (this.props.media?.isVR || this.props.media?.projectionType)
    ) {
      this.goFullscreenIphone();
    } else {
      if (!document.fullscreenElement) {
        if (video.requestFullscreen) {
          video.requestFullscreen();
        } else if (video.mozRequestFullScreen) {
          // Firefox
          video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) {
          // Chrome, Safari and Opera
          video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) {
          // IE/Edge
          video.msRequestFullscreen();
        }
      }
    }
  };
  showMenu = (show) => {
    let menu = document.getElementById(this.props.id + "video-menu");
    if (show) {
      if (menu) {
        menu.style.opacity = 1;
      }
    } else {
      if (menu) {
        menu.style.opacity = 0;
      }
    }
  };

  adjustVideoDuration = (time) => {
    let minutes = parseInt(time / 60) ? parseInt(time / 60) : 0;
    let secons = parseInt(time - minutes * 60)
      ? parseInt(time - minutes * 60)
      : 0;
    let adjustSeconds = secons > 9 ? secons : "0" + secons;
    let finalTime = minutes + ":" + adjustSeconds;
    return finalTime;
  };

  render() {
    const width = window.innerWidth;
    var isMobile = width <= 512;
    return (
      <div
        onClick={(e) => {
          if (!this.props.media?.isVR && !this.props.media?.projectionType) {
            this.manageChangePlayStatus(e);
          }
        }}
        onTouch={(e) => {
          this.showMenu(true);
          clearTimeout(this.timeoutID);
          this.timeoutID = setTimeout(() => {
            this.showMenu(false);
          }, 2000);
          if (!this.props.media?.isVR && !this.props.media?.projectionType) {
            this.manageChangePlayStatus(e);
          }
        }}
        style={{
          display: "flex",
          justifyContent: "center",

          // width: "inherit",
        }}
        id={this.props.id + "top-scene"}
        onMouseEnter={() => {
          this.showMenu(true);
        }}
        onMouseLeave={() => {
          this.showMenu(false);
        }}
        onMouseMove={() => {
          this.showMenu(true);
          clearTimeout(this.timeoutID);
          this.timeoutID = setTimeout(() => {
            this.showMenu(false);
          }, 2000);
        }}
      >
        {this.props.thumbNailUrl && (
          <img
            id={this.props.id + "image"}
            style={{
              maxHeight: "70vh",
              position: "absolute",
              top: "0px",
              left: "0px",
              opacity: "0",
              height: "auto",
              width: "100%",
            }}
            src={this.props.thumbNailUrl}
            alt=""
          ></img>
        )}
        <div
          style={{
            maxHeight: "70vh",
            position: "relative",
          }}
          id={this.props?.id + "base-video"}
        >
          {!this.state.firstPause && (
            <div
              id={this.props.id + "video-menu"}
              onClick={(e) => {
                // e.preventDefault()
                e.stopPropagation();
              }}
              onDrag={(e) => {
                e.stopPropagation();
              }}
              onMouseEnter={() => {
                this.changeSwipeable(false);
              }}
              onMouseLeave={() => {
                this.changeSwipeable(true);
              }}
              onTouchStart={() => {
                this.changeSwipeable(false);
              }}
              onTouchEnd={() => {
                this.changeSwipeable(true);
              }}
              className={`custom-video-player-menu ${
                this.props.media?.isVR || this.props.media?.projectionType
                  ? ""
                  : navigator.platform.includes("iPhone")
                  ? "absolute-hide"
                  : ""
              }`}
              style={{
                bottom:
                  this.props.media?.isVR || this.props.media?.projectionType
                    ? "0px"
                    : "0px",
              }}
              tabIndex={1}
            >
              <div
                style={{
                  width: "100%",
                  height: "0px",
                  position: "absolute",
                  top: "-20px",
                }}
                className="video-time-bar"
              >
                <input
                  type="range"
                  min={0}
                  max={document.getElementById(this.props.id)?.duration}
                  step={0.1}
                  onMouseDown={() => {
                    var video = document.getElementById(this.props.id);
                    video.pause();
                  }}
                  onMouseUp={() => {
                    var video = document.getElementById(this.props.id);
                    video.play();
                  }}
                  onTouchStart={() => {
                    var video = document.getElementById(this.props.id);
                    video.pause();
                  }}
                  onTouchEnd={() => {
                    var video = document.getElementById(this.props.id);
                    video.play();
                  }}
                  onChange={(value) => {
                    this.manageTimeVideo(value.target.value);
                  }}
                  value={this.state.videoTime}
                  style={{
                    width: "100%",
                  }}
                ></input>
              </div>
              {this.state.isPaused && (
                <FaPlay
                  style={{ fontSize: "14px", marginLeft: "8px" }}
                  className="hover"
                  onClick={(e) => {
                    this.manageChangePlayStatus(e, true);
                  }}
                ></FaPlay>
              )}
              {!this.state.isPaused && (
                <FaPause
                  style={{ fontSize: "14px", marginLeft: "8px" }}
                  className="hover"
                  onClick={(e) => {
                    this.manageChangePlayStatus(e, true);
                  }}
                ></FaPause>
              )}

              <span
                style={{
                  marginLeft: "auto",
                  fontSize: "14px",
                  marginRight: "12px",
                }}
              >
                {this.adjustVideoDuration(this.state.videoTime) +
                  " / " +
                  this.adjustVideoDuration(
                    document.getElementById(this.props.id)?.duration
                  )}
              </span>
              <span
                onMouseEnter={() => {
                  const inputAudio = document.getElementById(
                    this.props.id + "-video-volume"
                  );
                  inputAudio.style.opacity = 1;
                  inputAudio.style.width = "120px";
                }}
                onMouseLeave={() => {
                  const inputAudio = document.getElementById(
                    this.props.id + "-video-volume"
                  );
                  inputAudio.style.width = "0px";
                  clearTimeout(this.timerHideAudio);
                  this.timerHideAudio = setTimeout(() => {
                    inputAudio.style.opacity = 0;
                  }, 500);
                }}
                style={{
                  alignItems: "center",
                  display: "flex",
                  height: "100%",
                  marginRight: "8px",
                }}
              >
                {(this.state.audioVolume === "0" || this.state.isMuted) && (
                  <BiVolumeMute
                    onClick={() => {
                      this.handleMuteVideo();
                    }}
                    style={{ fontSize: "18px" }}
                  ></BiVolumeMute>
                )}
                {this.state.audioVolume < 0.5 &&
                  this.state.audioVolume > 0 &&
                  !this.state.isMuted && (
                    <BiVolumeLow
                      onClick={() => {
                        this.handleMuteVideo();
                      }}
                      style={{ fontSize: "18px" }}
                    ></BiVolumeLow>
                  )}
                {this.state.audioVolume >= 0.5 && !this.state.isMuted && (
                  <BiVolumeFull
                    onClick={() => {
                      this.handleMuteVideo();
                    }}
                    style={{ fontSize: "18px" }}
                  ></BiVolumeFull>
                )}

                <input
                  id={this.props.id + "-video-volume"}
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  onClick={(e) => {}}
                  onChange={(value) => {
                    this.manageAudioVolume(value.target.value);
                  }}
                  value={this.state.audioVolume}
                  style={{ marginLeft: "8px" }}
                ></input>
              </span>
              {isMobile &&
                (this.props.media?.isVR ||
                  this.props.media?.projectionType) && (
                  <Tb360View
                    style={{
                      fontSize: "18px",
                      marginRight: "8px",
                    }}
                    className="hover"
                    onClick={(e) => {
                      this.active360();
                    }}
                  ></Tb360View>
                )}
              {(!navigator.platform.includes("iPhone") ||
                this.props.media?.isVR ||
                this.props.media?.projectionType) && (
                <MdFullscreen
                  style={{
                    fontSize: "24px",
                    marginRight: "8px",
                  }}
                  className="hover"
                  onClick={(e) => {
                    if (isMobile) {
                      if (
                        this.props.media?.isVR ||
                        this.props.media?.projectionType
                      ) {
                        this.goFullscreen();
                      } else {
                        this.fullScreenMobile();
                      }
                    } else {
                      if (
                        this.props.media?.isVR ||
                        this.props.media?.projectionType
                      ) {
                        this.goFullscreen();
                      } else {
                        if (!document.fullscreenElement) {
                          var video = document.getElementById(this.props.id);
                          if (document.requestFullscreen) {
                            video.requestFullscreen();
                          } else if (video.mozRequestFullScreen) {
                            // Firefox
                            video.mozRequestFullScreen();
                          } else if (video.webkitRequestFullscreen) {
                            // Chrome, Safari and Opera
                            video.webkitRequestFullscreen();
                          } else if (video.msRequestFullscreen) {
                            // IE/Edge
                            video.msRequestFullscreen();
                          }
                        }
                      }
                    }
                  }}
                ></MdFullscreen>
              )}
            </div>
          )}
          {this.state.showVideo && (
            <div
              style={
                this.props.media?.isVR || this.props.media?.projectionType
                  ? {
                      width:
                        document.getElementById(this.props.id + "image")
                          ?.clientWidth > 0
                          ? document.getElementById(this.props.id + "image")
                              ?.clientWidth + "px"
                          : "100%",
                      height:
                        document.getElementById(this.props.id + "image")
                          ?.clientHeight > 0
                          ? document.getElementById(this.props.id + "image")
                              ?.clientHeight + "px"
                          : "100%",
                    }
                  : {}
              }
            >
              {(this.props.media?.isVR || this.props.media?.projectionType) && this.props.id ? (
                <CustomVrPlayer
                  gyroscope={this.state.gyroscope}
                  t={this.props.t}
                  media={this.props.media}
                  pause={() => {
                    this.manageChangePlayStatus();
                  }}
                  src={this.props.url}
                  poster={this.props.thumbNailUrl}
                  id={this.props.id}
                  height={"100%"}
                  width={"100%"}
                ></CustomVrPlayer>
              ) : (
                <video
                  poster={this.props.thumbNailUrl}
                  controls={false}
                  controlslist="nodownload"
                  id={this.props.id}
                  className="mobile-fix-custom-video"
                  webkit-playsinline
                  playsinline
                  x-webkit-airplay="allow"
                >
                  <source
                    playsinline={true}
                    webkit-playsinline={true}
                    src={this.props.url}
                  ></source>
                </video>
              )}
            </div>
          )}
        </div>
        {this.state.firstPause && (
          <div
            className={`${
              this.props.isFancyBox
                ? "pause-button-clip"
                : "pause-button-custom-video"
            }`}
            style={{
              top: "0px",
              height: "100%",
            }}
            onClick={(e) => {
              this.manageChangePlayStatus(e);
            }}
            onTouch={(e) => {
              this.manageChangePlayStatus(e);
            }}
          >
            <div className="pause-internal-button-collection">
              <ImPlay3
                style={{
                  width: "36px",
                  height: "36px",
                  color: "#FFFFFF",
                  paddingLeft: "4px",
                }}
              ></ImPlay3>
            </div>
          </div>
        )}
        {this.state.showChangePlayStatus && this.state.isPaused && (
          <div
            className={`${
              this.props.isFancyBox
                ? "pause-button-clip"
                : "pause-button-custom-video"
            }`}
            style={{
              top: "0px",
              height: "100%",
            }}
          >
            <div className="pause-internal-button-collection animation-pause-play animation-pause-play">
              <ImPause2
                style={{
                  width: "28px",
                  height: "28px",
                  color: "#FFFFFF",
                }}
              ></ImPause2>
            </div>
          </div>
        )}
        {this.state.showChangePlayStatus && !this.state.isPaused && (
          <div
            className={`${
              this.props.isFancyBox
                ? "pause-button-clip"
                : "pause-button-custom-video"
            }`}
            style={{
              top: "0px",
              height: "100%",
            }}
          >
            <div className="pause-internal-button-collection animation-pause-play animation-pause-play">
              <ImPlay3
                style={{
                  width: "36px",
                  height: "36px",
                  color: "#FFFFFF",
                  paddingLeft: "4px",
                }}
              ></ImPlay3>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomVideoPlayer);
