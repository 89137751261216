import { Box, Skeleton, Stack } from "@mui/material";
import React, { Component } from "react";

export default class TipLoader extends Component {
  render() {
    return (
      <Stack spacing={1}>
        <Skeleton animation="wave" width={"30%"} height={20} />
        <Box
          sx={{
            width: "100%",
            p: 1,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Skeleton
            className="skeleton-placeholder"
            animation="wave"
            variant="rounded"
            height={35}
            width={"24%"}
            maxWidth={"25%"}
          />
          <Skeleton
            className="skeleton-placeholder"
            animation="wave"
            variant="rounded"
            height={35}
            width={"24%"}
            maxWidth={"25%"}
          />
          <Skeleton
            className="skeleton-placeholder"
            animation="wave"
            variant="rounded"
            height={35}
            width={"24%"}
            maxWidth={"25%"}
          />
          <Skeleton
            className="skeleton-placeholder"
            animation="wave"
            variant="rounded"
            height={35}
            width={"24%"}
            maxWidth={"25%"}
          />
        </Box>
        <Skeleton animation="wave" width={"40%"} height={20} />
        <Skeleton
          className="skeleton-placeholder"
          animation="wave"
          variant="rounded"
          width={"100%"}
          height={125}
        />
        <Box
          sx={{
            width: "100%",
            p: 2,
            justifyContent: "flex-end",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Skeleton
            className="skeleton-placeholder"
            animation="wave"
            variant="rounded"
            width={100}
            height={35}
            sx={{ mr: 1.5 }}
          />
          <Skeleton
            className="skeleton-placeholder"
            animation="wave"
            variant="rounded"
            width={100}
            height={35}
          />
        </Box>
      </Stack>
    );
  }
}
