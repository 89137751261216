import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
// import CustomAudioPlayer from "./CustomAudioPlayer";
import CustomVideoPlayer from "./CustomVideoPlayer";
import { AWS_URL } from "../config/constants";
import { BsXLg } from "react-icons/bs";
import { BiSolidLockAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { getUserProfilePic } from "../helper/GeneralHelper";
import { withTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MdOutlineZoomIn, MdOutlineZoomOut } from "react-icons/md";
import { FaPause } from "react-icons/fa";
import { IoPlayOutline } from "react-icons/io5";

class CustomFancybox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoPlay: false,
      current: 0,
      isZoom: false,
      isTouch: false,
      zoom: 1,
      prevTouchX: 0,
      prevTouchY: 0,
      prevTouch2X: 0,
      prevTouch2Y: 0,
      x: 0,
      y: 0,
    };
  }

  componentDidMount() {
    this.setState({ current: this.controlAudio(this.props.current) });
  }

  componentWillUnmount() {}

  controlAudio = (index) => {
    let newIndex = 0;
    if (this.props.place === "postMedias") {
      newIndex = this.props.medias
        .filter((media) => !media.type?.includes("audio"))
        .findIndex(
          (media) =>
            media._id + media?.name ===
            this.props.medias[index]._id + this.props.medias[index]?.name
        );
      return newIndex;
    } else {
      newIndex = this.props.medias
        .filter((media) => !media.type?.includes("audio"))
        .findIndex(
          (media) =>
            media.messageId + media?.name ===
            this.props.medias[index].messageId + this.props.medias[index]?.name
        );
      return newIndex;
    }
  };

  handleWheel = (e) => {
    console.log(e.deltaY);
    if (e.deltaY > 0) {
      this.setState({
        current: this.state.current - this.state.current > 1 ? 1 : 0,
      });
    } else {
      this.setState({
        current:
          this.state.current + this.state.current <
          this.props.medias.filter((media) => !media.type?.includes("audio"))
            .length
            ? 1
            : 0,
      });
    }
  };

  handleMouseDown = (e) => {
    e.preventDefault();
  };

  handleMouseUp = () => {};

  handleMouseMove = (e) => {
    e.preventDefault();
    if (window.innerWidth > 512) {
      if (this.state.isZoom) {
        const img = document.getElementById(
          this.props.index + "-" + this.state.current + "-carousel"
        );
        const divElement = e.target;
        const rect = divElement.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const divW = e.target.clientWidth;
        const divH = e.target.clientHeight;
        const tranX = ((x - divW / 2) / divW) * -100;
        const tranY = ((y - divH / 2) / divH) * -100;
        img.style.transform = `scale(2.5) translate(${tranX}%, ${tranY}%)`;
      }
    }
  };

  handleTouchMove = (e) => {
    if (this.state.isTouch && this.state.isZoom) {
      if (e.touches.length > 1) {
        if (e.touches.length === 2) {
          const touch = e.touches[0];
          const touch2 = e.touches[1];
          const zoom =
            this.state.zoom +
            (this.state.prevTouchX + this.state.prevTouch2X !==
            touch.clientX + touch2.clientX
              ? (this.state.prevTouchX +
                  this.state.prevTouch2X -
                  (touch.clientX + touch2.clientX)) /
                100
              : 0) +
            (this.state.prevTouchY + this.state.prevTouch2Y !==
            touch.clientY + touch2.clientY
              ? (this.state.prevTouchY +
                  this.state.prevTouch2Y -
                  (touch.clientY + touch2.clientY)) /
                100
              : 0);
          this.setState(
            {
              prevTouchX: touch.clientX,
              prevTouchY: touch.clientY,
              prevTouch2X: touch2.clientX,
              prevTouch2Y: touch2.clientY,
              zoom: zoom,
            },
            () => {
              const img = document.getElementById(
                this.props.index + "-" + this.state.current + "-carousel"
              );
              img.style.transform = `scale(${this.state.zoom}) translate(${this.state.x}px, ${this.state.y}px)`;
            }
          );
        } else {
          console.log("no max fingers is 2");
        }
      } else {
        const touch = e.touches[0];
        const { prevTouchX, prevTouchY } = this.state;
        this.setState(
          {
            x: this.state.x + touch.clientX - prevTouchX,
            y: this.state.y + touch.clientY - prevTouchY,
            prevTouchX: touch.clientX,
            prevTouchY: touch.clientY,
          },
          () => {
            const img = document.getElementById(
              this.props.index + "-" + this.state.current + "-carousel"
            );
            img.style.transform = `scale(${this.state.zoom}) translate(${this.state.x}px, ${this.state.y}px)`;
          }
        );
      }
    }
  };

  // handleTouchMove = (e) => {
  //   const img = document.getElementById(
  //     this.props.index + "-" + this.state.current + "-carousel"
  //   );
  //   const movementX = img.preX - e.touches[0].clientX;
  //   const movementY = img?.preY - e.touches[0].clientY;
  //   console.log(movementX, movementY);
  //   img.preX = e.touches[0].clientX;
  //   img.preY = e.touches[0].clientY;
  //   if (e.touches?.length > 1) {
  //   } else {
  //     if (this.state.isZoom) {
  //       const prevScaleX = img.scaleX ? img.scaleX : 0;
  //       const prevScaleY = img.scaleY ? img.scaleY : 0;
  //       const zoom = img.zoom ? img.zoom : 1;
  //       img.style.transform = `scale(${zoom}) translate(${
  //         prevScaleX + movementX
  //       }px, ${prevScaleY + movementY}px)`;
  //     }
  //   }
  // };

  disableZoom = () => {
    this.setState({ isZoom: false, zoom: 1 }, () => {
      const img = document.getElementById(
        this.props.index + "-" + this.state.current + "-carousel"
      );
      img.zoom = 1;
      img.style.transform = `scale(1) translate(${0}px, ${0}px)`;
    });
  };

  activeZoom = () => {
    if (
      this.props.medias
        .filter((media) => !media.type?.includes("audio"))
        [this.state.current]?.type?.includes("image")
    ) {
      this.setState({ isZoom: true, zoom: 2.5 }, () => {
        const img = document.getElementById(
          this.props.index + "-" + this.state.current + "-carousel"
        );
        img.zoom = 2.5;
        img.style.transform = `scale(2.5) translate(${0}px, ${0}px)`;
      });
    }
  };

  togglePauseAllVideos = () => {
    const videoElements = document.getElementsByTagName("video");
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i];
      if (this.state.isClipPaused) {
        videoElement.pause(); // Metti in pausa i video
      } else {
        videoElement.play();
      }
    }
  };

  manageAutoPlay = async () => {
    let medias = this.props.medias.filter(
      (media) => !media.type?.includes("audio")
    );
    let timer = 5000;
    // if (medias[this.state.current].type?.includes("video")) {
    //   let videoDuration = await this.getVideoDuration(
    //     medias[this.state.current].url
    //   );
    //   timer = videoDuration * 100;
    // }
    setTimeout(() => {
      this.setState(
        { current: (this.state.current ? this.state.current : 0) + 1 },
        () => {
          if (this.state.autoPlay && medias.length > this.state.current + 1) {
            this.manageAutoPlay();
          } else {
            this.setState({ autoPlay: false });
          }
        }
      );
    }, timer);
  };

  getVideoDuration = (url) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.style.display = "none";
      videoElement.onloadedmetadata = () => {
        const duration = videoElement.duration;
        videoElement.remove();
        resolve(duration);
      };

      videoElement.onerror = () => {
        videoElement.remove();
        reject("error");
      };
      videoElement.src = url;
    });
  };

  render() {
    const width = window.innerWidth;
    const isMobile = width <= 512;
    return (
      <div
        id={this.props.id ? this.props.id : "general-fancybox"}
        className={`fancybox-container ${
          this.props.show ? "" : "display-none"
        } ${
          this.props.medias[0]?.isVR ||
          this.props.medias[0]?.projectionType !== 0
            ? "disable-transform-carousel"
            : ""
        }`}
        style={{
          animationDelay: "10s",
          touchAction: "none",
          userSelect: "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            paddingTop: "8px",
            paddingLeft: "16px",
            zIndex: "99999",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            color: "white",
            fontSize: "24px",
          }}
        >
          {this.state.current + 1}
          {" / "}
          {
            this.props.medias.filter((media) => !media.type?.includes("audio"))
              .length
          }
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            paddingRight: "16px",
            paddingTop: "8px",
            paddingBottom: "16px",
            paddingLeft: "16px",
            zIndex: "99999",
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          {this.state.isZoom ? (
            <div
              className="hover"
              onClick={() => {
                this.disableZoom();
              }}
            >
              <MdOutlineZoomOut
                style={{ fontSize: "24px", color: "white" }}
              ></MdOutlineZoomOut>
            </div>
          ) : (
            <>
              {this.props.medias
                .filter((media) => !media.type?.includes("audio"))
                [this.state.current]?.type?.includes("image") && (
                <div
                  className="hover"
                  onClick={() => {
                    this.activeZoom();
                  }}
                >
                  <MdOutlineZoomIn
                    style={{ fontSize: "24px", color: "white" }}
                  ></MdOutlineZoomIn>
                </div>
              )}
            </>
          )}
          <div
            style={{ width: "24px" }}
            className="hover"
            onClick={() => {
              this.setState({ autoPlay: !this.state.autoPlay }, () => {
                if (this.state.autoPlay) {
                  this.manageAutoPlay();
                }
              });
            }}
          >
            {this.state.autoPlay ? (
              <FaPause style={{ fontSize: "18px", color: "white" }}></FaPause>
            ) : (
              <IoPlayOutline
                style={{ fontSize: "24px", color: "white" }}
              ></IoPlayOutline>
            )}
          </div>
          <div
            className="hover"
            onClick={() => {
              this.props.hide();
            }}
          >
            <BsXLg style={{ fontSize: "24px", color: "white" }}></BsXLg>
          </div>
        </div>
        <Carousel
          verticalSwipe={"natural"}
          showStatus={true}
          emulateTouch={true}
          onChange={(e) => {
            const img = document.getElementById(
              this.props.index + "-" + this.state.current + "-carousel"
            );
            if (img) {
              img.style.transform = `scale(1) translate(${0}px, ${0}px)`;
            }

            this.setState({
              current: e,
              isZoom: false,
            });
          }}
          selectedItem={
            this.state.current && this.state.autoPlay
              ? this.state.current
              : this.controlAudio(this.props.current)
          }
          showThumbs={true}
          width="100%"
          dynamicHeight={false}
          renderThumbs={() =>
            this.props.medias
              .filter((media) => !media.type?.includes("audio"))
              .map((thumbnail) => (
                <img
                  style={{ height: "88px", width: "88px", objectFit: "cover" }}
                  src={
                    thumbnail.locked
                      ? thumbnail?.blurredThumbNailUrl
                      : (thumbnail.type?.includes("audio")
                          ? AWS_URL + "logo/audio.png"
                          : "") +
                        (thumbnail.type?.includes("image")
                          ? thumbnail.url
                          : "") +
                        (thumbnail.type?.includes("video")
                          ? thumbnail.thumbNailUrl
                          : "")
                  }
                  alt=""
                ></img>
              ))
          }
          showIndicators={false}
          swipeable={this.state.isZoom === true ? false : true}
          centerMode={false}
          showArrows={true}
          className={this.props.medias?.length <= 1 ? "hideDots" : ""}
        >
          {this.props.medias?.length > 0
            ? this.props.medias
                .filter((media) => !media.type?.includes("audio"))
                .map((media, indexMedia) => (
                  <div
                    style={{
                      width: "100%",
                      height: "calc(100dvh - 128px)",
                      display: "grid",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                    key={indexMedia}
                  >
                    <div
                      style={{
                        touchAction: "manipulation",
                        maxHeight: "70dvh",
                        height: "100%",
                        width: "calc(100% - 96px)",
                        maxWidth: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        position: "relative",
                        overflow: "hidden",
                        background:
                          media.locked && media?.blurredThumbNailUrl
                            ? `url(${media?.blurredThumbNailUrl})`
                            : "transparent",
                        backgroundSize: "cover",
                      }}
                      onDoubleClick={() => {
                        if (media.type?.includes("image")) {
                          this.setState(
                            {
                              zoom: this.state.isZoom === 1 ? 2.5 : 1,
                              isZoom: !this.state.isZoom,
                            },
                            () => {
                              const img = document.getElementById(
                                this.props.index +
                                  "-" +
                                  this.state.current +
                                  "-carousel"
                              );
                              img.zoom = 2.5;
                              img.style.transform = `scale(2.5) translate(${0}px, ${0}px)`;
                            }
                          );
                        }
                      }}
                      onMouseMove={(e) => {
                        if (media.type?.includes("image")) {
                          this.handleMouseMove(e);
                        }
                      }}
                      onTouchMove={(e) => {
                        if (media.type?.includes("image")) {
                          e.preventDefault();
                          this.handleTouchMove(e);
                        }
                      }}
                      onTouchStart={(e) => {
                        if (media.type?.includes("image")) {
                          this.setState({
                            prevTouchX: e?.touches[0]?.clientX,
                            prevTouchY: e?.touches[0]?.clientY,
                            prevTouch2X: e?.touches[1]?.clientX,
                            prevTouch2Y: e?.touches[1]?.clientY,
                            isTouch: true,
                          });
                        }
                      }}
                      onTouchEnd={(e) => {
                        if (media.type?.includes("image")) {
                          if (e.touches.length < 1) {
                            this.setState({ isTouch: false });
                          }
                        }
                      }}
                      // onWheel={this.handleWheel}
                    >
                      {media.type?.includes("image") && !media.locked && (
                        <img
                          style={{
                            maxHeight: "70dvh",
                            width: "100%",
                            maxWidth: "100%",
                            transform: `scale(1) translate(0px, 0px)`,
                            transition: "none",
                            touchAction: "none",
                          }}
                          className="width-image-common-post"
                          alt=""
                          id={this.props.index + "-" + indexMedia + "-carousel"}
                          src={media.url}
                        />
                      )}
                      {media.type?.includes("video") && !media.locked && (
                        <CustomVideoPlayer
                          thumbNailUrl={media.thumbNailUrl}
                          url={media.url}
                          id={this.props.index + "-" + indexMedia}
                          isFancyBox={true}
                          media={media}
                        />
                      )}
                      {media.locked && (
                        <>
                          <div
                            style={{
                              filter: "blur(20px)",
                              width: "100%",
                              zIndex: 1,
                              position: "relative",
                            }}
                          ></div>
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              position: "absolute",
                              top: "0px",
                              left: "0px",
                              zIndex: "2",
                            }}
                          >
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                display: "grid",
                                alignItems: "center",
                                justifyItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "grid",
                                  justifyItems: "center",
                                  height: "fit-content",
                                  width: isMobile ? "90%" : "50%",
                                }}
                              >
                                <div
                                  style={{
                                    justifyContent: "center",
                                    gap: "8px",
                                    display: "grid",
                                    justifyItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      justifyContent: "center",
                                    }}
                                    className="chat-lock-box-fancy"
                                  >
                                    <BiSolidLockAlt></BiSolidLockAlt>
                                  </div>
                                  <span className="locked-content-text">
                                    {this.props.t("clip.lockedContent")}
                                  </span>
                                </div>
                                <div style={{ width: "90%" }}>
                                  {this.props.place === "postMedias" ? (
                                    <Link
                                      to={{
                                        pathname: "/payment",
                                        state: {
                                          creatorDetails: media.userDetails,
                                          postDetails: media,
                                          creatorPicture: getUserProfilePic(
                                            media.userDetails?._id,
                                            media.userDetails?.profilePicture,
                                            true,
                                            "0% 0% / cover no-repeat"
                                          ),
                                          price: media.price,
                                          typeOfPayment: 3,
                                          postId: media._id,
                                        },
                                      }}
                                    >
                                      <button className="width-button-buy-clip p-0 btn p-2 lh-24 w125 d-inline-block rounded-xl chatButtonPurchase border-red detailTitle fw-700 text-white">
                                        {this.props.t("chat.unlockMediaFor", {
                                          numberOfMedia: media.numberOfMedias,
                                          price: media?.price,
                                        })}
                                      </button>
                                    </Link>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        this.props.customPayment(indexMedia);
                                      }}
                                      className="width-button-buy-clip p-0 btn p-2 lh-24 w125 d-inline-block rounded-xl chatButtonPurchase border-red detailTitle fw-700 text-white"
                                    >
                                      {this.props.t("chat.unlockMediaFor", {
                                        numberOfMedia: media.numberOfMedias,
                                        price: media?.messagePrice,
                                      })}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {/* {media.type?.includes("audio")&& !media.locked && (
                      <CustomAudioPlayer src={media.url} />
                    )} */}
                    </div>
                  </div>
                ))
            : null}
        </Carousel>
      </div>
    );
  }
}

export default withTranslation()(CustomFancybox);
