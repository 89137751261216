import { Types } from '../../components/config/actionTypes';

function addNotificationCount(notificationCount) {
    return dispatch => {

        dispatch({
            type: Types.ADD_NOTIFICATION_COUNT,
            payload: { notificationCount: notificationCount }
        });
    };
}

function updateNotificationCount(notificationCount) {
    return dispatch => {

        dispatch({
            type: Types.UPDATE_NOTIFICATION_COUNT,
            payload: { notificationCount: notificationCount }
        });
    };
}

function callNotificationApi(callNotificationApi) {
    return dispatch => {

        dispatch({
            type: Types.CALL_NOTIFICATION_API,
            payload: { callNotificationApi: callNotificationApi }
        });
    };
}

export const notificationActions = {
    addNotificationCount,
    updateNotificationCount,
    callNotificationApi
};