import React, { Component } from "react";
import LoaderComponent from "./LoaderComponent.jsx";

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStory: this.props.stories,
    };
  }

  componentDidMount() {
    document.body.classList.add("prevent-swipedown");
  }

  componentWillUnmount() {
    document.body.classList.remove("prevent-swipedown");
  }

  render() {
    const { flag } = this.props;

    return flag === "modal" ? (
      <div className={`${this.state.isStory ? "loader" : "loader2"}`}>
        <LoaderComponent stories={this.state.isStory} />
      </div>
    ) : (
      <div className={`${this.state.isStory ? "loader" : "loader2"}`}>
        <LoaderComponent stories={this.state.isStory} />
      </div>
    );
  }
}

export default Loader;
