import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { io } from "socket.io-client";
import { SOCKET_BASEURL } from "../components/config/constants";

const defaultVal = { socket: {} }; //Insert the default value here.
export const SocketContext = React.createContext(defaultVal);

function SocketContextProvider({ children, loggedUserInfo }) {
  const socketObj = React.useMemo(
    () =>
      io(SOCKET_BASEURL, {
        transports: ["websocket"],
        auth: { userId: loggedUserInfo?.userData?._id },
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      loggedUserInfo?.userData?._id,
      loggedUserInfo?.userData?.sessionDetails?._id,
    ]
  );

  useEffect(() => {
    socketObj.on("connect", () => {
      if (
        loggedUserInfo?.userData?._id &&
        loggedUserInfo?.userData?.sessionDetails?._id
      ) {
        let data = {
          userId: loggedUserInfo?.userData?._id,
          sessionId: loggedUserInfo?.userData?.sessionDetails?._id,
          connectionId: socketObj.id,
        };
        setSocket(socketObj);
        socketObj.emit("on_connect", data);
      }
      window["socket_id"] = socketObj.id;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketObj]);

  const [socket, setSocket] = useState(socketObj);

  return (
    <SocketContext.Provider value={{ socket, setSocket }}>
      {children}
    </SocketContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  loggedUserInfo: state.authentication,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SocketContextProvider);
