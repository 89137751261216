import { Rating } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { numFormatter } from "../helper/NumberHelper";
import { useTranslation } from "react-i18next";

export const StarReviewModule = ({
  children,
  averageRating,
  creatorAverageRating,
}) => {
  return (
    <div>
      <Box sx={{ "& > legend": { mt: 2 } }}>
        <div className="gray-text-important2 d-flex justify-content-center align-items-center responsive-rating">
          <Rating
            name="simple-controlled"
            value={parseFloat(averageRating)} //Ho tolto .toFixed(1) perché dava WARNING in console
            readOnly={true}
            precision={0.1}
            size={"small"}
          />
          {creatorAverageRating?.totalRatingCount && children}
        </div>
      </Box>
    </div>
  );
};

export const ShowTotalRatings = ({ creatorAverageRating, review, reviews }) => {
  const { t } = useTranslation();
  return (
    <>
      <span className="fw-400 font-xsss text-grey-400 ms-2">
        {numFormatter(creatorAverageRating?.averageRating.toFixed(1))}{" "}
        {t("common.outOf")} 5 (
        {numFormatter(creatorAverageRating?.totalRatingCount)}
        {creatorAverageRating?.totalRatingCount === 1
          ? " " + review
          : " " + reviews}
        )
      </span>
    </>
  );
};
