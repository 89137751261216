import React, { Component } from "react";
import { BsSunFill } from "react-icons/bs";
import { BiSun } from "react-icons/bi";
import { BiMoon, BiSolidMoon } from "react-icons/bi";
import Switch from "react-switch";
import {
  switchTheme,
  clickedClass,
  theme,
} from "../components/helper/themeHelper";

class DarkModeSwitch extends Component {
  constructor() {
    super();
    this.state = {
      checked: localStorage.getItem("theme") === "theme-light" ? false : true,
      isReady: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {};

  handleChange(checked) {
    if (this.state.isReady) {
      this.setState({ checked });
      this.props.onChangeTheme();
      switchTheme();
      this.setState({ isReady: false });
      setTimeout(() => {
        this.setState({ isReady: true });
      }, 500);
    }
  }

  render() {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {this.state.checked}
        {this.state.checked ? (
          <BiSun size={16} color="#707070" />
        ) : (
          <BsSunFill size={16} color="#F2994A" />
        )}
        <label
          htmlFor="dark-switch"
          style={{ marginTop: "10px", marginBottom: "10px", height: "20px" }}
          className={`${theme === "dark" ? clickedClass : ""}`}
        >
          <Switch
            // onClick={(e) => switchTheme(e)}
            checked={this.state.checked}
            onChange={this.handleChange}
            offColor="#EEEEEE"
            onColor="#fef8f7"
            offHandleColor="#F2994A"
            onHandleColor="#ff2800"
            handleDiameter={12}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={40}
            id="dark-switch"
          />
        </label>
        {this.state.checked ? (
          <BiSolidMoon size={16} color="#ff2800" />
        ) : (
          <BiMoon size={16} color="#A3A3A3" />
        )}
      </div>
    );
  }
}

export default DarkModeSwitch;
