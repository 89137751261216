import React, { Component, Fragment } from "react";
import { Button, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { AWS_URL } from "../../../components/config/constants.js";
import Loader from "../../../components/Loader";
import { ImPlay3 } from "react-icons/im";
import { IoCheckmarkSharp } from "react-icons/io5";
import CollectionService from "../../../services/CollectionService.js";
import EmptyPlaceHolderCollection from "../../../components/common/EmptyPlaceHolderCollection.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomAudioPlayer from "../../../components/common/CustomAudioPlayer.jsx";
import CustomFancybox from "../../../components/common/CustomFancyBox.jsx";
import moment from "moment";

class CollectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMediaType: this.props.selectedMediaType
        ? this.props.selectedMediaType
        : "All",
      collectionList: [],
      totalCollections: 0,
      allMediaCollection: { mediasList: [] },
      allMedia: { mediasList: [] },
      mediaIds: [],
      showListOfCollection: true,
      showCollectionDetails: false,
      collectionId: null,
      loading: false,
      collectionsPage: 0,
      totalMedias: 0,
      pageOfMedias: 0,
      limit: 18,
      showFancyBox: false,
      current: 0,
      hideAllMedia: this.props.hideAllMedia ? this.props.hideAllMedia : false,
      hideImages: this.props.hideImages ? this.props.hideImages : false,
      hideVideos: this.props.hideVideos ? this.props.hideVideos : false,
      hideAudios: this.props.hideAudios ? this.props.hideAudios : false,
      hideVr: this.props.hideVr ? this.props.hideVr : false,
    };
  }

  componentDidMount = () => {
    this.getUserColletion();
    this.getAllCollection();
  };

  updateMediaIds = (media) => {
    let newMedia = {
      _id: media._id,
      url: media.url,
      dataURL: media.url,
      name: media.mediaName,
      isVaultMedia: true,
      isEdit: true,
      ...(media.isVR && { isVR: true, projectionType: media.projectionType }),
      typeOfMedia: media.mediaType,
      type: media.mediaType,
      blurredThumbNailImage: media.mediaBlurredThumbNailName,
      thumbNailImage: media.mediaThumbNailName,
      uploadedMediaDataUrl: media.url,
      uploadedMedia: {
        isEdit: true,
        name: media.mediaName,
        type: media.mediaType,
        uploadedMediaDataUrl: media.url,
        url: media.url,
      },
    };
    const indexElement = this.state.mediaIds.findIndex(
      (element) => element._id === newMedia._id
    );
    let mediaIds = this.state.mediaIds;
    if (this.state.selectedMediaType === "vr") {
      if (indexElement !== -1) {
        this.setState({ mediaIds: [] }, () => {});
      } else {
        this.setState({ mediaIds: [newMedia] }, () => {});
      }
    } else {
      if (indexElement !== -1) {
        mediaIds.splice(indexElement, 1);
        this.setState({ mediaIds }, () => {});
      } else {
        mediaIds.push(newMedia);
        this.setState({ mediaIds }, () => {});
      }
    }
  };

  getAllCollection = () => {
    this.setState({ loading: true });
    let params = {
      limit: 4,
      page: 0,
      mediaType: this.state.selectedMediaType,
    };

    CollectionService.getAllCollection({ ...params })
      .then((res) => {
        this.setState({ allMediaCollection: res.data.data });
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getCollectionDetails = () => {
    this.setState({ loading: true });
    let params = {
      limit: this.state.limit,
      page: this.state.page,
      collectionId: this.state.collectionId,
      mediaType: this.state.selectedMediaType,
    };

    CollectionService.getCollectionDetails({ ...params })
      .then((res) => {
        let allMedia = res.data.data;
        allMedia.mediasList = [
          ...this.state.allMedia.mediasList,
          ...res.data.data.mediasList,
        ];
        this.setState({ allMedia: allMedia });
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getAllMedias = () => {
    this.setState({ loading: true });
    let params = {
      limit: this.state.limit,
      page: this.state.page,
      mediaType: this.state.selectedMediaType,
    };

    CollectionService.getAllCollection({ ...params })
      .then((res) => {
        let allMedia = res.data.data;
        allMedia.mediasList = [
          ...this.state.allMedia.mediasList,
          ...res.data.data.mediasList,
        ];
        this.setState({ allMedia: allMedia });
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getUserColletion = () => {
    this.setState({ loading: true });
    let params = {
      page: this.state.collectionsPage,
    };

    CollectionService.listUserCollection({ ...params })
      .then((res) => {
        this.setState({
          collectionList: [
            ...this.state.collectionList,
            ...res.data.data.collectionsList,
          ],
          totalCollections: res.data.data.total,
        });
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const width = window.innerWidth;
    const isMobile = width <= 512;

    return (
      <>
        {this.state.loading && <Loader />}
        <Modal
          show={this.props.showCollectionModal}
          onHide={() => {
            this.props.handleCloseModal();
            this.setState({
              showCollectionDetails: false,
              showListOfCollection: true,
              allMedia: { mediasList: [] },
            });
          }}
          keyboard={false}
          centered
          className="vaultModel"
        >
          <Modal.Header className="text-black " closeButton>
            <span className={`font-xss`}>
              {this.props.t("collection.selectMediaFromCollection")}
            </span>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "70vh" }} className="text-black">
            <CustomFancybox
              medias={this.state.allMedia.mediasList.map((media) => {
                let newMedia = media;
                media.type = media.mediaType;
                media.thumbNailUrl = media.thumbnailUrl;
                return newMedia;
              })}
              show={this.state.showFancyBox}
              current={this.state.current}
              t={this.props.t}
              hide={() => {
                this.setState({ showFancyBox: false });
              }}
              place={"postMedias"}
            />
            <div
              id="scrollCollectionsModal"
              style={{ height: "calc(70vh - 2rem)", overflowY: "scroll" }}
            >
              <InfiniteScroll
                dataLength={
                  this.state.showCollectionDetails === true
                    ? this.state.allMedia.mediasList.length
                    : this.state.collectionList?.length
                }
                next={() => {
                  if (this.state.showCollectionDetails === true) {
                    this.setState({ page: this.state.page + 1 }, () => {
                      if (this.state.collectionId) {
                        this.getCollectionDetails();
                      } else {
                        this.getAllMedias();
                      }
                    });
                  } else {
                    this.setState(
                      { collectionsPage: this.state.collectionsPage + 1 },
                      () => {
                        this.getUserColletion();
                      }
                    );
                  }
                }}
                hasMore={
                  this.state.showCollectionDetails === true
                    ? this.state.allMedia?.mediasList?.length <
                      this.state.allMedia.allMediasCount
                    : this.state.collectionList?.length <
                      this.state.totalCollections
                    ? true
                    : false
                }
                scrollableTarget="scrollCollectionsModal"
                className="right-scroll-bar"
              >
                {this.state.showCollectionDetails === true && (
                  <div className="mb-3 newVault">
                    <div
                      className={` mb-2 fw-600 font-xsss vaultText text-black`}
                    >
                      {this.props.t("collection.selectMediaType")}
                    </div>
                    <div className="p-2 vaultTab">
                      {!this.state.hideAllMedia && (
                        <span
                          className={`badge badge-pill pointer vaultText  ${
                            this.state.selectedMediaType === "All"
                              ? "bg-blue text-white"
                              : " bg-gray text-black"
                          }  fw-600 me-3 font-xsss`}
                          onClick={() =>
                            this.setState(
                              {
                                ...(this.state.selectedMediaType === "vr" && {
                                  mediaIds: [],
                                }),
                                selectedMediaType: "All",
                                allMedia: { mediasList: [] },
                              },
                              () => {
                                if (this.state.collectionId) {
                                  this.getCollectionDetails();
                                } else {
                                  this.getAllMedias();
                                }
                              }
                            )
                          }
                        >
                          {this.props.t("common.allMedia")}
                        </span>
                      )}
                      {!this.state.hideImages && (
                        <span
                          className={`badge badge-pill pointer vaultText ${
                            this.state.selectedMediaType === "image"
                              ? "bg-blue text-white"
                              : " bg-gray text-black"
                          } text-black fw-600 me-3 font-xsss`}
                          onClick={() =>
                            this.setState(
                              {
                                ...(this.state.selectedMediaType === "vr" && {
                                  mediaIds: [],
                                }),
                                selectedMediaType: "image",
                                allMedia: { mediasList: [] },
                              },
                              () => {
                                if (this.state.collectionId) {
                                  this.getCollectionDetails();
                                } else {
                                  this.getAllMedias();
                                }
                              }
                            )
                          }
                        >
                          {this.props.t("common.images")}
                        </span>
                      )}
                      {!this.state.hideVideos && (
                        <span
                          className={`badge badge-pill pointer vaultText ${
                            this.state.selectedMediaType === "video"
                              ? "bg-blue text-white"
                              : " bg-gray text-black"
                          } text-black fw-600 me-3 font-xsss`}
                          onClick={() =>
                            this.setState(
                              {
                                ...(this.state.selectedMediaType === "vr" && {
                                  mediaIds: [],
                                }),
                                selectedMediaType: "video",
                                allMedia: { mediasList: [] },
                              },
                              () => {
                                if (this.state.collectionId) {
                                  this.getCollectionDetails();
                                } else {
                                  this.getAllMedias();
                                }
                              }
                            )
                          }
                        >
                          {this.props.t("bookmark.videos")}
                        </span>
                      )}
                      {!this.state.hideAudios && (
                        <span
                          className={`badge badge-pill pointer vaultText ${
                            this.state.selectedMediaType === "audio"
                              ? "bg-blue text-white"
                              : " bg-gray text-black"
                          } text-black fw-600 me-3 font-xsss`}
                          onClick={() =>
                            this.setState(
                              {
                                ...(this.state.selectedMediaType === "vr" && {
                                  mediaIds: [],
                                }),
                                selectedMediaType: "audio",
                                allMedia: { mediasList: [] },
                              },
                              () => {
                                if (this.state.collectionId) {
                                  this.getCollectionDetails();
                                } else {
                                  this.getAllMedias();
                                }
                              }
                            )
                          }
                        >
                          {this.props.t("bookmark.audios")}
                        </span>
                      )}
                      {!this.state.hideVr && (
                        <span
                          className={`badge badge-pill pointer vaultText ${
                            this.state.selectedMediaType === "vr"
                              ? "bg-blue text-white"
                              : " bg-gray text-black"
                          } text-black fw-600 me-3 font-xsss`}
                          onClick={() =>
                            this.setState(
                              {
                                selectedMediaType: "vr",
                                allMedia: { mediasList: [] },
                              },
                              () => {
                                if (this.state.collectionId) {
                                  this.getCollectionDetails();
                                } else {
                                  this.getAllMedias();
                                }
                              }
                            )
                          }
                        >
                          {this.props.t("home.vr")}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {this.state.showCollectionDetails && (
                  <div className="container-media-parent">
                    {this.state.allMedia.mediasList.map((media, index) => (
                      <span
                        onClick={() => {
                          if (!media.type?.includes("audio")) {
                            this.setState({
                              showFancyBox: true,
                              current: index,
                            });
                          }
                        }}
                        className="container-media-collection-sub2 hover"
                      >
                        <div className="media-collection-date">
                          <p>
                            {moment(media.createdAt)
                              .locale(localStorage.getItem("i18nextLng"))
                              .format("MMM D, YYYY")}
                          </p>
                        </div>
                        {this.state.mediaIds.findIndex(
                          (element) => element._id === media._id
                        ) !== -1 ? (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              this.updateMediaIds(media);
                            }}
                            className="select-media-collection-selected"
                          >
                            <span
                              style={{ bottom: "3px", position: "relative" }}
                            >
                              <IoCheckmarkSharp
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  position: "relative",
                                  top: "-6px",
                                  left: "4px",
                                }}
                              ></IoCheckmarkSharp>
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              this.updateMediaIds(media);
                            }}
                            className="select-media-collection"
                          ></div>
                        )}
                        {media.mediaType?.includes("image") && (
                          <img
                            className="image-colletion"
                            alt=""
                            loading="lazy"
                            src={`${media.url}`}
                          ></img>
                        )}
                        {media.mediaType?.includes("audio") && (
                          <div>
                            <img
                              className="image-colletion icon-fix-collection"
                              src={AWS_URL + "logo/audio.png"}
                              alt=""
                              loading="lazy"
                            ></img>
                            <div className="audio-text-collection">
                              <div>{media.mediaName.slice(13, 33)}</div>

                              <div
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ maxHeight: "35px" }}>
                                  <CustomAudioPlayer
                                    wid={"0px"}
                                    CustomStyle={{
                                      maxHeight: "35px",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "8px",
                                      width: "100%",
                                    }}
                                    src={media.url}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {media.mediaType?.includes("video") && (
                          <>
                            <img
                              className="image-colletion"
                              alt=""
                              loading="lazy"
                              src={`${media.thumbnailUrl}`}
                            ></img>
                            {(this.state[index] === undefined ||
                              this.state[index] ||
                              this.state[index] === null) && (
                              <>
                                <div className="containter-button-mobile-pause-profile">
                                  <div className="pause-button-clip">
                                    <div className="pause-internal-button-clip">
                                      <ImPlay3
                                        style={{
                                          width: "36px",
                                          height: "36px",
                                          color: "#FFFFFF",
                                          paddingLeft: "4px",
                                        }}
                                      ></ImPlay3>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </span>
                    ))}
                  </div>
                )}

                {this.state.showListOfCollection === true && (
                  <div className="vault-page-container row px-4">
                    <div className="col-lg-4" style={{ marginBottom: "24px" }}>
                      <div
                        onClick={() => {
                          this.setState(
                            {
                              showListOfCollection: false,
                              showCollectionDetails: true,
                            },
                            () => {
                              this.getAllMedias();
                            }
                          );
                        }}
                        className="image-card hover"
                      >
                        <div>
                          <div className="image-collection">
                            {this.state.allMediaCollection.mediasList.map(
                              (media, index) => (
                                <>
                                  {media.mediaType.includes("image") && (
                                    <div className="div-image-colletion">
                                      <img
                                        className="image-colletion"
                                        src={media.url}
                                        alt=""
                                        loading="lazy"
                                      ></img>
                                    </div>
                                  )}
                                  {media.mediaType.includes("video") && (
                                    <div className="div-image-colletion">
                                      <img
                                        className="image-colletion"
                                        src={media.thumbnailUrl}
                                        alt=""
                                        loading="lazy"
                                      ></img>
                                    </div>
                                  )}
                                  {media.mediaType.includes("audio") && (
                                    <div className="div-image-colletion">
                                      <img
                                        className="image-colletion"
                                        src={AWS_URL + "logo/audio.png"}
                                        alt=""
                                        loading="lazy"
                                      ></img>
                                    </div>
                                  )}
                                </>
                              )
                            )}
                            {this.state.allMediaCollection.mediasList.length <
                              4 && (
                              <EmptyPlaceHolderCollection></EmptyPlaceHolderCollection>
                            )}
                            {this.state.allMediaCollection.mediasList.length <
                              3 && (
                              <EmptyPlaceHolderCollection></EmptyPlaceHolderCollection>
                            )}
                            {this.state.allMediaCollection.mediasList.length <
                              2 && (
                              <EmptyPlaceHolderCollection></EmptyPlaceHolderCollection>
                            )}
                            {this.state.allMediaCollection.mediasList.length <
                              1 && (
                              <EmptyPlaceHolderCollection></EmptyPlaceHolderCollection>
                            )}
                          </div>
                          <div className="collection-name py-3 text-color-dark bgc-white fw-bold">
                            {this.props.t("collection.all")}{" "}
                            {this.state?.allMediaCollection?.mediasList
                              ?.length < 1 ? (
                              <small className="collection-name-empty">
                                ({this.props.t("common.empty")})
                              </small>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.collectionList.map((collection, index) => (
                      <div
                        className="col-lg-4"
                        style={{ marginBottom: "24px" }}
                      >
                        <div
                          onClick={() => {
                            this.setState(
                              {
                                showListOfCollection: false,
                                showCollectionDetails: true,
                                collectionId: collection._id,
                              },
                              () => {
                                this.getCollectionDetails();
                              }
                            );
                          }}
                          className="image-card hover"
                        >
                          <div
                            to={{
                              pathname: `/collection-details`,
                              state: {
                                id: collection._id,
                                name: collection.name,
                              },
                            }}
                          >
                            <div className="image-collection">
                              {collection.thumbnails.map((media, index) => (
                                <>
                                  {media?.mediaType.includes("image") && (
                                    <div className="div-image-colletion">
                                      <img
                                        className="image-colletion"
                                        src={media.url}
                                        alt=""
                                        loading="lazy"
                                      ></img>
                                    </div>
                                  )}
                                  {media?.mediaType.includes("video") && (
                                    <div className="div-image-colletion">
                                      <img
                                        className="image-colletion"
                                        src={media.url}
                                        alt=""
                                        loading="lazy"
                                      ></img>
                                    </div>
                                  )}
                                  {media?.mediaType.includes("audio") && (
                                    <div className="div-image-colletion">
                                      <img
                                        className="image-colletion"
                                        src={AWS_URL + "logo/audio.png"}
                                        alt=""
                                        loading="lazy"
                                      ></img>
                                    </div>
                                  )}
                                </>
                              ))}
                              {collection.thumbnails.length < 4 && (
                                <EmptyPlaceHolderCollection></EmptyPlaceHolderCollection>
                              )}
                              {collection.thumbnails.length < 3 && (
                                <EmptyPlaceHolderCollection></EmptyPlaceHolderCollection>
                              )}
                              {collection.thumbnails.length < 2 && (
                                <EmptyPlaceHolderCollection></EmptyPlaceHolderCollection>
                              )}
                              {collection.thumbnails.length < 1 && (
                                <EmptyPlaceHolderCollection></EmptyPlaceHolderCollection>
                              )}
                            </div>
                            <div className="collection-name py-3 text-color-dark bgc-white fw-bold">
                              {collection.name}{" "}
                              {collection.thumbnails.length < 1 ? (
                                <small className="collection-name-empty">
                                  ({this.props.t("common.empty")})
                                </small>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}{" "}
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end">
            <Button
              variant="link"
              className={`text-decoration-none pointer fw-600 ${
                isMobile ? " font-xsss" : " font-xsss"
              } add-new-category text-silver`}
              onClick={() => {
                this.props.handleCloseModal();
                this.setState({
                  showCollectionDetails: false,
                  showListOfCollection: true,
                  allMedia: { mediasList: [] },
                });
              }}
            >
              {this.props.t("common.cancel")}
            </Button>
            <Button
              variant="link"
              className={`text-decoration-none pointer fw-600 ${
                isMobile ? " font-xsss" : " font-xsss"
              } add-new-category ${
                !(this.state.mediaIds.length > 0) && " text-muted"
              }`}
              onClick={() => {
                this.props.handleCloseModal();
                this.props.handleAddMedia(this.state.mediaIds);
                this.setState({
                  showCollectionDetails: false,
                  showListOfCollection: true,
                  mediaIds: [],
                });
              }}
              disabled={!(this.state.mediaIds.length > 0)}
              style={{ color: "#4ECC6A" }}
            >
              {this.props.t("collection.simpleAdd")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(CollectionModal);
