import axios from "axios";
import { API_BASEURL, headers } from "../components/config/constants";

const RequestService = {
    get: function (path, heads=headers) {
        return axios
        .get(API_BASEURL + path, { headers: heads })
        .then((response) => response)
        .catch((error) => {
            throw error.response.data;
        });
    },
    post: function (path, params, heads=headers) {
        return axios
        .post(API_BASEURL + path, params, { headers: heads })
        .then((response) => response)
        .catch((error) => {
            throw error.response.data;
        });
    },
    put: function (path, params, heads=headers) {
        return axios
        .put(API_BASEURL + path, params, { headers: heads })
        .then((response) => response)
        .catch((error) => {
            throw error.response.data;
        });
    },
    delete: function (path, heads=headers) {
        return axios
        .delete(API_BASEURL + path, { headers: heads })
        .then((response) => response)
        .catch((error) => {
            throw error.response.data;
        });
    },
    putFile: function (url, params, heads=headers) {
        return axios
        .put(url, params, { headers: heads })
        .then((response) => response)
        .catch((error) => {
            throw error.response.data;
        });
    },
}

export default RequestService;