import RequestService from "./RequestService";

const CollectionService = {
    listUserCollection: function (params) {
        return RequestService.post(`/collection/list`, params);
    },
    addCollection: function (params) {
        return RequestService.post(`/collection/add`, params);
    },
    getCollectionDetails: function (params) {
        return RequestService.post(`/collection/get`, params);
    },
    addMediaToCollection: function (params) {
        return RequestService.post(`/collection/addMedias`, params);
    },
    deleteMediaFromCollection: function (params) {
        return RequestService.post(`/collection/removeMedias`, params);
    },
    deleteMediaFromAllMedia: function (params) {
        return RequestService.post(`/collection/remove-from-all`, params);
    },
    deleteCollection: function (params) {
        return RequestService.post(`/collection/remove`, params);
    },
    getAllCollection: function (params) {
        return RequestService.post(`/collection/allMedias`, params);
    },
}

export default CollectionService;