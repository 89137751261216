import React, { Component, Fragment } from "react";
import { FaPause } from "react-icons/fa";
import { IoMdPlay } from "react-icons/io";
import WaveSurfer from "wavesurfer.js";

class CustomAudioPlayer extends Component {
  constructor(props) {
    super(props);

    this.waveformRef = React.createRef();
    this.state = {
      isPlaying: false,
      duration: 0,
      loading: false,
      loadingPercentage: 0,
      progressTime: "0:00",
    };

    this.wavesurfer = null;
  }

  componentDidMount() {
    // Inizializza Wavesurfer.js quando il componente è montato
    this.wavesurfer = WaveSurfer.create({
      container: this.waveformRef.current,
      waveColor: "#A3A3A3",
      progressColor: "#A3A3A3",
      cursorColor: "#ff2800",
      barWidth: 2,
      barGap: 1,
      cursorWidth: 1,
      mediaControls: false,
      autoplay: false,
      width: this.props.wid ? this.props.wid : undefined,
    });

    this.setState({ loading: true });
    // Carica il file audio specifico per questa istanza di Wavesurfer
    this.wavesurfer.load(this.props.src);

    // Ottieni e imposta la durata del file audio
    this.wavesurfer.on("ready", () => {
      const audioDuration = this.wavesurfer.getDuration();
      this.setState({ duration: audioDuration, loading: false });
    });
    // Ottieni e imposta la durata del file audio
    this.wavesurfer.on("loading", (result) => {
      this.setState({ loadingPercentage: result });
    });

    this.wavesurfer.on("timeupdate", (result) => {
      this.setState({
        progressTime:
          Math.floor((result % 3600) / 60) +
          ":" +
          ("00" + Math.floor(result % 60)).slice(-2),
      });
    });
  }

  componentWillUnmount() {
    // Cleanup alla dismissione del componente
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
  }

  togglePlay = () => {
    if (this.wavesurfer) {
      if (this.state.isPlaying) {
        this.wavesurfer.pause();
      } else {
        this.wavesurfer.play();
      }
      this.setState((prevState) => ({ isPlaying: !prevState.isPlaying }));
    }
  };

  render() {
    return (
      <div
        style={
          this.props.CustomStyle
            ? this.props.CustomStyle
            : {
                display: "flex",
                alignItems: "center",
                gap: "8px",
                width: "100%",
              }
        }
      >
        {this.state.loading ? (
          <p>{this.state.loadingPercentage + "%"}</p>
        ) : (
          <Fragment>
            <div style={{ display: "flex" }} className="hover">
              {this.state.isPlaying ? (
                <FaPause onClick={this.togglePlay}></FaPause>
              ) : (
                <IoMdPlay onClick={this.togglePlay}></IoMdPlay>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                {this.state.duration
                  ? Math.floor((this.state.duration % 3600) / 60) +
                    ":" +
                    ("00" + Math.floor(this.state.duration % 60)).slice(-2)
                  : ""}
              </span>
              <span>{this.state.progressTime}</span>
            </div>
          </Fragment>
        )}
        <div style={{ width: "100%" }} ref={this.waveformRef}></div>
      </div>
    );
  }
}

export default CustomAudioPlayer;
