import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AWS_URL } from "../components/config/constants";
const width = window.innerWidth;
var isMobile = width <= 512;

class GuestLayout extends Component {
  render() {
    return (
      <div className="main-wrap" onContextMenu={(e) => e.preventDefault()}>
        <div className="row" style={{ minHeight: "100vh" }}>
          <div
            className="col-xl-6 d-none d-xl-block p-0 bg-image-cover bg-no-repeat"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${AWS_URL}logo/login-image.webp)`,
            }}
          />
          <div
            className="col-xl-6 align-items-center d-flex  rounded-3 overflow-hidden bgc-lighter-black-new"
            style={{ background: "#FAFAFA" }}
          >
            <div
              className={`${
                isMobile ? "card" : "card border-r16px radius-16"
              }  shadow-none border-0  ms-auto me-auto login-card overflow-scroll-mobile h-auto`}
            >
              <div className="card-body rounded-0 text-left">
                <Link
                  to="/"
                  style={{
                    paddingLeft: isMobile ? "0px" : "0px",
                    gap: "7.24px",
                  }}
                >
                  <div style={{ width: "100%", textAlign: "-webkit-center" }}>
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "100%" }}
                    >
                      <img
                        className="logo-red-circle-login"
                        src={`${AWS_URL}logo/logoRedCircle.png`}
                        alt=""
                      ></img>
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "100%" }}
                    >
                      <img
                        className="logo-red-circle-login"
                        src={`${AWS_URL}logo/${
                          localStorage.getItem("theme") === "theme-light"
                            ? "RedCircleBlack.png"
                            : "RedCircleRed.png"
                        }`}
                        alt=""
                      ></img>
                    </div>
                  </div>
                </Link>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GuestLayout;
