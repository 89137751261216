import { Types } from '../../components/config/actionTypes'

var initialState = {
    notificationCount: 0
}
// const initialState = notificationCount ? { notificationCount: notificationCount } : state;

var notificationApiCallInitialState = {
    callNotificationApi: null
}
// const notificationApiCallInitialState = callNotificationApi ? { callNotificationApi: callNotificationApi } : notificationApiCallState;

export function notificationCountInfo(state = initialState, action) {

    switch (action.type) {

        case Types.ADD_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: action.payload.notificationCount
            };

        case Types.UPDATE_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: action.payload.notificationCount,
            }

        default:
            return state
    }
}


export function notificationApiCallInfo(state = notificationApiCallInitialState, action) {

    switch (action.type) {

        case Types.CALL_NOTIFICATION_API:
            return {
                ...state,
                callNotificationApi: action.payload.callNotificationApi
            };

        default:
            return state
    }
}