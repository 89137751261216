import moment from "moment";
import {
  FILE_PREFIX_CODE,
  POST_SECTION_VALUES,
  AWS_URL,
} from "../config/constants";
import DefaultAvatar from "../common/DefaultAvatar";
import SimpleReactValidator from "simple-react-validator";
import {
  AMAZON_REGEX,
  INSTAGRAM_REGEX,
  REDDIT_REGEX,
  SNAPCHAT_REGEX,
  SPOTIFY_REGEX,
  TELEGRAM_REGEX,
  TIKTOK_REGEX,
  TWITCH_REGEX,
  TWITTER_REGEX,
  USERNAME_REGEX,
  YOUTUBE_REGEX,
} from "../config/regex";
import { toast } from "react-toastify";

function generateRandomFileName(prefix) {
  var timestamp = Date.now() + Math.floor(Math.random() * 100);
  if (prefix) {
    return FILE_PREFIX_CODE + "_" + prefix + "_" + timestamp;
  } else {
    return FILE_PREFIX_CODE + "_" + timestamp;
  }
}

function getFileExtension(file) {
  return file.type.split("/")[1].toLowerCase();
}

function formatTimeToSec(date) {
  //convert utc to local
  let yourDate = new Date(date);

  //format time as per figma
  const formattedTime = yourDate.toLocaleTimeString("en-US", {
    second: "numeric",
  });

  return formattedTime;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getReactionAndCommentCount(
  postEmojiLikesCount,
  valuePostEmojiCount,
  isInstantReaction,
  slug
) {
  const width = window.innerWidth;
  var isMobile = width <= 512;

  let TEXT = POST_SECTION_VALUES[slug];

  const currentCount = isInstantReaction
    ? postEmojiLikesCount
    : valuePostEmojiCount;
  if (currentCount === 0) {
    // return null;
    return `${currentCount} ${isMobile ? "" : TEXT.single}`;
  } else if (currentCount === 1) {
    return `${currentCount} ${isMobile ? "" : TEXT.single}`;
  } else if (currentCount > 1) {
    return `${currentCount} ${isMobile ? "" : TEXT.multiple}`;
  } else {
    return null;
  }
}

function calculateMinTime(date) {
  let isToday = moment(date).isSame(moment(), "day");
  if (isToday) {
    let nowAdd15Min = moment(moment().add(15, "minutes"));
    return nowAdd15Min;
  }
  return moment(date).startOf("day");
}

function calculateRate(a, b) {
  let result = a / b;
  return Number.isNaN(result)
    ? 0
    : Math.round((result * 100 + Number.EPSILON) * 100) / 100;
}

const timeClipComment = (inputDate, propsT, extendedWeek) => {
  const currentDate = new Date();
  const inputDateObj = new Date(inputDate);

  const timeDifferenceInMilliseconds = currentDate - inputDateObj;
  const secondsDifference = Math.floor(timeDifferenceInMilliseconds / 1000);

  if (secondsDifference < 1) {
    return propsT("clip.now");
  } else if (secondsDifference < 60) {
    return `${
      secondsDifference !== 1
        ? propsT("clip.secondsAgo", { secondsDifference })
        : propsT("clip.secondAgo")
    }`;
  } else {
    const minutesDifference = Math.floor(secondsDifference / 60);
    if (minutesDifference < 60) {
      return `${
        minutesDifference !== 1
          ? propsT("clip.minutesAgo", { minutesDifference })
          : propsT("clip.minuteAgo")
      }`;
    } else {
      const hoursDifference = Math.floor(minutesDifference / 60);
      if (hoursDifference < 24) {
        return `${
          hoursDifference !== 1
            ? propsT("clip.hoursAgo", { hoursDifference })
            : propsT("clip.hourAgo")
        }`;
      } else {
        const daysDifference = Math.floor(hoursDifference / 24);
        const extended = extendedWeek ? 14 : 7;
        if (daysDifference < extended) {
          return `${
            daysDifference !== 1
              ? propsT("clip.daysAgo", { daysDifference })
              : propsT("clip.dayAgo")
          }`;
        } else {
          const weeksDifference = Math.floor(daysDifference / 7);
          if (weeksDifference < 4) {
            // average days in a month
            return `${
              weeksDifference !== 1
                ? propsT("clip.weeksAgo", { weeksDifference })
                : propsT("clip.weekAgo")
            }`;
          } else {
            const monthsDifference = Math.floor(weeksDifference / 4);
            if (monthsDifference < 12) {
              return `${
                monthsDifference !== 1
                  ? propsT("clip.monthsAgo", { monthsDifference })
                  : propsT("clip.monthAgo")
              }`;
            } else {
              const yearsDifference = Math.floor(monthsDifference / 12);
              return `${
                yearsDifference !== 1
                  ? propsT("clip.yearsAgo", { yearsDifference })
                  : propsT("clip.yearAgo")
              }`;
            }
          }
        }
      }
    }
  }
};

const stringAvatar = (name) => {
  if (name) {
    name = name.toUpperCase();
    return {
      children: `${name.split(" ")[0][0]}${
        name.split(" ")[1] ? name.split(" ")[1][0] : ""
      }`,
    };
  } else {
    return {
      children: null,
    };
  }
};

const isNotValid = (value) => {
  return value === undefined || value === null;
};

const getCoverPic = (userId, coverImage, addUrlCssTag) => {
  let urlCoverPic = AWS_URL + "users/" + userId + "/cover_image/" + coverImage;

  if (addUrlCssTag) {
    urlCoverPic = "url(" + urlCoverPic + ")";
  }

  return urlCoverPic;
};

const getUserProfilePic = (
  userId,
  pictureName,
  addUrlCssTag,
  extraParameters
) => {
  let urlProfilePic =
    AWS_URL + "users/" + userId + "/profile_pic/" + pictureName;

  if (addUrlCssTag) {
    urlProfilePic = "url(" + urlProfilePic + ")";
  }

  if (extraParameters) {
    urlProfilePic = urlProfilePic + " " + extraParameters;
  }
  if (userId) {
    return urlProfilePic;
  } else {
    return null;
  }
};

const ProfilePicture = (props) => {
  var imageURL = props.profilePicture
    ? getUserProfilePic(props.id, props.profilePicture)
    : null;
  return (
    <DefaultAvatar id={props.id} userName={props.name}>
      {props.profilePicture ? (
        <img
          alt=""
          style={{
            height: "36px",
            width: "36px",
            borderRadius: "99px",
            background: "white",
          }}
          src={imageURL}
        ></img>
      ) : null}
    </DefaultAvatar>
  );
};

const charCount = (text, maxLength) => {
  return (text ? text.length : 0) + "/" + maxLength;
};

const hasWhiteSpace = (s) => {
  return /\s/g.test(s);
};

const truncateLongNameUserName = (text) => {
  if (!hasWhiteSpace(text)) {
    var truncated = text.substring(0, 22) + "...";
    return text.length > 22 ? truncated : text;
  } else {
    return text;
  }
};

const AccountValidator = (object, t) => {
  return new SimpleReactValidator({
    className: "text-danger",
    autoForceUpdate: object,
    validators: {
      instagram_validate: {
        message: t("account.form.instagram.validator"),
        rule: function (val, options) {
          var regexp = INSTAGRAM_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
      twitter_validate: {
        message: t("account.form.twitter.validator"),
        rule: function (val, options) {
          var regexp = TWITTER_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
      snapchat_validate: {
        message: t("account.form.snapchat.validator"),
        rule: function (val, options) {
          var regexp = SNAPCHAT_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
      reddit_validate: {
        message: t("account.form.reddit.validator"),
        rule: function (val, options) {
          var regexp = REDDIT_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
      telegram_validate: {
        message: t("account.form.telegram.validator"),
        rule: function (val, options) {
          var regexp = TELEGRAM_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
      twitch_validate: {
        message: t("account.form.twitch.validator"),
        rule: function (val, options) {
          var regexp = TWITCH_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
      youtube_validate: {
        message: t("account.form.youtube.validator"),
        rule: function (val, options) {
          var regexp = YOUTUBE_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
      amazon_validate: {
        message: t("account.form.amazon.validator"),
        rule: function (val, options) {
          var regexp = AMAZON_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
      tiktok_validate: {
        message: t("account.form.tiktok.validator"),
        rule: function (val, options) {
          var regexp = TIKTOK_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
      spotify_validate: {
        message: t("account.form.spotify.validator"),
        rule: function (val, options) {
          var regexp = SPOTIFY_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
      username_validate: {
        message: t("account.form.username.validator"),
        rule: function (val, options) {
          var regexp = USERNAME_REGEX;
          if (val.match(regexp)) {
            return true;
          } else {
            return false;
          }
        },
      },
    },
  });
};

const handleShareProfile = (username) => {
  let shareData = {
    title: "RedCircle",
    text: "The most rewarding interaction ever.",
    url: process.env.REACT_APP_BASEURL + "" + username,
  };
  navigator.share(shareData);
};

const copyAction = (userName, postId, t) => {
  let url = process.env.REACT_APP_BASEURL + "post-detail?id=" + postId;
  navigator.clipboard.writeText(url);
  toast.success(t("creatorProfile.linkCopied"));
};

export {
  generateRandomFileName,
  getFileExtension,
  formatTimeToSec,
  capitalizeFirstLetter,
  getReactionAndCommentCount,
  calculateMinTime,
  calculateRate,
  timeClipComment,
  stringAvatar,
  isNotValid,
  getUserProfilePic,
  ProfilePicture,
  getCoverPic,
  charCount,
  truncateLongNameUserName,
  hasWhiteSpace,
  AccountValidator,
  handleShareProfile,
  copyAction,
};
