import { Typography } from "@mui/material";
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class Disclaimer extends Component {
  render() {
    return (
      <div className="p-4" style={{ minHeight: "250px" }}>
        <div className="d-flex flex-column fw-400 pt-1">
          <Typography className="font-xssss">
            ©RedCircle {new Date().getFullYear()}, All rights reserved.{" "}
          </Typography>
          <Typography className="font-xssss">
            Disclaimer: This website may contain mature content. All members and
            persons appearing on this site have contractually represented to us
            that they are <b>18 years of age or older</b>.
          </Typography>
        </div>
        <div className="fw-400 font-xsss pt-1">
          <Row>
            <Col>
              <Row>
                <Link to="/terms-conditions" className="text-grey-500">
                  <small>Terms and Conditions</small>
                </Link>
              </Row>
              <Row>
                <Link to="/privacy-policy" className="text-grey-500">
                  <small>Privacy Policy</small>
                </Link>
              </Row>
              <Row>
                <Link to="/cookie-policy" className="text-grey-500">
                  <small>Cookie Policy</small>
                </Link>
              </Row>
              <Row>
                <Link to="/aml-policy" className="text-grey-500">
                  <small>AML Policy</small>
                </Link>
              </Row>
            </Col>
            <Col>
              <Row>
                <Link to="/terms-user-creator" className="text-grey-500">
                  <small>Contract between Fan and Creator</small>
                </Link>
              </Row>
              <Row>
                <Link to="/terms-user" className="text-grey-500">
                  <small>Terms of Service for Fans</small>
                </Link>
              </Row>
              <Row>
                <Link to="/terms-creator" className="text-grey-500">
                  <small>Terms of Service for Creators</small>
                </Link>
              </Row>
              <Row>
                <Link to="/refund-policy" className="text-grey-500">
                  <small>Refund Policy</small>
                </Link>
              </Row>
            </Col>
            <Col>
              <Row>
                <Link to="/mission" className="text-grey-500">
                  <small>Our mission</small>
                </Link>
              </Row>
              <Row>
                <Link to="/complaint" className="text-grey-500">
                  <small>Complaint</small>
                </Link>
              </Row>
              <Row>
                <Link to="/faq" className="text-grey-500">
                  <small>FAQ</small>
                </Link>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
