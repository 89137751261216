import RequestService from "./RequestService";

const TipService = {
    sendTip: function (params) {
        return RequestService.post(`/tip/send`, params);
    },
    totalTip: function (params) {
        return RequestService.post(`/tip/get-total`, params);
    },
    tipList: function (params) {
        return RequestService.post(`/tip-plan/list`, params);
    },
    deleteTip: function (params) {
        return RequestService.post(`/tip-plan/remove`, params);
    },
    addTip: function (params) {
        return RequestService.post(`/tip-plan/add`, params);
    },
    updateTip: function (params) {
        return RequestService.post(`/tip-plan/update`, params);
    },
}

export default TipService;