import * as serviceWorker from "./serviceWorker";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./main.scss";
import "./i18n/config";

import { store } from "./redux/store/store";

// console.log = function () {};
console.error = function () {};
console.warn = function () {};

ReactDOM.render(
  process.env.REACT_APP_ENV === "development" ? (
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  ) : (
    <Provider store={store}>
      <App />
    </Provider>
  ),
  document.getElementById("root"),
);

serviceWorker.register();
