import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { getUserProfilePic, timeClipComment } from "../../helper/GeneralHelper";
import { Image } from "react-bootstrap";
import { BiLike, BiReply } from "react-icons/bi";
import { MultiNumber } from "../MultiNumber";
import AccountVerifyTick from "../AccountVerifyTick";
import DefaultAvatar from "../DefaultAvatar";

class SingleCommentClip extends Component {
  render() {
    return (
      <Fragment>
        <div
          className="comment-user-data"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <DefaultAvatar
            id={this.props._id}
            userName={this.props.name}
            width={"32px"}
            height={"32px"}
            className="picture-story"
          >
            {this.props.profilePicture ? (
              <Image
                className="picture-story"
                width={32}
                height={32}
                title={this.props.name}
                alt={this.props.name}
                src={getUserProfilePic(
                  this.props._id,
                  this.props.profilePicture
                )}
              ></Image>
            ) : null}
          </DefaultAvatar>
          <span className="name-comment-creator">
            {this.props.name}{" "}
            {this.props?.isCreatorVerified ? (
              <span style={{ marginLeft: "2px", verticalAlign: "text-bottom" }}>
                <AccountVerifyTick width="15px"></AccountVerifyTick>
              </span>
            ) : null}
          </span>
          <span className="clip-pre-url">
            {timeClipComment(this.props.createdAt, this.props.t)}
          </span>
        </div>
        <div className="comment-clip-text">{this.props.commentText}</div>
        <div className="reply-comment-clip">
          <span className="mr-16px hover">
            <BiLike
              style={{ verticalAlign: "text-bottom" }}
              onClick={this.props.leaveLike}
            ></BiLike>{" "}
            <MultiNumber numberToFix={this.props.totalCommentReactions} />{" "}
            {this.props.t("clip.likes")}
            <div
              className={`emoji-wrap pointer z-index-99 ${
                this.props.showAddReactionComment && "active"
              }`}
            ></div>
          </span>
          {this.props.showReply ? (
            <span onClick={this.props.replyAction} className="hover">
              <BiReply style={{ verticalAlign: "text-bottom" }}></BiReply>{" "}
              {this.props.t("clip.reply")}
            </span>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(SingleCommentClip);
