import RequestService from "./RequestService";

const ChatService = {
    scheduledMassMessagesList: function (params) {
        return RequestService.post(`/chat/get-scheduled-mass-messages`, params);
    },
    scheduledMessagesList: function (params) {
        return RequestService.post(`/chat/get-scheduled-messages`, params);
    },
    getUnreadMessage: function () {
        return RequestService.get(`/chat/unread-messages-count`);
    },
    setWelcomeMessage: function (params) {
        return RequestService.post(`/chat/set-welcome-message`, params);
    },
    chatCalculateTotal: function (params) {
        return RequestService.post(`/chat/calculate-total`, params);
    },
    chatCalculateTotalToUnlock: function (params) {
        return RequestService.post(`/chat/get-total-to-unlock`, params);
    },
    sendPaidMessageToCreator: function (params) {
        return RequestService.post(`/chat/confirm-total`, params);
    },
    getChatListByUser: function (params) {
        return RequestService.post(`/chat/list-message`, params);
    },
    getNewUserList: function (params) {
        return RequestService.post(`/chat/list-new-user`, params);
    },
    purchaseMessage: function (params) {
        return RequestService.post(`/chat/unlock`, params);
    },
    editMessage: function (params) {
        return RequestService.post(`/chat/edit-message`, params);
    },
    editMassMessage: function (params) {
        return RequestService.post(`/chat/edit-mass-message`, params);
    },
    deleteScheduledMessage: function (params) {
        return RequestService.post(`/chat/delete-scheduled-message`, params);
    },
    unsendMassMessage: function (params) {
        return RequestService.post(`/chat/unsend-mass-message`, params);
    },
    searchPeople: function (params) {
        return RequestService.post(`/chat/search/users`, params);
    },
    searchMessages: function (params) {
        return RequestService.post(`/chat/search/messages`, params);
    },
    getUserInfo: function (params) {
        return RequestService.post(`/chat/user-info`, params);
    },
    scheduleMassMessage: function (params) {
        return RequestService.post(`/chat/send-mass-scheduled`, params);
    },
    scheduleMessage: function (params) {
        return RequestService.post(`/chat/send-scheduled`, params);
    },
    getMassMessageStats: function (page) {
        return RequestService.get(`/chat/mass-message-stats?page=${page || 1}`);
    },
}

export default ChatService;