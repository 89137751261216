import React, { Component } from 'react'

class ErrorPageLayout extends Component {

    render() {
        return (
            <div className="main-wrap" onContextMenu={(e)=> e.preventDefault()}>
                {this.props.children}
            </div>
        )
    }
}

export default ErrorPageLayout