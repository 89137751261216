let clickedClass = "clicked";
const body = document.body;
const lightTheme = "theme-light";
const darkTheme = "theme-dark";
let theme;

if (localStorage) {
  theme = localStorage.getItem("theme");
}

if (theme === lightTheme || theme === darkTheme) {
  if (theme === lightTheme) {
    body.classList.remove(darkTheme);
    body.classList.add(theme);
  } else {
  }
} else {
  body.classList.add(darkTheme);
  localStorage.setItem("theme", "theme-dark");
}

const switchTheme = () => {
  if (theme === lightTheme) {
    body.classList.replace(lightTheme, darkTheme);
    // e.target.classList.add(clickedClass);
    localStorage.setItem("theme", "theme-dark");
    theme = darkTheme;
  } else {
    body.classList.replace(darkTheme, lightTheme);
    // e.target.classList.remove(clickedClass);
    localStorage.setItem("theme", "theme-light");
    theme = lightTheme;
  }
};

export { switchTheme, theme, clickedClass };
