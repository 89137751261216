import React, { Component } from 'react'
import { connect } from "react-redux";
import SupportHeader from '../components/SupportHeader';
import SupportFooter from '../components/SupportFooter';
class ProtectedSupportLayout extends Component {

    render() {
        return (
          <div
            style={{ overflow: "hidden" }}
            onContextMenu={(e) => e.preventDefault()}
          >
            <SupportHeader title={"My Support Ticket"} />
            {this.props.children}
            <SupportFooter />
          </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        loggedUserInfo: state.authentication
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedSupportLayout);