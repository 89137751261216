import React, { Component, Fragment } from "react";
import StoryService from "../../../services/StoryService.js";
import { withTranslation } from "react-i18next";
import { Image, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  AWS_URL,
  REDUX_LOCAL_STORE_KEY,
} from "../../../components/config/constants";
import Stories from "react-insta-stories";
import Carousel from "react-elastic-carousel";
import Loader from "../../../components/Loader.jsx";
import { Link } from "react-router-dom";
import { FaXmark } from "react-icons/fa6";
import { GoMute, GoUnmute } from "react-icons/go";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { GoEye } from "react-icons/go";
import { BsThreeDots } from "react-icons/bs";
import TipModal from "../Tip/TipModal";
import { GoAlertFill } from "react-icons/go";
import { BiDollarCircle } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import { BiMessageRoundedDots, BiSolidTrashAlt } from "react-icons/bi";
import { SocketContext } from "../../../context/SocketContext";
import ReportModal from "../../../components/common/commonModal/ReportModal.jsx";
import { Modal } from "react-bootstrap";
import { MultiNumber } from "../../../components/common/MultiNumber.jsx";
import { USER_TYPES } from "../../../components/config/constants";
import { getUserProfilePic } from "../../../components/helper/GeneralHelper.js";
import DefaultAvatar from "../../../components/common/DefaultAvatar.jsx";
import AccountVerifyTick from "../../../components/common/AccountVerifyTick.jsx";
import { IoMdThumbsUp } from "react-icons/io";

class StoriesComponent extends Component {
  static contextType = SocketContext;
  constructor(props) {
    super(props);
    this.clickSendMessage = React.createRef();
    if (this.props.location.state?.currentCreator === undefined) {
      this.props.history.push("/");
      window.location.reload();
    }
    this.carouselRef = React.createRef();
    this.width = window.innerWidth;
    this.isMobile = this.width <= 512;
    this.isTab = this.width <= 9999;
    this.state = {
      from: this.props.history.location.state.from,
      arrayStoryUrl: [""],
      currentCreator:
        this.props.location.state?.currentCreator +
        (this.isMobile ? 0 : this.isTab ? 1 : 2),
      creatorDetails: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
        .authentication.userData,
      storyList: this.props.location.state?.storyList
        ? this.isMobile
          ? [...this.props.location.state?.storyList]
          : this.isTab
          ? [
              { blank: true },
              ...this.props.location.state?.storyList,
              { blank: true },
            ]
          : [
              { blank: true },
              { blank: true },
              ...this.props.location.state?.storyList,
              { blank: true },
              { blank: true },
            ]
        : null,
      currentStory: 0,
      isStoryMuted: false,
      showOption: false,
      showModalTip: false,
      showModalReport: false,
      messageValue: "",
      showModalStatistics: false,
      storyLikeList: [],
      myStories: [],
    };
  }

  componentDidMount = () => {
    // this.displayAllStory()
    window.addEventListener("click", this.handleClick);
    window.addEventListener("touch", this.handleTouch);
    if (this.props.location.state.isArchiveStory === true) {
      this.carouselRef.goTo(
        this.state.currentCreator - (this.isMobile ? 0 : this.isTab ? 1 : 2)
      );
      this.newArrayStory();
    } else {
      let isCreator =
        JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY)).authentication
          ?.userData?.userType === USER_TYPES.CREATOR;
      if (isCreator) {
        this.getStoryListFirst();
      } else {
        this.getStoryList();
      }
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("click", this.handleClick);
    window.removeEventListener("touch", this.handleTouch);
  };

  displayAllStory = () => {
    this.carouselRef.goTo(
      this.state.currentCreator - (this.isMobile ? 0 : this.isTab ? 1 : 2)
    );
    const firstStory = this.state.storyList[
      this.state.currentCreator
    ].stories.map((value) => ({
      url: value.storyMedia.storyUrl,
      type: value.storyMedia.type,
      id: value._id,
      liked: value.userStoryDetails[0]?.liked
        ? value.userStoryDetails[0]?.liked
        : false,
    }));
    this.setState({ arrayStoryUrl: firstStory, currentStory: 0 }, () => {
      this.visualizeStory();
    });
  };

  getStoryLike = (storyId) => {
    let params = {
      storyId: storyId,
      page: 0,
      limit: 250,
    };
    StoryService.getStoryLike({ ...params })
      .then((res) => this.setState({ storyLikeList: res.data.data[0].data }))
      .catch((err) => console.log(err.message));
  };

  getStoryListFirst = () => {
    StoryService.myStories()
      .then((res) => {
        let myStories = {
          creatorDetails: {
            name: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
              .authentication.userData.name,
            profilePicture: JSON.parse(
              localStorage.getItem(REDUX_LOCAL_STORE_KEY)
            ).authentication.userData.profilePicture,
            userName: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
              .authentication.userData.userName,
            _id: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
              .authentication.userData._id,
            isCreatorVerified: JSON.parse(
              localStorage.getItem(REDUX_LOCAL_STORE_KEY)
            ).authentication.userData.isCreatorVerified,
          },
          stories: res.data.data,
          subscribedCreatorId: JSON.parse(
            localStorage.getItem(REDUX_LOCAL_STORE_KEY)
          ).authentication.userData._id,
        };
        this.setState({ myStories }, () => {
          StoryService.storyList()
            .then((res) => {
              // this.setState({
              //   storyList: [...res.data.data, this.state.myStories],
              // });

              this.setState(
                {
                  storyList:
                    this.state.myStories?.stories?.length > 0
                      ? this.isMobile
                        ? [this.state.myStories, ...res.data.data]
                        : this.isTab
                        ? [
                            { blank: true },
                            this.state.myStories,
                            ...res.data.data,

                            { blank: true },
                          ]
                        : [
                            { blank: true },
                            { blank: true },
                            this.state.myStories,
                            ...res.data.data,

                            { blank: true },
                            { blank: true },
                          ]
                      : this.isMobile
                      ? [...res.data.data]
                      : this.isTab
                      ? [{ blank: true }, ...res.data.data, { blank: true }]
                      : [
                          { blank: true },
                          { blank: true },
                          ...res.data.data,

                          { blank: true },
                          { blank: true },
                        ],
                },
                () => this.displayAllStory()
              );
            })
            .catch((err) => {
              toast.error(err.message);
            });
        });
      })
      .catch((err) => {});
  };

  getStoryList = () => {
    StoryService.storyList()
      .then((res) => {
        this.setState(
          {
            storyList: this.isMobile
              ? [...res.data.data]
              : this.isTab
              ? [{ blank: true }, ...res.data.data, { blank: true }]
              : [
                  { blank: true },
                  { blank: true },
                  ...res.data.data,
                  { blank: true },
                  { blank: true },
                ],
          },
          () => this.displayAllStory()
        );
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  handleStoryEnd = (event) => {
    this.setState({ currentCreator: this.state.currentCreator + 1 });
  };

  scrollPrev = () => {
    this.carouselRef.slidePrev();
    this.setState({ currentCreator: this.state.currentCreator - 1 }, () =>
      this.newArrayStory()
    );
  };

  sendMessage = () => {
    let params = {
      senderId: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
        .authentication.userData._id,
      receiverId:
        this.state.storyList[this.state.currentCreator].creatorDetails?._id,
      message: {
        text: this.state.messageValue,
        messagePrice: null,
        media: [],
      },
    };
    this.context?.socket?.emit("add_message", params);
    this.setState({ messageValue: "" });
  };

  scrollNext = () => {
    if (
      this.state.currentCreator ===
      this.state.storyList.length - (this.isMobile ? 1 : this.isTab ? 2 : 3)
    ) {
      this.setState({ currentStory: this.state.currentStory + 1 }, () => {
        if (this.state.from === "archive") {
          window.location.pathname = "/archive";
        } else {
          window.location.pathname = "/";
        }
      });
    } else {
      this.carouselRef.slideNext();
      this.setState({ currentCreator: this.state.currentCreator + 1 }, () =>
        this.newArrayStory()
      );
    }
  };

  muteStory = () => {
    this.setState({ isStoryMuted: !this.state.isStoryMuted }, () =>
      this.muteAllVideos()
    );
  };

  muteAllVideos = () => {
    const videoElements = document.getElementsByTagName("video");
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i];
      videoElement.muted = this.state.isStoryMuted;
    }
  };

  newArrayStory = () => {
    if (this.props.location.state.isArchiveStory === false) {
      const arrayStories = this.state.storyList[
        this.state.currentCreator
      ].stories.map((value) => ({
        url: value.storyMedia.storyUrl,
        type: value.storyMedia.type,
        id: value._id,
        liked: value.userStoryDetails[0]?.liked
          ? value.userStoryDetails[0]?.liked
          : false,
      }));
      this.muteAllVideos();
      this.setState({ arrayStoryUrl: arrayStories, currentStory: 0 }, () =>
        this.visualizeStory()
      );
    } else {
      const arrayStories = [
        {
          url: `${
            this.state.storyList[this.state.currentCreator].storyMedia.storyUrl
          }`,
          type: this.state.storyList[this.state.currentCreator].storyMedia.type,
          id: this.state.storyList[this.state.currentCreator]._id,
          liked: false,
        },
      ];
      this.muteAllVideos();
      this.setState({ arrayStoryUrl: arrayStories, currentStory: 0 }, () =>
        this.visualizeStory()
      );
    }
  };

  clickStory = (storyIndex) => {
    this.carouselRef.goTo(
      storyIndex - (this.isMobile ? 0 : this.isTab ? 1 : 2)
    );
    this.setState({ currentCreator: storyIndex }, () => this.newArrayStory());
  };

  visualizeStory = () => {
    if (this.props.location.state.isArchiveStory === false) {
      let params = {
        storyId:
          this.state.arrayStoryUrl[
            this.state.arrayStoryUrl[this.state.currentStory]
              ? this.state.currentStory
              : 0
          ].id,
      };
      StoryService.visualizeStory({ ...params })
        .then(() => {})
        .catch((err) => toast.error(err.message));
    }
  };

  likeOrDislike = () => {
    let params = {
      storyId: this.state.arrayStoryUrl[this.state.currentStory].id,
    };
    StoryService.likeStory({ ...params })
      .then((res) => {
        this.adjustArrayForLike();
      })
      .catch((err) => {});
  };

  deleteStory = (storyId) => {
    let params = {
      storyId: storyId,
    };
    StoryService.deleteStorie({ ...params })
      .then((res) => {
        this.props.history.push("/");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  adjustArrayForLike = () => {
    let storyList = this.state.storyList;
    let arrayStoryUrl = this.state.arrayStoryUrl;
    arrayStoryUrl[this.state.currentStory].liked = arrayStoryUrl[
      this.state.currentStory
    ].liked
      ? !arrayStoryUrl[this.state.currentStory].liked
      : true;
    storyList[this.state.currentCreator].stories[
      this.state.currentStory
    ].userStoryDetails[0] = {};
    storyList[this.state.currentCreator].stories[
      this.state.currentStory
    ].userStoryDetails[0].liked = arrayStoryUrl[this.state.currentStory].liked;
    this.setState({ arrayStoryUrl: arrayStoryUrl, storyList: storyList });
  };

  render() {
    return (
      <Fragment>
        <div
          onClick={() => {
            if (this.state.showOption === true) {
              this.setState({ showOption: false });
            }
          }}
          className="stories-page"
        >
          <div className="carousel-controls"></div>
          <Carousel
            ref={(ref) => (this.carouselRef = ref)}
            enableSwipe={true}
            showArrows={false}
            pagination={false}
            className="align-center"
            breakPoints={[
              {
                width: 0,
                itemsToShow: this.isMobile ? 1 : this.isTab ? 3 : 5,
              },
            ]}
            onChange={(event) =>
              this.setState(
                { currentCreator: event.index + (this.isMobile ? 0 : 1) },
                () => this.newArrayStory()
              )
            }
          >
            {this.state.storyList.map((value, index) => (
              <div>
                {index === this.state.currentCreator ? (
                  <>
                    <div className="story-card-current flex-paginator">
                      {this.isMobile === false &&
                      this.state.currentCreator - (this.isMobile ? 0 : 1) >
                        0 ? (
                        <span
                          onClick={() => this.scrollPrev()}
                          className="arrow-story-left hover"
                        >
                          <MdArrowBackIosNew></MdArrowBackIosNew>
                        </span>
                      ) : (
                        <span
                          className={`${
                            this.isMobile === false && "arrow-story-left"
                          }`}
                          style={{ boxShadow: "none", opacity: 0 }}
                        ></span>
                      )}

                      <span className="story-style">
                        <span
                          onClick={() =>
                            this.props.history.push(
                              `/${value.creatorDetails?.userName}`
                            )
                          }
                          style={{ position: "absolute" }}
                          className="ultra-fix-story hover story-creator-icon"
                        >
                          <div className="flex-paginator fix-story-icon">
                            <span className="picture-story">
                              {this.props.location.state.isArchiveStory ? (
                                <DefaultAvatar
                                  fontSize={"14px"}
                                  width={"32px"}
                                  height={"32px"}
                                  id={this.state.creatorDetails?._id}
                                  userName={this.state.creatorDetails?.name}
                                >
                                  {this.state.creatorDetails?.profilePicture ? (
                                    <Image
                                      className="picture-story"
                                      width={"32px"}
                                      height={"32px"}
                                      alt={this.state.creatorDetails?.name}
                                      title={this.state.creatorDetails?.name}
                                      src={getUserProfilePic(
                                        this.state.creatorDetails?._id,
                                        this.state.creatorDetails
                                          ?.profilePicture
                                      )}
                                    ></Image>
                                  ) : null}
                                </DefaultAvatar>
                              ) : (
                                <DefaultAvatar
                                  fontSize={"14px"}
                                  width={"32px"}
                                  height={"32px"}
                                  userName={value.creatorDetails?.name}
                                  id={value.subscribedCreatorId}
                                >
                                  {value.creatorDetails?.profilePicture ? (
                                    <Image
                                      className="picture-story"
                                      width={"32px"}
                                      height={"32px"}
                                      alt={value.creatorDetails?.name}
                                      title={value.creatorDetails?.name}
                                      src={getUserProfilePic(
                                        value.subscribedCreatorId,
                                        value.creatorDetails?.profilePicture
                                      )}
                                    ></Image>
                                  ) : null}
                                </DefaultAvatar>
                              )}
                            </span>
                            <span className="hover story-creators-name">
                              {this.props.location.state.isArchiveStory
                                ? this.state.creatorDetails?.name
                                : value.creatorDetails?.name}{" "}
                              {this.props.location.state.isArchiveStory ? (
                                this.state.creatorDetails?.isCreatorVerified ? (
                                  <span
                                    style={{
                                      marginLeft: "2px",
                                      verticalAlign: "text-bottom",
                                    }}
                                  >
                                    <AccountVerifyTick width="15px"></AccountVerifyTick>
                                  </span>
                                ) : null
                              ) : value.creatorDetails?.isCreatorVerified ? (
                                <span
                                  style={{
                                    marginLeft: "2px",
                                    verticalAlign: "text-bottom",
                                  }}
                                >
                                  <AccountVerifyTick width="15px"></AccountVerifyTick>
                                </span>
                              ) : null}
                            </span>
                          </div>
                        </span>
                        <span className="fix-story-100-width remove-option-image-iphone-chrome">
                          <Stories
                            isPaused={
                              this.state.showModalTip ||
                              this.state.showModalReport ||
                              this.state.showModalDelete
                            }
                            stories={this.state.arrayStoryUrl}
                            defaultInterval={15000}
                            width={
                              this.isMobile
                                ? "100vw"
                                : "calc(86.6666666vh * 0.560897)"
                            }
                            height={this.isMobile ? "100vh" : "86.6666666vh"}
                            loop={false}
                            onAllStoriesEnd={this.scrollNext}
                            // loader={<Loader />}
                            muted={this.state.isStoryMuted}
                            loader={<Loader />}
                            onStoryStart={() => {
                              this.visualizeStory();
                            }}
                            onStoryEnd={() =>
                              this.setState(
                                { currentStory: this.state.currentStory + 1 }
                                // () => this.visualizeStory(),
                              )
                            }
                            onNext={() =>
                              this.setState(
                                { currentStory: this.state.currentStory + 1 },
                                () => this.visualizeStory()
                              )
                            }
                            onPrevious={() =>
                              this.setState({
                                currentStory:
                                  this.state.currentStory -
                                  (this.state.currentStory > 0 ? 1 : 0),
                              })
                            }
                          />
                        </span>
                      </span>
                      {this.props.location.state.isArchiveStory === false && (
                        <span
                          onClick={() =>
                            this.setState({
                              showOption: !this.state.showOption,
                            })
                          }
                          className="story-option-button"
                          name="no-close"
                        >
                          <BsThreeDots></BsThreeDots>
                          {this.state.showOption && (
                            <div name="no-close" className="option-story-div">
                              {value.creatorDetails._id !==
                                JSON.parse(
                                  localStorage.getItem(REDUX_LOCAL_STORE_KEY)
                                ).authentication.userData._id && (
                                <div
                                  name="no-close"
                                  className="d-flex justify-content-start align-items-center"
                                  onClick={() =>
                                    this.setState({ showModalReport: true })
                                  }
                                >
                                  <GoAlertFill
                                    style={{
                                      position: "relative",
                                      marginRight: "4px",
                                    }}
                                  ></GoAlertFill>{" "}
                                  {this.props.t("stories.report")}
                                </div>
                              )}
                              {value.creatorDetails._id !==
                                JSON.parse(
                                  localStorage.getItem(REDUX_LOCAL_STORE_KEY)
                                ).authentication.userData._id && (
                                <div
                                  name="no-close"
                                  className="d-flex justify-content-start align-items-center"
                                  onClick={() =>
                                    this.setState({ showModalTip: true })
                                  }
                                >
                                  <BiDollarCircle
                                    style={{
                                      position: "relative",
                                      marginRight: "4px",
                                    }}
                                  ></BiDollarCircle>{" "}
                                  {this.props.t("stories.tip")}
                                </div>
                              )}
                              {value.creatorDetails._id ===
                                JSON.parse(
                                  localStorage.getItem(REDUX_LOCAL_STORE_KEY)
                                ).authentication.userData._id && (
                                <div
                                  name="no-close"
                                  className="d-flex justify-content-start align-items-center"
                                  onClick={() => {
                                    this.setState({ showModalDelete: true });
                                  }}
                                >
                                  <BiSolidTrashAlt
                                    style={{
                                      position: "relative",
                                      marginRight: "4px",
                                    }}
                                  ></BiSolidTrashAlt>{" "}
                                  {this.props.t("stories.delete")}
                                </div>
                              )}
                            </div>
                          )}
                        </span>
                      )}
                      {this.props.location.state.isArchiveStory === false && (
                        <span
                          onClick={() => this.muteStory()}
                          className="mute-button"
                        >
                          {this.state.isStoryMuted === false ? (
                            <GoUnmute></GoUnmute>
                          ) : (
                            <GoMute></GoMute>
                          )}
                        </span>
                      )}

                      {this.isMobile && (
                        <span
                          onClick={() => this.props.history.goBack()}
                          className="close-story-button"
                        >
                          <FaXmark style={{ color: "white" }}></FaXmark>
                        </span>
                      )}

                      {this.isMobile === false &&
                        this.state.currentCreator + (this.isMobile ? 0 : 1) <
                          this.state.storyList.length && (
                          <span
                            onClick={() => this.scrollNext()}
                            className="arrow-story-right hover"
                          >
                            <MdArrowForwardIos></MdArrowForwardIos>
                          </span>
                        )}
                    </div>
                    {/* Segnaposto */}
                    {this.props.location.state.isArchiveStory ? (
                      <div
                        onClick={() => {
                          this.getStoryLike(value._id);
                          this.setState({ showModalStatistics: true });
                        }}
                        className="footer-story-extra hover"
                        style={{ color: "white", zIndex: 999 }}
                      >
                        <div
                          className="d-flex justify-content-center align-items-center text-shadow-20px-black"
                          style={{ gap: "8px" }}
                        >
                          <GoEye />
                          <span className="f-14px-16px">
                            {this.props.t("archive.seeStatistics")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
                          .authentication.userData._id ===
                        value?.creatorDetails?._id ? (
                          <div
                            onClick={() => {
                              this.getStoryLike(
                                value?.stories[this.state.currentStory]?._id
                              );
                              this.setState({ showModalStatistics: true });
                            }}
                            className="footer-story-extra hover"
                            style={{ color: "white", zIndex: 999 }}
                          >
                            <div
                              className="d-flex justify-content-center align-items-center text-shadow-20px-black"
                              style={{ gap: "8px" }}
                            >
                              <GoEye />
                              <span className="f-14px-16px">
                                {this.props.t("archive.seeStatistics")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="footer-story-extra">
                            <div className="footer-story">
                              <span
                                className="border-message-story d-flex justify-content-center align-items-center"
                                style={{ height: "35px" }}
                              >
                                <input
                                  placeholder={this.props.t(
                                    "stories.sendMessage"
                                  )}
                                  style={{
                                    width: this.isMobile
                                      ? window.innerWidth - 60 + "px"
                                      : "280px",
                                  }}
                                  onKeyUp={(e) => {
                                    if (e.code === "Enter") {
                                      this.clickSendMessage.current.click();
                                    }
                                  }}
                                  onChange={(value) =>
                                    this.setState({
                                      messageValue: value.target.value,
                                    })
                                  }
                                  className="message-story"
                                  type="text"
                                  value={this.state.messageValue}
                                ></input>
                              </span>
                              <span
                                style={{ display: "none" }}
                                onClick={() => this.sendMessage()}
                                ref={this.clickSendMessage}
                                className="footer-story-icon hover"
                              >
                                {/* <Link
                            to={{
                              pathname: `/chats`,
                              state: {
                                creatorDetails:
                                  this.state.storyList[
                                    this.state.currentCreator -
                                      (this.isMobile ? 0 : 1)
                                  ].creatorDetails,
                                message: this.state.messageValue,
                              },
                            }}
                            ref={this.clickSendMessage}
                          > */}
                                <BiMessageRoundedDots
                                  style={{ color: "white" }}
                                ></BiMessageRoundedDots>
                                {/* </Link> */}
                              </span>
                              <span
                                style={{ height: "35px" }}
                                className="footer-story-icon hover d-flex justify-content-center align-items-center"
                                onClick={() => this.likeOrDislike()}
                              >
                                {this.state?.arrayStoryUrl[
                                  this.state.currentStory
                                ]?.liked === true ? (
                                  <AiTwotoneHeart></AiTwotoneHeart>
                                ) : (
                                  <AiOutlineHeart></AiOutlineHeart>
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {value?.blank === true ? (
                      <>
                        {index === 0 &&
                        this.props.location.state.isArchiveStory === false &&
                        JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
                          .authentication.userData.userType ===
                          USER_TYPES.CREATOR ? (
                          <div
                            onClick={() =>
                              this.props.history.push("/upload-stories")
                            }
                            className={`story-card-background hover`}
                          >
                            <div
                              style={{ textAlign: "-webkit-center" }}
                              className="hover"
                            >
                              <div className="story-add">
                                <FaPlus></FaPlus>
                              </div>
                              <div
                                style={{ width: "100%", marginTop: "8px" }}
                                className="stories-add-story-text"
                              >
                                {this.props.t("stories.addYourStories")}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className={`story-card-blank`}></div>
                        )}
                      </>
                    ) : (
                      <div
                        onClick={() => this.clickStory(index)}
                        className={`story-card-background hover`}
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundImage: `url(${
                            (value?.stories
                              ? value.stories[0]
                              : value
                            ).storyMedia?.type?.includes("video")
                              ? (value?.stories ? value.stories[0] : value)
                                  .storyMedia.thumbnailUrl
                              : (value?.stories ? value.stories[0] : value)
                                  .storyMedia?.storyUrl
                          })`,
                        }}
                      >
                        <div className="story-box-extra hover">
                          <div className="story-box">
                            {this.props.location.state.isArchiveStory ? (
                              <DefaultAvatar
                                id={this.state.creatorDetails?._id}
                                userName={this.state.creatorDetails?.name}
                                width={"52px"}
                                height={"52px"}
                                className={`${
                                  !this.props.location.state.isArchiveStory
                                    ? value.stories.some(
                                        (storie) =>
                                          storie?.userStoryDetails?.length > 0
                                      )
                                      ? "story-img-border-gray"
                                      : "story-img-border"
                                    : "story-img-border-gray"
                                }`}
                              >
                                {this.state.creatorDetails?.profilePicture ? (
                                  <Image
                                    className={`${
                                      !this.props.location.state.isArchiveStory
                                        ? value.stories.some(
                                            (storie) =>
                                              storie?.userStoryDetails?.length >
                                              0
                                          )
                                          ? "story-img-border-gray"
                                          : "story-img-border"
                                        : "story-img-border-gray"
                                    }`}
                                    style={{ borderRadius: "99px" }}
                                    width={52}
                                    height={52}
                                    alt={this.state.creatorDetails?.name}
                                    title={this.state.creatorDetails?.name}
                                    src={getUserProfilePic(
                                      this.state.creatorDetails?._id,
                                      this.state.creatorDetails?.profilePicture
                                    )}
                                  ></Image>
                                ) : null}
                              </DefaultAvatar>
                            ) : (
                              <DefaultAvatar
                                id={value.subscribedCreatorId}
                                className={`${
                                  !this.props.location.state.isArchiveStory
                                    ? value.stories.some(
                                        (storie) =>
                                          storie?.userStoryDetails?.length > 0
                                      )
                                      ? "story-img-border-gray"
                                      : "story-img-border"
                                    : "story-img-border-gray"
                                }`}
                                userName={value.creatorDetails?.name}
                                width={"52px"}
                                height={"52px"}
                              >
                                {value.creatorDetails?.profilePicture ? (
                                  <Image
                                    className={`${
                                      !this.props.location.state.isArchiveStory
                                        ? value.stories.some(
                                            (storie) =>
                                              storie?.userStoryDetails?.length >
                                              0
                                          )
                                          ? "story-img-border-gray"
                                          : "story-img-border"
                                        : "story-img-border-gray"
                                    }`}
                                    style={{ borderRadius: "99px" }}
                                    width={52}
                                    height={52}
                                    alt={value.creatorDetails?.name}
                                    title={value.creatorDetails?.name}
                                    src={getUserProfilePic(
                                      value.subscribedCreatorId,
                                      value.creatorDetails?.profilePicture
                                    )}
                                  ></Image>
                                ) : null}
                              </DefaultAvatar>
                            )}
                          </div>
                          <div className="story-box-name">
                            {value.creatorDetails?.name}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </Carousel>
          {this.isMobile === false && (
            <>
              <div className="red-circle-story">
                <Link to="/" style={{ paddingLeft: "24px", gap: "7.24px" }}>
                  <img
                    className="logo-red-circle"
                    src={`${AWS_URL}logo/logoRedCircle.png`}
                    alt=""
                  ></img>
                  <img
                    className="logo-red-circle"
                    src={`${AWS_URL}logo/${
                      localStorage.getItem("theme") === "theme-light"
                        ? "RedCircleBlack.png"
                        : "RedCircleRed.png"
                    }`}
                    alt=""
                  ></img>
                  {localStorage.getItem("theme") === "theme-dark"}
                </Link>
              </div>
              <div className="close-story">
                <div
                  onClick={() => {
                    if (this.state.from === "archive") {
                      window.location.pathname = "/archive";
                    } else {
                      window.location.pathname = "/";
                    }
                  }}
                  style={{ justifyContent: "center", gap: "7.24px" }}
                  className="hover"
                >
                  <FaXmark className="close-x"></FaXmark>
                </div>
              </div>
            </>
          )}
        </div>
        {this.state.showModalTip && (
          <TipModal
            userName={
              this.state.storyList[this.state.currentCreator]?.creatorDetails
                .userName
            }
            show={this.state.showModalTip}
            onHide={() => this.setState({ showModalTip: false })}
            id={
              this.state.storyList[this.state.currentCreator]
                ?.subscribedCreatorId
            }
            objId={
              this.state.storyList[this.state.currentCreator].stories[
                this.state.currentStory
              ]._id
            }
            tipArea={"STORIES"}
          />
        )}
        {this.state.showModalReport && (
          <ReportModal
            type={3}
            reported={
              this.state.storyList[this.state.currentCreator]
                ?.subscribedCreatorId
            }
            id={this.state.storyList[this.state.currentCreator]?.stories[0]._id}
            show={this.state.showModalReport}
            onHide={() => this.setState({ showModalReport: false })}
          />
        )}
        <Modal
          show={this.state.showModalDelete}
          onHide={() => this.setState({ showModalDelete: false })}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton className="ps-3 pt-2 pb-2">
            <span className="text-color-dark fw-700 font-xssss">
              {this.props.t("stories.delete")}
            </span>
          </Modal.Header>
          <Modal.Body
            className={`p-4 add-card-modal ${
              this.state.doMessage === true ? "pb-0" : ""
            }`}
          >
            <span className="text-color-dark fw-400 font-xssss">
              {this.props.t("stories.deleteDescription")}
            </span>
          </Modal.Body>
          <Modal.Footer className="d-flex p-2 justify-content-end">
            <div
              className="pointer fw-bold me-4 font-xsss text-silver"
              onClick={() => this.setState({ showModalDelete: false })}
            >
              {this.props.t("common.cancel")}
            </div>
            <Button
              className="fix-tip-button fw-bold me-2 rounded-pill font-xssss text-white px-3 py-2 pointer"
              // onClick={() =>
              //   this.state.tipValue >= 1
              //     ? this.setState({ doPayment: true })
              //     : toast.error(this.props.t("tip.selectTip"))
              // }
              onClick={() => {
                this.deleteStory(
                  this.state.storyList[this.state.currentCreator]?.stories[
                    this.state.currentStory
                  ]._id
                );
              }}
            >
              {this.props.t("account.deleteAccount.modal.confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showModalStatistics}
          onHide={() => this.setState({ showModalStatistics: false })}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton className="ps-3 pt-2 pb-2">
            <span className="text-color-dark fw-700 font-xssss">
              {this.props.t("archive.storyStatistics")}
            </span>
          </Modal.Header>
          <Modal.Body
            className={`p-4 add-card-modal stats-modal ${
              this.state.doMessage === true ? "pb-0" : ""
            }
                          `}
          >
            <div>
              <span className="text-12px">
                {this.props.t("archive.totalView")}:{" "}
              </span>
              <span className="select-category-clip">
                <MultiNumber
                  numberToFix={
                    this.state.storyList[this.state.currentCreator].totalViews
                      ? this.state.storyList[this.state.currentCreator]
                          .totalViews
                      : this.state.storyLikeList[0]?.totalViews
                  }
                />{" "}
                {this.props.t("archive.followers")}
              </span>
              <span className="text-12px" style={{ marginLeft: "16px" }}>
                {this.props.t("archive.totalLkes")}:{" "}
              </span>
              <span className="select-category-clip">
                <MultiNumber
                  numberToFix={
                    this.state.storyList[this.state.currentCreator]
                      .storyTotalLikes
                      ? this.state.storyList[this.state.currentCreator]
                          .storyTotalLikes
                      : this.state.storyLikeList[0]?.storyTotalLikes
                  }
                />{" "}
                {this.props.t("archive.followers")}
              </span>
            </div>

            <div className="scroll-like-story">
              {this.state.storyLikeList.map((value, index) => {
                return (
                  <div className="container-user-like-story flex-paginator align-center-only">
                    <span
                      style={
                        !value.userDetails?.profilePicture
                          ? { display: "flex" }
                          : {}
                      }
                    >
                      <DefaultAvatar
                        userName={value.userDetails?.name}
                        width={"48px"}
                        height={"48px"}
                        online={false}
                        id={value.userDetails._id}
                      >
                        {value.userDetails?.profilePicture ? (
                          <Image
                            style={{ borderRadius: "50px" }}
                            width={48}
                            height={48}
                            src={getUserProfilePic(
                              value.userDetails._id,
                              value.userDetails.profilePicture
                            )}
                          ></Image>
                        ) : null}
                      </DefaultAvatar>
                      <span className="user-like-thumbs">
                        <IoMdThumbsUp />
                      </span>
                    </span>
                    <span>
                      <div className="name-user-story">
                        {value.userDetails.userName}
                      </div>
                      <div className="wallet-info">
                        @{value.userDetails.name}
                      </div>
                    </span>
                  </div>
                );
              })}
            </div>
            <Modal.Footer className="d-flex p-2 justify-content-end border-0"></Modal.Footer>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation()(StoriesComponent);
