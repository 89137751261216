import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  AWS_URL,
  REDUX_LOCAL_STORE_KEY,
} from "../../../components/config/constants";
import Loader from "../../../components/Loader";
import { isNotValid } from "../../../components/helper/GeneralHelper.js";
import PostService from "../../../services/PostService.js";
import GeneralService from "../../../services/GeneralService.js";
import StoryService from "../../../services/StoryService.js";
import controlMediaSize from "../../../components/helper/ControlMedia.js";

class UploadStories extends Component {
  constructor(props) {
    super(props);
    this.clickInput = React.createRef();
    this.state = {
      showFilters: false,
      file: null,
      description: "",
      urlForUpload: "",
      uploadedMedia: "",
      uploadedMediaDataUrl: "",
      userId: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
        .authentication.userData._id,
      fileName: "",
      fileType: "",
      thumbNailImage: "",
      blurredThumbNailImage: "",
      inputFileStory: null,
      inputFileEvent: null,
    };
  }

  componentDidMount = () => {};

  handleDragOver = (event) => {
    event.preventDefault();
  };

  handleDrop = () => {
    // console.log(event.dataTransfer.files[0]);
    // event.preventDefault();
    // const formData = new FormData();
    // formData.append("file", event.dataTransfer.files[0]);
    // formData.append("_id", this?.props?.loggedUserInfo?.userData?._id);
    // this.setState(
    //   { fileName: `${new Date().getTime("L")}${event.dataTransfer.files[0].name}` },
    //   () => {
    //     let params = {
    //       folder: "stories",
    //       filename: this.state.fileName,
    //     };
    //     api
    //       .obtainUrlUpload({ ...params })
    //       .then((res) => {
    //         this.setState({ urlForUpload: res.data.data, file: formData }, () =>
    //           this.putFile(this.state.urlForUpload, event.dataTransfer.files[0])
    //         );
    //       })
    //       .catch((err) => console.log(err));
    //   }
    // );
  };

  submitThumbnail = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("_id", this?.props?.loggedUserInfo?.userData?._id);

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const dataURL = event.target.result;
        this.setState({ uploadedMediaDataUrl: dataURL });
      };

      reader.readAsDataURL(file);
    }

    this.setState(
      { fileName: `${new Date().getTime("L")}${file.name}` },
      () => {
        let params = {
          folder: "media",
          filename: this.state.fileName,
        };

        GeneralService.obtainUrlUpload({ ...params })
          .then((res) => {
            this.setState(
              { urlForUpload: res.data.data, file: formData },
              () => {
                PostService.putFile(this.state.urlForUpload, file).then(() => {
                  this.setState({ uploadedMedia: params.filename });
                  const url = URL.createObjectURL(file);
                  const videoElement = document.createElement("video");
                  videoElement.src = url;

                  const onCanPlayThrough = async () => {
                    videoElement.removeEventListener(
                      "canplaythrough",
                      onCanPlayThrough
                    );

                    videoElement.currentTime = 0; // Set current time to beginning

                    const canvas = document.createElement("canvas");
                    canvas.width = videoElement.videoWidth;
                    canvas.height = videoElement.videoHeight;

                    const context = canvas.getContext("2d");

                    const onSeeked = async () => {
                      videoElement.removeEventListener("seeked", onSeeked);

                      context.drawImage(
                        videoElement,
                        0,
                        0,
                        canvas.width,
                        canvas.height
                      );

                      try {
                        // Convert canvas content to a Blob
                        canvas.toBlob(async (blob) => {
                          // Create a new File object from the Blob
                          const imageFile = new File([blob], "thumbnail.jpg", {
                            type: "image/jpeg",
                          });
                          const formData = new FormData();
                          formData.append("file", imageFile);
                          formData.append(
                            "_id",
                            this?.props?.loggedUserInfo?.userData?._id
                          );

                          let params = {
                            folder: "thumbnail",
                            filename: `${new Date().getTime("L")}${
                              imageFile.name
                            }`,
                          };

                          GeneralService.obtainUrlUpload({ ...params })
                            .then((res) => {
                              // this.setState(
                              //   { urlForUpload: res.data.data, file: formData },
                              //   () => this.putFile(this.state.urlForUpload, file)
                              // );
                              this.putFileThumbnail(
                                res.data.data,
                                imageFile,
                                params.filename
                              );
                            })
                            .catch(() => {});
                        }, "image/jpeg");
                      } catch (error) {
                        console.error(
                          "Error converting canvas to Blob:",
                          error
                        );
                      }
                    };

                    videoElement.addEventListener("seeked", onSeeked);
                    videoElement.currentTime = 0;
                  };

                  videoElement.addEventListener(
                    "canplaythrough",
                    onCanPlayThrough
                  );
                });
              }
            );
          })
          .catch(() => {});
      }
    );
  };

  putFileThumbnail = (url, file, name) => {
    PostService.putFile(url, file)
      .then(() => {
        this.setState(
          {
            thumbNailImage: name,
          },
          () => {
            const reader = new FileReader();

            reader.onload = (event) => {
              const dataURL = event.target.result;
              const img = new Image();
              img.src = dataURL;

              img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext("2d");
                ctx.filter = "blur(20px)"; // Imposta l'intensità della sfocatura

                ctx.drawImage(img, 0, 0, img.width, img.height);
                try {
                  // Convert canvas content to a Blob
                  canvas.toBlob(async (blob) => {
                    // Create a new File object from the Blob
                    const imageFile = new File([blob], "thumbnailBlur.jpg", {
                      type: "image/jpeg",
                    });
                    const formData = new FormData();
                    formData.append("file", imageFile);
                    formData.append(
                      "_id",
                      this?.props?.loggedUserInfo?.userData?._id
                    );

                    let params2 = {
                      folder: "thumbnailBlur",
                      filename: `blurredThumbnail${new Date().getTime("L")}${
                        imageFile.name
                      }`,
                    };

                    GeneralService.obtainUrlUpload({ ...params2 })
                      .then((res) => {
                        PostService.putFile(res.data.data, imageFile)
                          .then(() => {
                            this.setState(
                              { blurredThumbNailImage: params2.filename },
                              () => {
                                this.createStorie();
                              }
                            );
                          })
                          .catch(() => {});
                      })
                      .catch(() => {});
                  }, "image/jpeg");
                } catch (error) {
                  console.error("Error converting canvas to Blob:", error);
                }
              };
            };

            reader.readAsDataURL(file);
          }
        );
      })
      .catch(() => {});
  };

  isImage = (file) => {
    return file.type.includes("image");
  };

  isVideo = (file) => {
    return file.type.includes("video");
  };

  submitStories = async (file, event) => {
    if (!isNotValid(event)) {
      this.setState({ loading: true });
      event.preventDefault();
      if (this.isImage(file) || this.isVideo(file)) {
        const duration = await this.getVideoDuration(file);
        if (
          (this.isVideo(file) &&
            (duration === "isPhoto" ? 0 : duration) <= 60) ||
          this.isImage(file)
        ) {
          if (file.type.includes("video")) {
            this.submitThumbnail(file);
          } else {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("_id", this?.props?.loggedUserInfo?.userData?._id);

            if (file) {
              const reader = new FileReader();

              reader.onload = (event) => {
                const dataURL = event.target.result;
                this.setState({ uploadedMediaDataUrl: dataURL });
              };

              reader.readAsDataURL(file);
            }

            this.setState(
              { fileName: `${new Date().getTime("L")}${file.name}` },
              () => {
                let params = {
                  folder: "media",
                  filename: this.state.fileName,
                };

                GeneralService.obtainUrlUpload({ ...params })
                  .then((res) => {
                    this.setState(
                      { urlForUpload: res.data.data, file: formData },
                      () => this.putFile(this.state.urlForUpload, file)
                    );
                  })
                  .catch(() => {});
              }
            );
          }
        } else {
          toast.error(this.props.t("clip.maxOneMinute"));
        }
      } else {
        this.setState({ fileType: "undefined" });
        toast.error(this.props.t("clip.invalidFile"));
      }
    } else {
      this.setState({ fileType: "undefined" });
      toast.error(this.props.t("clip.invalidFile"));
    }
  };

  putFile = (url, file) => {
    let upMedia = `${this.state.fileName}`;
    PostService.putFile(url, file)
      .then(() => {
        this.setState(
          {
            fileType: this.isImage(file)
              ? "image"
              : this.isVideo(file)
              ? "video"
              : "undefined",
            uploadedMedia: upMedia,
          },
          () => {
            const img = new Image();
            img.src = this.state.uploadedMediaDataUrl;

            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;

              const ctx = canvas.getContext("2d");
              ctx.filter = "blur(20px)"; // Imposta l'intensità della sfocatura

              ctx.drawImage(img, 0, 0, img.width, img.height);

              try {
                // Convert canvas content to a Blob
                canvas.toBlob(async (blob) => {
                  // Create a new File object from the Blob
                  const imageFile = new File([blob], "thumbnailBlur.jpg", {
                    type: "image/jpeg",
                  });
                  const formData = new FormData();
                  formData.append("file", imageFile);
                  formData.append(
                    "_id",
                    this?.props?.loggedUserInfo?.userData?._id
                  );

                  let params2 = {
                    folder: "thumbnailBlur",
                    filename: `blurredThumbnail${new Date().getTime("L")}${
                      imageFile.name
                    }`,
                  };
                  GeneralService.obtainUrlUpload({ ...params2 })
                    .then((res) => {
                      PostService.putFile(res.data.data, imageFile)
                        .then(() => {
                          this.setState(
                            { blurredThumbNailImage: params2.filename },
                            () => {
                              this.createStorie();
                            }
                          );
                        })
                        .catch(() => {});
                    })
                    .catch(() => {});
                }, "image/jpeg");
              } catch (error) {
                console.error("Error converting canvas to Blob:", error);
              }
            };
          }
        );
      })
      .catch(() => {});
  };

  getImageUrl = (id) => {
    this.pictureService
      .get(id)
      .then((res) => this.setState({ profileImage: res.data.link }));
  };

  createStorie = () => {
    if (this.state.uploadedMedia !== "") {
      if (this.state.fileType !== "undefined") {
        let params = {
          storyMedia: {
            isVaultMedia: false,
            name: this.state.uploadedMedia,
            thumbNailImage:
              this.state.fileType === "video"
                ? this.state.thumbNailImage
                : this.state.uploadedMedia,
            type: this.state.fileType,
            blurredThumbNailImage: this.state.blurredThumbNailImage,
          },
        };
        StoryService.createStorie({ ...params })
          .then(() => {
            this.setState({ loading: false });
            toast.success(this.props.t("stories.storySuccessfullyCreated"));
            this.props.history.push("/");
          })
          .catch((err) => toast.error(err.message));
      } else {
        toast.error(this.props.t("clip.invalidFile"));
      }
    } else {
      toast.error(this.props.t("stories.selectVideoOrPhoto"));
    }
  };

  getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      if (this.isImage(file)) {
        resolve("isPhoto");
      } else {
        const videoElement = document.createElement("video");
        videoElement.onloadedmetadata = () => {
          const duration = videoElement.duration;
          videoElement.remove();
          resolve(duration);
        };

        videoElement.onerror = () => {
          videoElement.remove();
          reject("error");
        };
        videoElement.src = URL.createObjectURL(file);
      }
    });
  };

  render() {
    const width = window.innerWidth;
    var isMobile = width <= 512;
    var isTab = width <= 992;

    return (
      <Fragment>
        <div className={`${this.state.loading && "main-overlay"}`}></div>
        {this.state.loading ? <Loader /> : null}
        <div
          style={
            isMobile
              ? {
                  padding: "20px",
                  height: "calc(100dvh - 64px)",
                  overflow: "hidden",
                }
              : {}
          }
          className="right-chat-active"
        >
          <div
            style={isMobile ? {} : { marginLeft: "10%", marginTop: "16px" }}
            className="mb-16px"
          >
            <span
              className="clip-pre-url hover"
              onClick={() => this.props.history.push("/stories")}
            >
              {this.props.t("stories.stories")}{" "}
            </span>
            <span className="text-12px f-black darktheme-txt">
              {" / "}
              {this.props.t("stories.uploadStories")}
            </span>
          </div>
          <div
            className={`${isMobile || isTab ? " mb-5" : ""} ${
              isTab === true ? "middle-sidebar-bottom" : "desk-modal-up"
            }`}
            style={
              isMobile
                ? { height: "100%", display: "grid", alignContent: "center" }
                : {}
            }
          >
            <div
              onDrop={(event) => {
                // this.submitStories(event.dataTransfer.files[0], event)
                this.setState({
                  inputFileStory: event.dataTransfer.files[0],
                  inputFileEvent: event,
                });

                let file = event.dataTransfer.files[0];
                if (file.type.includes("image")) {
                  this.setState({ fileType: "image" });
                }
                if (file.type.includes("video")) {
                  this.setState({ fileType: "video" });
                }
                if (file) {
                  const reader = new FileReader();

                  reader.onload = (event) => {
                    const dataURL = event.target.result;
                    this.setState({ uploadedMediaDataUrl: dataURL });
                  };

                  reader.readAsDataURL(file);
                }
              }}
              onDragOver={(event) => this.handleDragOver(event)}
              className="card w-100 clip-container"
            >
              <input
                onChange={async (event) => {
                  const controlledFile = await controlMediaSize(
                    [{ file: event.target.files[0] }],
                    this.props.t
                  );
                  if (controlledFile) {
                    this.setState({
                      inputFileStory: event.target.files[0],
                      inputFileEvent: event,
                    });

                    let file = event.target.files[0];
                    if (file.type.includes("image")) {
                      this.setState({ fileType: "image" });
                    }
                    if (file.type.includes("video")) {
                      this.setState({ fileType: "video" });
                    }

                    if (file) {
                      const reader = new FileReader();

                      reader.onload = (event) => {
                        const dataURL = event.target.result;
                        this.setState({ uploadedMediaDataUrl: dataURL });
                      };

                      reader.readAsDataURL(file);
                    }
                  }
                }}
                type="file"
                ref={this.clickInput}
                style={{ display: "none" }}
                accept={
                  ".jpg, .jpeg, .gif, .png, .mp4, .mov, .quicktime, .moov, .m4v, .mpg, .mpeg, .wmv, .avi, .webm, .mkv"
                }
              ></input>
              <div className="card-body p-0 mt-3">
                <div className="absolute-center">
                  {this.state.uploadedMediaDataUrl === "" ? (
                    <img
                      alt=""
                      src={`${AWS_URL}logo/upload_placeholder.png`}
                      height={80}
                      width={80}
                    ></img>
                  ) : (
                    <>
                      {this.state.fileType === "image" && (
                        <img
                          alt=""
                          className="br-8px image-fit-300"
                          width={"100%"}
                          src={this.state.uploadedMediaDataUrl}
                        ></img>
                      )}

                      {this.state.fileType === "video" && (
                        <video
                          className={`${
                            isMobile ? "w-100 h-100" : "mw-332px"
                          } br-8px`}
                          width={"100%"}
                          controls
                          controlsList="nodownload nocontextmenu noremoteplayback"
                          oncontextmenu="return false;"
                        >
                          <source
                            src={this.state.uploadedMediaDataUrl}
                            type="video/mp4"
                          />
                        </video>
                      )}
                      {this.state.fileType === "undefined" && (
                        <div>This file is not valid</div>
                      )}
                    </>
                  )}
                </div>
                {this.state.uploadedMedia === "" ? (
                  <div className="absolute-center upload-media-text">
                    {this.props.t("clip.uploadMedia")}
                  </div>
                ) : null}
                {isMobile === false ? (
                  <>
                    {this.state.uploadedMedia === "" && (
                      <>
                        <div className="absolute-center wallet-info">
                          {this.props.t("clip.dragAndDrop")}
                        </div>
                        <div className="absolute-center wallet-info">
                          {this.props.t("clip.or")}
                        </div>
                      </>
                    )}
                  </>
                ) : null}
                <div
                  onClick={() => this.clickInput.current.click()}
                  className="absolute-center clip-browse hover"
                >
                  {this.props.t("clip.browseMedia")}
                </div>
              </div>
            </div>
            <div className="clip-what  d-flex justify-content-center">
              <Button
                onClick={() =>
                  // this.createStorie()
                  this.submitStories(
                    this.state.inputFileStory,
                    this.state.inputFileEvent
                  )
                }
                className="align-center fix-buttom mb-0 p-0 btn p-2 lh-24 w125  d-inline-block rounded-xl subscriptionSubscibe border-red detailTitle fw-700 ls-lg text-white"
              >
                {this.props.t("stories.uploadStories")}
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(UploadStories);
