import RequestService from "./RequestService";

const AuthService = {
  login: function (params) {
    return RequestService.post("/user/login", params);
  },
  socialLogin: function (params) {
    return RequestService.post("/user/social-login", params);
  },
  signUp: function (params) {
    return RequestService.post("/user/signup", params);
  },
  forgotPassword: function (params) {
    return RequestService.post("/user/forgot-password", params);
  },
  resetPassword: function (params) {
    return RequestService.post("/user/reset-password", params);
  },
  checkToken: function (params) {
    return RequestService.post("/user/check-forgot-password-token", params);
  },
  changePassword: function (params) {
    return RequestService.post("/user/change-password", params);
  },
  logout: function (params) {
    return RequestService.post("/user/log-out", params);
  },
};

export default AuthService;
