import React, { Component, Fragment } from "react";
import api from "../../../components/helper/Api.js";
import { withTranslation } from "react-i18next";
import { Image, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { REDUX_LOCAL_STORE_KEY } from "../../../components/config/constants";
import Stories from "react-insta-stories";
import Loader from "../../../components/Loader.jsx";
import { FaXmark } from "react-icons/fa6";
import { GoEye, GoMute, GoUnmute } from "react-icons/go";
import { BsThreeDots } from "react-icons/bs";
import { BiSolidTrashAlt } from "react-icons/bi";
import { SocketContext } from "../../../context/SocketContext";
import { getUserProfilePic } from "../../../components/helper/GeneralHelper.js";
import DefaultAvatar from "../../../components/common/DefaultAvatar.jsx";
import AccountVerifyTick from "../../../components/common/AccountVerifyTick.jsx";
import StoryService from "../../../services/StoryService.js";
import { MultiNumber } from "../../../components/common/MultiNumber.jsx";
import { IoMdThumbsUp } from "react-icons/io";

class SingleStory extends Component {
  static contextType = SocketContext;

  constructor(props) {
    super(props);
    const searchParams = new URLSearchParams(this.props.location.search);
    this.carouselRef = React.createRef();
    this.width = window.innerWidth;
    this.isMobile = this.width <= 512;
    this.isTab = this.width <= 9999;
    this.state = {
      arrayStoryUrl: [""],
      currentCreator:
        this.props.location.state?.currentCreator +
        (this.isMobile ? 0 : this.isTab ? 1 : 2),
      creatorDetails: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
        .authentication.userData,
      storyId: searchParams.get("storyId"),
      isStoryMuted: false,
      showOption: false,
      story: null,
      storyLikeList: [],
    };
  }

  componentDidMount = () => {
    this.getStoryById(this.state.storyId);
  };

  componentWillUnmount = () => {
    window.removeEventListener("click", this.handleClick);
    window.removeEventListener("touch", this.handleTouch);
  };

  getStoryById = (id) => {
    api
      .getStoryById(id)
      .then((res) => {
        this.setState({
          story: res.data.data,
        });
      })
      .catch((err) => {
        window.location.href = "/story-not-found";
      });
  };

  handleStoryEnd = (event) => {
    this.setState({ currentCreator: this.state.currentCreator + 1 });
  };

  /*  scrollPrev = () => {
         this.carouselRef.slidePrev();
         this.setState({ currentCreator: this.state.currentCreator - 1 }, () =>
             this.newArrayStory()
         );
     }; */

  muteStory = () => {
    this.setState({ isStoryMuted: !this.state.isStoryMuted }, () =>
      this.muteAllVideos()
    );
  };

  getStoryLike = (storyId) => {
    let params = {
      storyId: storyId,
      page: 0,
      limit: 250,
    };
    StoryService.getStoryLike({ ...params })
      .then((res) => this.setState({ storyLikeList: res.data.data[0].data }))
      .catch((err) => console.log(err.message));
  };

  visualizeStory = () => {
    let params = {
      storyId: this.state.storyId,
    };
    api
      .visualizeStory({ ...params })
      .then(() => {})
      .catch((err) => toast.error(err.message));
  };

  muteAllVideos = () => {
    const videoElements = document.getElementsByTagName("video");
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i];
      videoElement.muted = this.state.isStoryMuted;
    }
  };

  deleteStory = (storyId) => {
    let params = {
      storyId: storyId,
    };
    api
      .deleteStorie({ ...params })
      .then((res) => {
        this.props.history.push("/");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  render() {
    const { story, creatorDetails } = this.state;
    return (
      <Fragment>
        {story && (
          <div
            onClick={() => {
              if (this.state.showOption === true) {
                this.setState({ showOption: false });
              }
            }}
            className="stories-page"
          >
            <div className="story-card-current flex-paginator">
              <span className="story-style">
                <span
                  onClick={() =>
                    this.props.history.push(`/${creatorDetails?.userName}`)
                  }
                  style={{ position: "absolute" }}
                  className=" hover story-creator-icon"
                >
                  <div className="flex-paginator fix-story-icon pl-10px">
                    <span className="picture-story">
                      <DefaultAvatar
                        fontSize={"14px"}
                        width={"32px"}
                        height={"32px"}
                        userName={creatorDetails?.name}
                        id={creatorDetails?._id}
                      >
                        {creatorDetails?.profilePicture ? (
                          <Image
                            className="picture-story"
                            width={"32px"}
                            height={"32px"}
                            alt={creatorDetails?.name}
                            title={creatorDetails?.name}
                            src={getUserProfilePic(
                              creatorDetails?._id,
                              creatorDetails?.profilePicture
                            )}
                          ></Image>
                        ) : null}
                      </DefaultAvatar>
                    </span>
                    <span className="hover story-creators-name">
                      {creatorDetails?.name}{" "}
                      {creatorDetails?.isCreatorVerified ? (
                        <span
                          style={{
                            marginLeft: "2px",
                            verticalAlign: "text-bottom",
                          }}
                        >
                          <AccountVerifyTick width="15px"></AccountVerifyTick>
                        </span>
                      ) : null}
                    </span>
                  </div>
                </span>
                <span className="fix-story-100-width">
                  <Stories
                    isPaused={
                      this.state.showModalTip ||
                      this.state.showModalReport ||
                      this.state.showModalDelete
                    }
                    stories={[story.storyMedia.storyUrl]}
                    defaultInterval={15000}
                    width={
                      this.isMobile ? "100vw" : "calc(86.6666666vh * 0.560897)"
                    }
                    height={this.isMobile ? "100vh" : "86.6666666vh"}
                    loop={false}
                    onAllStoriesEnd={this.scrollNext}
                    // loader={<Loader />}
                    muted={this.state.isStoryMuted}
                    loader={<Loader />}
                    onStoryStart={() => {
                      this.visualizeStory();
                    }}
                    onStoryEnd={() => {
                      this.props.history.goBack();
                    }}
                    onNext={() => {
                      this.props.history.goBack();
                    }}
                    onPrevious={() => {
                      // this.props.history.goBack();
                    }}
                  />
                </span>
                <div
                  onClick={() => {
                    this.getStoryLike(this.state.storyId);
                    this.setState({ showModalStatistics: true });
                  }}
                  className="footer-story-extra hover"
                  style={{ color: "white", zIndex: 999 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center text-shadow-20px-black"
                    style={{ gap: "8px" }}
                  >
                    <GoEye />
                    <span className="f-14px-16px">
                      {this.props.t("archive.seeStatistics")}
                    </span>
                  </div>
                </div>
              </span>
              <span
                onClick={() =>
                  this.setState({
                    showOption: !this.state.showOption,
                  })
                }
                className="story-option-button"
                name="no-close"
              >
                <BsThreeDots></BsThreeDots>
                {this.state.showOption && (
                  <div
                    name="no-close"
                    style={{ minHeight: "auto" }}
                    className="option-story-div pointer"
                  >
                    {creatorDetails._id ===
                      JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
                        .authentication.userData._id && (
                      <div
                        name="no-close "
                        className="d-flex justify-content-start align-items-center"
                        onClick={() => {
                          this.deleteStory(this.state.storyId);
                        }}
                      >
                        <BiSolidTrashAlt
                          style={{
                            position: "relative",
                          }}
                        ></BiSolidTrashAlt>{" "}
                        {this.props.t("stories.delete")}
                      </div>
                    )}
                  </div>
                )}
              </span>

              <span onClick={() => this.muteStory()} className="mute-button">
                {this.state.isStoryMuted === false ? (
                  <GoUnmute></GoUnmute>
                ) : (
                  <GoMute></GoMute>
                )}
              </span>

              {this.isMobile && (
                <span
                  onClick={() => this.props.history.goBack()}
                  className="close-story-button"
                >
                  <FaXmark style={{ color: "white" }}></FaXmark>
                </span>
              )}
            </div>
          </div>
        )}
        {!this.isMobile && (
          <div className="close-story" style={{ zIndex: "99999" }}>
            <div
              onClick={() => {
                this.props.history.goBack();
              }}
              style={{ justifyContent: "center", gap: "7.24px" }}
              className="hover"
            >
              <FaXmark className="close-x"></FaXmark>
            </div>
          </div>
        )}
        {/* <Modal
                    show={this.state.showModalDelete}
                    onHide={() => this.setState({ showModalDelete: false })}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton className="ps-3 pt-2 pb-2">
                        <span className="text-color-dark fw-700 font-xssss">
                            {this.props.t("stories.delete")}
                        </span>
                    </Modal.Header>
                    <Modal.Body
                        className={`p-4 add-card-modal ${this.state.doMessage === true ? "pb-0" : ""
                            }`}
                    >
                        <span className="text-color-dark fw-400 font-xssss">
                            {this.props.t("stories.deleteDescription")}
                        </span>
                    </Modal.Body>
                    <Modal.Footer className="d-flex p-2 justify-content-end">
                        <div
                            className="pointer fw-bold me-4 font-xsss text-silver"
                            onClick={() => this.setState({ showModalDelete: false })}
                        >
                            {this.props.t("common.cancel")}
                        </div>
                        <Button
                            className="fix-tip-button fw-bold me-2 rounded-pill font-xssss text-white px-3 py-2 pointer"
                            // onClick={() =>
                            //   this.state.tipValue >= 1
                            //     ? this.setState({ doPayment: true })
                            //     : toast.error(this.props.t("tip.selectTip"))
                            // }
                            onClick={() => {
                                this.deleteStory(
                                    this.state.storyList[this.state.currentCreator]?.stories[
                                        this.state.currentStory
                                    ]._id,
                                );
                            }}
                        >
                            {this.props.t("account.deleteAccount.modal.confirm")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalStatistics}
                    onHide={() => this.setState({ showModalStatistics: false })}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton className="ps-3 pt-2 pb-2">
                        <span className="text-color-dark fw-700 font-xssss">
                            {this.props.t("archive.storyStatistics")}
                        </span>
                    </Modal.Header>
                    <Modal.Body
                        className={`p-4 add-card-modal ${this.state.doMessage === true ? "pb-0" : ""
                            }
                          `}
                    >
                        <div>
                            <span className="text-12px">
                                {this.props.t("archive.totalView")}:{" "}
                            </span>
                            <span className="select-category-clip">
                                <MultiNumber
                                    numberToFix={
                                        story.storyTotalLikes
                                            ? story.storyTotalLikes
                                            : story.totalViews
                                    }
                                />{" "}
                                {this.props.t("archive.followers")}
                            </span>
                            <span className="text-12px" style={{ marginLeft: "16px" }}>
                                {this.props.t("archive.totalLkes")}:{" "}
                            </span>
                            <span className="select-category-clip">
                                <MultiNumber
                                    numberToFix={
                                        story.totalViews
                                            ? story.totalViews
                                            : story.storyTotalLikes
                                    }
                                />{" "}
                                {this.props.t("archive.followers")}
                            </span> 
                        </div>
                        <Modal.Footer className="d-flex p-2 justify-content-end border-0"></Modal.Footer>
                    </Modal.Body>
                </Modal> */}
        <Modal
          show={this.state.showModalStatistics}
          onHide={() => this.setState({ showModalStatistics: false })}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton className="ps-3 pt-2 pb-2">
            <span className="text-color-dark fw-700 font-xssss">
              {this.props.t("archive.storyStatistics")}
            </span>
          </Modal.Header>
          <Modal.Body
            className={`p-4 add-card-modal stats-modal ${
              this.state.doMessage === true ? "pb-0" : ""
            }
                          `}
          >
            <div>
              <span className="text-12px">
                {this.props.t("archive.totalView")}:{" "}
              </span>
              <span className="select-category-clip">
                <MultiNumber numberToFix={this.state.story?.totalViews || 0} />{" "}
                {this.props.t("archive.followers")}
              </span>
              <span className="text-12px" style={{ marginLeft: "16px" }}>
                {this.props.t("archive.totalLkes")}:{" "}
              </span>
              <span className="select-category-clip">
                <MultiNumber
                  numberToFix={this.state.story?.storyTotalLikes || 0}
                />{" "}
                {this.props.t("archive.followers")}
              </span>
            </div>

            <div className="scroll-like-story">
              {this.state.storyLikeList?.length > 0 &&
                this.state.storyLikeList.map((value, index) => {
                  return (
                    <div className="container-user-like-story flex-paginator align-center-only">
                      <span
                        style={
                          !value.userDetails?.profilePicture
                            ? { display: "flex" }
                            : {}
                        }
                      >
                        <DefaultAvatar
                          userName={value.userDetails?.name}
                          id={value.userDetails._id}
                          width={"48px"}
                          height={"48px"}
                          online={false}
                        >
                          {value.userDetails?.profilePicture ? (
                            <Image
                              style={{ borderRadius: "50px" }}
                              width={48}
                              height={48}
                              src={getUserProfilePic(
                                value.userDetails._id,
                                value.userDetails.profilePicture
                              )}
                            ></Image>
                          ) : null}
                        </DefaultAvatar>
                        <span className="user-like-thumbs">
                          <IoMdThumbsUp />
                        </span>
                      </span>
                      <span>
                        <div className="name-user-story">
                          {value.userDetails.userName}
                        </div>
                        <div className="wallet-info">
                          @{value.userDetails.name}
                        </div>
                      </span>
                    </div>
                  );
                })}
            </div>
            <Modal.Footer className="d-flex p-2 justify-content-end border-0"></Modal.Footer>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation()(SingleStory);
