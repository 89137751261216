import { Types } from '../../components/config/actionTypes'

var initialState = {
    ipInfoDetails: null
}
// const initialState = ipInfoDetails ? { ipInfoDetails: ipInfoDetails } : nullState;

export function ipInfo(state = initialState, action) {

    switch (action.type) {

        case Types.IP_INFO_DETAILS:
            return {
                ...state,
                ipInfoDetails: action.payload.ipInfoDetails
            };

        case Types.REMOVE_IP_INFO_DETAILS:
            return {
                ...state,
                ipInfoDetails: null,
            }

        default:
            return state
    }
}