import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { withTranslation } from "react-i18next";
import { Component } from "react";
import { grey } from "@mui/material/colors";

import {
  getLanguageDateLocale,
  getLanguageDatePicker,
} from "../helper/LanguageHelper";

export class CustomDateTimePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      darkTheme: createTheme({
        palette: {
          mode:
            localStorage.getItem("theme") === "theme-dark" ? "dark" : "light",
          ...(localStorage.getItem("theme") === "theme-light"
            ? {
                // palette values for light mode
                primary: { main: "#cc0000" },
                text: {
                  secondary: "#fff",
                  primary: grey[800],
                },
              }
            : {
                // palette values for dark mode
                primary: { main: "#cc0000" },
                text: {
                  primary: "#fff",
                  secondary: grey[500],
                },
              }),
        },
        components: {
          /*  MuiDialogTitle: {
                         defaultProps: {
                             title: 'Si'
                         }
                     }, */
          MuiOutlinedInput: {
            defaultProps: {
              className: "datepicker",
            },
            styleOverrides: {
              input: {
                borderWidth: "0px !important",
              },
              error: {
                borderWidth: "0px !important",
              },
              focused: {
                borderWidth: "0px !important",
              },
              notchedOutline: {
                borderWidth: "0px !important",
              },
            },
          },
        },
      }),
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleProjectChange);
    document.addEventListener("touch", this.handleProjectChange);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleProjectChange);
    document.removeEventListener("touch", this.handleProjectChange);
  }

  handleProjectChange = () => {
    this.setState({
      darkTheme: createTheme({
        palette: {
          mode:
            localStorage.getItem("theme") === "theme-dark" ? "dark" : "light",
          ...(localStorage.getItem("theme") === "theme-light"
            ? {
                // palette values for light mode
                primary: { main: "#cc0000" },
                text: {
                  secondary: "#fff",
                  primary: grey[800],
                },
              }
            : {
                // palette values for dark mode
                primary: { main: "#cc0000" },
                text: {
                  primary: "#fff",
                  secondary: grey[500],
                },
              }),
        },
        components: {
          /*  MuiDialogTitle: {
                       defaultProps: {
                           title: 'Si'
                       }
                   }, */
          MuiOutlinedInput: {
            defaultProps: {
              className: "datepicker",
            },
            styleOverrides: {
              input: {
                borderWidth: "0px !important",
              },
              error: {
                borderWidth: "0px !important",
              },
              focused: {
                borderWidth: "0px !important",
              },
              notchedOutline: {
                borderWidth: "0px !important",
              },
            },
          },
        },
      }),
    });
  };

  render() {
    const { date, minTime, onDatePickerChange } = this.props;

    return (
      <ThemeProvider theme={this.state.darkTheme}>
        <LocalizationProvider
          localeText={getLanguageDatePicker()}
          adapterLocale={getLanguageDateLocale()}
          dateAdapter={AdapterMoment}
        >
          <Stack spacing={3}>
            <DateTimePicker
              value={date}
              onChange={(newDate) => {
                onDatePickerChange(newDate);
              }}
              minTime={minTime}
              minDate={moment(moment().add(15, "minutes"))}
            />
          </Stack>
        </LocalizationProvider>
      </ThemeProvider>
    );
  }
}

export default withTranslation()(CustomDateTimePicker);
