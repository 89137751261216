import axios from "axios";
import { API_BASEURL } from "../../components/config/constants";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const image_headers = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
};

const ApiUtils = {
  // Login
  login: function (params) {
    return axios
      .post(API_BASEURL + "/user/login", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Social Login
  socialLogin: function (params) {
    return axios
      .post(API_BASEURL + "/user/social-login", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // SingUp
  signUp: function (params) {
    return axios
      .post(API_BASEURL + "/user/signup", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // obtainUrlUpload
  obtainUrlUpload: function (params) {
    return axios
      .post(API_BASEURL + "/post/upload-signed-url", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Otp Veirfy By Email
  otpVerifyByEmail: function (params) {
    return axios
      .post(API_BASEURL + "/user/otp/verify-by-email", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Otp Veirfy By Id
  otpVerifyById: function (params) {
    return axios
      .post(API_BASEURL + "/user/otp/verify-by-id", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get User Details By Id
  userDetails: function (params) {
    return axios
      .post(API_BASEURL + `/user/details`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Support Add
  addTicket: function (params) {
    return axios
      .post(API_BASEURL + `/ticket/create`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // addUserToGroup
  addUserToGroup: function (params) {
    return axios
      .post(API_BASEURL + `/list/add-remove-user`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Add or Remove user from list
  addRemoveUserFromLists: function (params) {
    return axios
      .post(API_BASEURL + `/list/add-remove-user-from-lists`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // deleteGroup
  deleteGroup: function (params) {
    return axios
      .post(API_BASEURL + `/list/delete`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // editAddUserToGroup
  editAddUserToGroup: function (params) {
    return axios
      .post(API_BASEURL + `/list/add-users`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getUserNotInList
  getUserNotInList: function (params) {
    return axios
      .post(API_BASEURL + `/list/get-missing`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Support Get
  getTicket: function (params) {
    return axios
      .post(API_BASEURL + `/ticket/show`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Account Growth
  getTotalReactions: function () {
    return axios
      .get(API_BASEURL + `/user/total-post-reactions`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // // Account Growth
  // getAverageRating: function (params) {
  //   return axios
  //     .post(API_BASEURL + `/user/get-average-rating`, params, {
  //       headers: headers,
  //     })
  //     .then((response) => response)
  //     .catch((error) => {
  //       throw error.response.data;
  //     });
  // },

  // Account Growth
  getTotalComments: function () {
    return axios
      .get(API_BASEURL + `/user/total-post-comments`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //
  isUserPasswordSet: function () {
    return axios
      .get(API_BASEURL + `/user/is-password-set`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Account Growth
  getSubscribersStats: function (params) {
    return axios
      .post(API_BASEURL + `/user/subscribersStats`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Account Growth
  sendLinkSetGooglePassword: function (params) {
    return axios
      .post(API_BASEURL + `/user/create-password`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Account Growth
  getReachChart: function (params) {
    return axios
      .post(API_BASEURL + `/user/reachStats`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Account Growth
  getPostImpressions: function (params) {
    return axios
      .post(API_BASEURL + `/user/impressions-posts`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Account Growth
  getClipImpressions: function (params) {
    return axios
      .post(API_BASEURL + `/user/impressions-clips`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Account Growth
  getProfileImpressions: function (params) {
    return axios
      .post(API_BASEURL + `/user/impressions-profile`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Account Growth
  getMassMessageImpressions: function (params) {
    return axios
      .post(API_BASEURL + `/user/impressions-mass-messages`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Account Growth
  getMassMessageReach: function () {
    return axios
      .get(API_BASEURL + `/user/mass-message-reach`, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Account Growth
  getMassMessageAverageConversion: function () {
    return axios
      .get(API_BASEURL + `/user/average-conversion-rate`, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Earnings
  getEarningsPerMonth: function () {
    return axios
      .get(API_BASEURL + `/user/earnings-per-month`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Earnings
  getEarningsDetail: function (params) {
    return axios
      .post(API_BASEURL + `/user/earnings-detail`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Account Growth
  getSummaryTotalEarnings: function () {
    return axios
      .get(API_BASEURL + `/user/summary-total-earnings`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Account Growth
  getSummaryTotalReferralsEarnings: function () {
    return axios
      .get(API_BASEURL + `/user/summary-total-referrals-earnings`, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Referral
  createReferralCode: function () {
    return axios
      .post(API_BASEURL + `/user/generate-referral-code`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Referral
  getReferralsList: function (params) {
    return axios
      .post(API_BASEURL + `/user/list-referrals`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Share
  addShare: function (params) {
    return axios
      .post(API_BASEURL + `/share/add`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Support List
  getTickets: function (params) {
    return axios
      .post(API_BASEURL + `/ticket/list`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Support Message
  sendMessageTicket: function (params) {
    return axios
      .post(API_BASEURL + `/ticket/sendMessage`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Support Solve
  solveTicket: function (params) {
    return axios
      .post(API_BASEURL + `/ticket/solve`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Comments Clip
  getCommentClip: function (params) {
    return axios
      .post(API_BASEURL + `/clip/comments`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Clip Details
  getClipDetails: function (id) {
    return axios
      .get(API_BASEURL + `/clip/details2/${id}`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Clip Details
  getClipInfo: function (id) {
    return axios
      .get(API_BASEURL + `/clip/details/${id}`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Comments Clip
  addCommentClip: function (params) {
    return axios
      .post(API_BASEURL + `/clip/addComment`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // addClipView

  addClipView: function (params) {
    return axios
      .post(API_BASEURL + `/clip/view`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Send Tip
  sendTip: function (params) {
    return axios
      .post(API_BASEURL + `/tip/send`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Total Tip
  totalTip: function (params) {
    return axios
      .post(API_BASEURL + `/tip/get-total`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Tip List
  tipList: function (params) {
    return axios
      .post(API_BASEURL + `/tip-plan/list`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Tip List Delete
  deleteTip: function (params) {
    return axios
      .post(API_BASEURL + `/tip-plan/remove`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Tip List Add
  addTip: function (params) {
    return axios
      .post(API_BASEURL + `/tip-plan/add`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Tip List Update
  updateTip: function (params) {
    return axios
      .post(API_BASEURL + `/tip-plan/update`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Send Report
  sendReport: function (params) {
    return axios
      .post(API_BASEURL + `/report/send`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Review Creator
  reviewCreator: function (params) {
    return axios
      .post(API_BASEURL + `/user/give-rating`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Stories Creator
  storiesCreator: function (params) {
    return axios
      .post(API_BASEURL + `/user/get-stories`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Stories By Id
  getStoryById: function (id) {
    return axios
      .get(API_BASEURL + `/story/${id}`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get My Stories
  myStories: function () {
    return axios
      .get(API_BASEURL + `/story/me`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  userUpdatePicture: function (params) {
    return axios
      .post(API_BASEURL + `/user/profile-picture`, params, {
        headers: image_headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  userUpdateCover: function (params) {
    return axios
      .post(API_BASEURL + `/user/cover-image`, params, {
        headers: image_headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Create Clip
  createClip: function (params) {
    return axios
      .post(API_BASEURL + `/clip/create`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Update Clip
  udpateClip: function (params) {
    return axios
      .post(API_BASEURL + `/clip/update`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Visualize Story
  visualizeStory: function (params) {
    return axios
      .post(API_BASEURL + `/story/view`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Like or Dislike stories
  likeStory: function (params) {
    return axios
      .post(API_BASEURL + `/story/like`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Like of story
  getStoryLike: function (params) {
    return axios
      .post(API_BASEURL + `/story/get-likes`, params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Story List
  storyList: function () {
    return axios
      .post(
        API_BASEURL + `/story/list`,
        {},
        {
          headers: headers,
        }
      )
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  globalFee: function () {
    return axios
      .get(API_BASEURL + `/global-fee`, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  addAttachment: function (params) {
    return axios
      .post(API_BASEURL + `/attachments/add`, params, {
        headers: image_headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  toggleComments: function (params) {
    return axios
      .post(API_BASEURL + `/user/toggle-comments`, params)
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  toggleAvailability: function (params) {
    return axios
      .post(API_BASEURL + `/user/toggle-availability`, params)
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  toggleSubscribersVisibility: function (params) {
    return axios
      .post(API_BASEURL + `/user/toggle-subscribers-visibility`, params)
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  getAttachment: function (id) {
    return axios
      .get(API_BASEURL + `/attachments/` + id, {
        headers: image_headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  getQRCode: function (params) {
    return axios
      .post(API_BASEURL + `/user/profile-qr-code`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Sessions List
  sessionsList: function () {
    return axios
      .get(API_BASEURL + "/user/login-sessions", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Payment History
  getPaymentHistory: function (params) {
    return axios
      .post(API_BASEURL + "/user/transactions", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Transfer Method
  addTransferMethod: function (params) {
    return axios
      .post(API_BASEURL + "/withdraw-method", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Close Sessions
  closeSessions: function (params) {
    return axios
      .post(API_BASEURL + "/user/close-login-sessions", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Update Profile
  updateProfile: function (params) {
    return axios
      .post(API_BASEURL + "/user/update-profile", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Check Creator
  addCheckCreator: function (params) {
    return axios
      .post(API_BASEURL + "/check/add", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getAllFans
  getAllFans: function (params) {
    return axios
      .post(API_BASEURL + "/manage-subscribers/get-all-fans", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getSubscribers
  getSubscribersManage: function (params) {
    return axios
      .post(API_BASEURL + "/manage-subscribers/get-subscribers", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getExpired
  getExpired: function (params) {
    return axios
      .post(API_BASEURL + "/manage-subscribers/get-expired", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete User Account
  deleteUserAccount: function (params) {
    return axios
      .post(API_BASEURL + "/user/delete-account", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Check User Exists
  checkUserExists: function (params) {
    return axios
      .post(API_BASEURL + "/user/check-exists", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Verify User Account
  verifyUserAccount: function (params) {
    return axios
      .post(API_BASEURL + "/user/verify-account", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Check user verify account
  checkPendingRequest: function () {
    return axios
      .get(API_BASEURL + "/check/pending-request", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //User Session Details
  userSessionDetails: function (params) {
    return axios
      .post(API_BASEURL + "/user/session-details", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error?.response?.data;
      });
  },

  // Delete User Session Detail
  deleteSingleSession: function (params) {
    return axios
      .post(API_BASEURL + "/user/delete-login-session", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Create Post
  creatorPost: function (params) {
    return axios
      .post(API_BASEURL + "/post/create", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete Post Media
  deletePostMedia: function (params) {
    return axios
      .post(API_BASEURL + "/post/delete-media", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Withdraw
  withdraw: function (params) {
    return axios
      .post(API_BASEURL + "/user/withdrawal", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Update Post Details
  updatePostDetails: function (params) {
    return axios
      .post(API_BASEURL + "/post/update", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Creator List
  creatorList: function (params) {
    return axios
      .post(API_BASEURL + "/user/creator-list", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Creator Post List (OLD don't use it)
  creatorPostList: function (params) {
    return axios
      .post(API_BASEURL + "/post/created-post-list", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Creator Post List new use this
  creatorSinglePostList: function (params) {
    return axios
      .post(API_BASEURL + "/post/creator-profile", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Scheduled Post List
  scheduledPostList: function (params) {
    return axios
      .post(API_BASEURL + "/post/scheduled-post-list", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Send Message Now
  sendMessageNow: function (params) {
    return axios
      .post(API_BASEURL + "/queue/send-message-now", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Send Mass Message Now
  sendMassMessageNow: function (params) {
    return axios
      .post(API_BASEURL + "/queue/send-mass-message-now", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Scheduled Post List
  scheduledMassMessagesList: function (params) {
    return axios
      .post(API_BASEURL + "/chat/get-scheduled-mass-messages", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Scheduled Post List
  getCounterChatMediaGallery: function (params) {
    return axios
      .post(API_BASEURL + "/chat/get-media-counters", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Scheduled Post List
  scheduledMessagesList: function (params) {
    return axios
      .post(API_BASEURL + "/chat/get-scheduled-messages", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Scheduled Clip List
  scheduledClipList: function (params) {
    return axios
      .post(API_BASEURL + "/clip/get-scheduled-clips", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Scheduled  Post List By Date
  scheduledPostListByDate: function (params) {
    return axios
      .post(API_BASEURL + "/post/scheduled-post-by-date", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Queue Post Media Count By Date
  queuePostMediaCountByDate: function (params) {
    return axios
      .post(API_BASEURL + "/post/scheduled-post-media-count", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete  Post
  deletePost: function (params) {
    return axios
      .post(API_BASEURL + "/post/delete", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete  Clip
  deleteClip: function (params) {
    return axios
      .post(API_BASEURL + "/clip/delete", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Publish  Post
  publishPost: function (params) {
    return axios
      .post(API_BASEURL + "/post/publish-now", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Publish  Clip
  publishClip: function (params) {
    return axios
      .post(API_BASEURL + "/clip/publish-now", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Publish  Post
  putFile: function (path, file) {
    return axios
      .put(path, file, {
        headers: headers /* { "Content-Length": new Blob([file]).size } */,
      })
      .then(() => {})
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Remove Reaction On  Post
  addRemoveReactionOnPost: function (params) {
    return axios
      .post(API_BASEURL + "/post/add-remove-reaction", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Create storie
  createStorie: function (params) {
    return axios
      .post(API_BASEURL + "/story/add", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Delete storie
  deleteStorie: function (params) {
    return axios
      .post(API_BASEURL + "/story/delete", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Reactions Count On  Post
  reactionsOnPost: function (params) {
    return axios
      .post(API_BASEURL + "/post/reactions", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Comment On  Post
  addCommentOnPost: function (params) {
    return axios
      .post(API_BASEURL + "/post/add-comment", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Update Comment On  Post
  updateCommentOnPost: function (params) {
    return axios
      .post(API_BASEURL + "/post/edit-comment", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete Comment On  Post
  deleteComment: function (params) {
    return axios
      .post(API_BASEURL + "/post/delete-comment", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Comments On  Post
  commentsOnPost: function (params) {
    return axios
      .post(API_BASEURL + "/post/comments", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Remove Like On  Post Comment
  addRemoveLikeOnPostComment: function (params) {
    return axios
      .post(API_BASEURL + "/post/comments/add-remove-like", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Remove Archive On  Post
  addRemoveArchiveOnPost: function (params) {
    return axios
      .post(API_BASEURL + "/archive/add-remove", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Archive Post List
  archivePostList: function (params) {
    return axios
      .post(API_BASEURL + "/archive/list-by-user", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Archive Story List
  archiveStoryList: function (params) {
    return axios
      .post(API_BASEURL + "/archive/stories-list-by-user", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Remove BookMArk On  Post
  addRemoveBookMarkOnPost: function (params) {
    return axios
      .post(API_BASEURL + "/bookmark/add-remove", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Appiled For Creator
  appliedCreator: function (params) {
    return axios
      .post(API_BASEURL + "/user/applied-creator", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // New Appiled For Creator
  applyKyc: function (params) {
    return axios
      .post(API_BASEURL + "/kyc/add", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Post Details
  postDetails: function (params) {
    return axios
      .get(API_BASEURL + `/post/details/${params.id}`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Schedule Withdraw
  getScheduleWithdraw: function () {
    return axios
      .get(API_BASEURL + `/user/schedule-withdraw`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Post Medias
  postMedias: function (params) {
    return axios
      .post(API_BASEURL + "/post/medias", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Post Purchase

  postPurchase: function (params) {
    return axios
      .post(API_BASEURL + "/user-post/purchase", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Manual Withdraw
  manualWithdraw: function () {
    return axios
      .post(API_BASEURL + "/user/manual-withdraw", {}, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Schedule Withdraw
  scheduleWithdraw: function (params) {
    return axios
      .post(API_BASEURL + "/user/schedule-withdraw", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // New Clip
  newClip: function (params) {
    return axios
      .post(API_BASEURL + "/clip/new", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Leave like comment
  leaveLikeComment: function (params) {
    return axios
      .post(API_BASEURL + "/clip/addCommentReaction", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Add Reaction CLip
  addReactionClip: function (params) {
    return axios
      .post(API_BASEURL + "/clip/addRemoveReaction", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Most Viewed Clip
  mostSellClip: function (params) {
    return axios
      .post(API_BASEURL + "/clip/topSellers", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Most Viewed Clip
  mostViewedClip: function (params) {
    return axios
      .post(API_BASEURL + "/clip/mostViewed", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // List colletion
  listUserCollection: function (params) {
    return axios
      .post(API_BASEURL + "/collection/list", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // List colletion
  listClipSingleCreator: function (params) {
    return axios
      .post(API_BASEURL + "/clip/list-by-creator", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Total Subscription
  getTotalSubscription: function (params) {
    return axios
      .post(API_BASEURL + "/subscription/get-total", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Total Clip
  getTotalClip: function (params) {
    return axios
      .post(API_BASEURL + "/user-clip/get-total", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  getTotalPost: function (params) {
    return axios
      .post(API_BASEURL + "/user-post/get-total", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getUserIdOfGroup
  getUserIdOfGroup: function (params) {
    return axios
      .post(API_BASEURL + "/list/get-all-users", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get List Sub
  getGroup: function (order, sortBy, search, excludeEmpty) {
    return axios
      .get(
        API_BASEURL +
          `/list/custom${order || sortBy ? "?" : ""}${
            order ? "order=" + order : ""
          }${order && sortBy ? "&" : ""}${sortBy ? "sortBy=" + sortBy : ""}${
            search ? "&search=" + search : ""
          }${excludeEmpty ? "&excludeEmpty=1" : ""}`,
        { headers: headers }
      )
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getCounterSubscribers
  getCounterSubscribers: function () {
    return axios
      .get(API_BASEURL + "/manage-subscribers/counters", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getUnreadMessage
  getUnreadMessage: function () {
    return axios
      .get(API_BASEURL + "/chat/unread-messages-count", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Create Group
  createGroup: function (params) {
    return axios
      .post(API_BASEURL + "/list/create", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Chat Medias Gallery
  getChatMediaGallery: function (params) {
    return axios
      .post(API_BASEURL + "/chat/get-medias", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getGroupForAdd
  getGroupForAdd: function (params) {
    return axios
      .post(API_BASEURL + "/list/get-missing", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getYourSub
  getYourSub: function (params) {
    return axios
      .post(API_BASEURL + "/user/get-subscribers", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getNewSub
  getNewSub: function (params) {
    return axios
      .post(API_BASEURL + "/manage-subscribers/get-new", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getGroupDetails
  getGroupDetails: function (params) {
    return axios
      .post(API_BASEURL + "/list/details", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Bookmark Clip
  getBookmarkClip: function (params) {
    return axios
      .post(API_BASEURL + "/bookmark/list-clips", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get My Content Clip
  getMyContentClip: function (params) {
    return axios
      .post(API_BASEURL + "/user-clip/get-purchased", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get My Content Post
  getMyContentPost: function (params) {
    return axios
      .post(API_BASEURL + "/user-post/get-purchased", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Collection
  addCollection: function (params) {
    return axios
      .post(API_BASEURL + "/collection/add", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // getCollectionDetails
  getCollectionDetails: function (params) {
    return axios
      .post(API_BASEURL + "/collection/get", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Media to collection
  addMediaToCollection: function (params) {
    return axios
      .post(API_BASEURL + "/collection/addMedias", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete From to collection
  deleteMediaFromCollection: function (params) {
    return axios
      .post(API_BASEURL + "/collection/removeMedias", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete From to allmedia collection
  deleteMediaFromAllMedia: function (params) {
    return axios
      .post(API_BASEURL + "/collection/remove-from-all", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Collection
  deleteCollection: function (params) {
    return axios
      .post(API_BASEURL + "/collection/remove", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Collection
  getAllCollection: function (params) {
    return axios
      .post(API_BASEURL + "/collection/allMedias", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Random Clip
  randomClip: function (params) {
    return axios
      .post(API_BASEURL + "/clip/list-random", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Post Media By Type
  postMediaByType: function (params) {
    return axios
      .post(API_BASEURL + "/post/media-type", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Post for get My Category
  myCategory: function (params) {
    return axios
      .post(API_BASEURL + "/category/list", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Update Category
  updateCategory: function (params) {
    return axios
      .post(API_BASEURL + "/user/add-remove-category", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Emojis List
  emojisList: function (location) {
    location = "";
    return axios
      .get(
        API_BASEURL + `/emoji/list${location === "post" ? "?from=post" : ""}`,
        {
          headers: headers,
        }
      )
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Trending Posts
  getTrendingPosts: function () {
    return axios
      .get(API_BASEURL + "/post/trending-posts", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Suggestion
  getSuggestion: function () {
    return axios
      .get(API_BASEURL + "/user/suggestions-for-you", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get All Category
  getAllCategory: function () {
    return axios
      .get(API_BASEURL + "/category/all", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Wallet
  getWallet: function () {
    return axios
      .get(API_BASEURL + "/user/wallet", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Withdraw Method
  getWithdrawMethod: function () {
    return axios
      .get(API_BASEURL + "/withdraw-method", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add New Category
  addNewCategory: function (params) {
    return axios
      .post(API_BASEURL + "/category/add", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Vault Category List By User
  vaultCategoryList: function (params) {
    return axios
      .post(API_BASEURL + "/category/list", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Vault List By Category
  vaultListByCategory: function (params) {
    return axios
      .post(API_BASEURL + "/vault/list-by-category", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Vault List By Category With Media
  vaultListByCategoryWithMedia: function (params) {
    return axios
      .post(API_BASEURL + "/category/list-with-media", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Category Details
  categoryDetails: function (params) {
    return axios
      .post(API_BASEURL + "/category/details", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Edit Category Details
  editCategoryDetails: function (params) {
    return axios
      .post(API_BASEURL + "/category/edit", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Delete Category
  deleteCategory: function (params) {
    return axios
      .post(API_BASEURL + "/category/delete", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Category Vault
  addCategoryVault: function (params) {
    return axios
      .post(API_BASEURL + "/vault/add", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete Vault
  deleteVault: function (params) {
    return axios
      .post(API_BASEURL + "/vault/delete", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get BookMark List By Type
  bookmarkList: function (params) {
    return axios
      .post(API_BASEURL + "/bookmark/list-by-user", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get counter by different type of bookmark

  bookmarkCounters: function (params) {
    return axios
      .get(API_BASEURL + "/bookmark/get-counter-v2", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  myContentCounters: function (params) {
    return axios
      .get(API_BASEURL + "/user-post/counters", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Notification Type List
  notificationTypeList: function (params) {
    return axios
      .get(API_BASEURL + "/notification/list-notification-type", {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Notification Counters
  notificationCounters: function (params) {
    return axios
      .get(API_BASEURL + "/notification/counters", {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Notification List By Type
  notificationListByType: function (params) {
    return axios
      .post(API_BASEURL + "/notification/list", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Unread Notification Count
  unreadNotificationCount: function () {
    return axios
      .get(API_BASEURL + "/notification/count", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Update Read Notification Count
  updateReadNotificationCount: function (params) {
    return axios
      .post(API_BASEURL + "/notification/update-readed", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Subscription Plans
  subscriptionPlanList: function (params) {
    return axios
      .get(API_BASEURL + "/subscription-plan/list", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Purchase Subscription Plan
  purchaseFreeSubscriptionPlan: function (params) {
    return axios
      .post(API_BASEURL + "/subscription/purchase", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //  Get Subscribed Creator Details
  getSubscribedCreatorDetails: function (params) {
    return axios
      .post(API_BASEURL + "/subscribed/user-details", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //  Get Subscribed Creator List
  getSubscribedCreatorList: function () {
    return axios
      .get(API_BASEURL + "/subscribed/user-list", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Cancel the Subscription
  cancelSubscription: function (params) {
    return axios
      .post(API_BASEURL + "/subscription/cancel", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add new promotion
  addPromotion: function (params) {
    return axios
      .post(API_BASEURL + "/promotion", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // delete promotion
  deletePromotion: function (id) {
    return axios
      .delete(API_BASEURL + "/promotion/" + id, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // fetch all promotions
  getPromotions: function (params) {
    return axios
      .get(API_BASEURL + "/promotion", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Fetch all country list
  getCountryList: function (params) {
    return axios
      .post(API_BASEURL + "/countries/list", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Created Plan List
  getCreatedPlanList: function (params) {
    return axios
      .post(API_BASEURL + "/subscription-plan/list/creator", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Subscription Plan By Duration
  getSubscriptionPlans: function (params) {
    return axios
      .post(API_BASEURL + "/subscription-product/list", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Create Subscription Plan
  createSubscriptionPlan: function (params) {
    return axios
      .post(API_BASEURL + "/subscription-plan/add", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Update Subscription Plan
  updateSubscriptionPlan: function (params) {
    return axios
      .post(API_BASEURL + "/subscription-plan/update", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete Subscription Plan
  deleteSubscriptionPlan: function (params) {
    return axios
      .post(API_BASEURL + "/subscription-plan/delete", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Archive Subscription Plan
  archiveSubscriptionPlan: function (params) {
    return axios
      .post(API_BASEURL + "/subscription-plan/archive", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Unarchive Subscription Plan
  unarchiveSubscriptionPlan: function (params) {
    return axios
      .post(API_BASEURL + "/subscription-plan/unarchive", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Add Card List Of User
  getAddedCardList: function () {
    return axios
      .get(API_BASEURL + "/stripe/card-list", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add Card To Stripe
  addCardToStripe: function (params) {
    return axios
      .post(API_BASEURL + "/stripe/add-card", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  updateCardToStripe: function (params) {
    return axios
      .post(API_BASEURL + "/stripe/edit-card", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  deleteCardToStripe: function (params) {
    return axios
      .post(API_BASEURL + "/stripe/delete-card", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  canDeleteCardToStripe: function (params) {
    return axios
      .post(API_BASEURL + "/stripe/can-delete-card", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Purchase Subscripton Plan
  purchaseSubscriptionPlan: function (params) {
    return axios
      .post(API_BASEURL + "/subscription/purchase", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Purchase Clip
  purchaseClip: function (params) {
    return axios
      .post(API_BASEURL + "/user-clip/purchase", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Add subscription plan details to DB
  addSessionSubscriptionDetails: function (params) {
    return axios
      .post(API_BASEURL + "/subscription/session-details", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Tax Details For The Subscription Plan
  getTaxDetailsForSubscription: function (params) {
    return axios
      .post(API_BASEURL + "/subscription/count-tax", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // delete inactive subscription details
  deleteInactiveSubscriptionDetails: function (params) {
    return axios
      .post(API_BASEURL + "/subscription/delete", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Search creator list by name and userName
  getCreatorListByName: function (params) {
    return axios
      .post(API_BASEURL + "/user/search-creator", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get all subscription plan by user
  getSubscriptionPlanByUser: function (params) {
    return axios
      .post(API_BASEURL + "/subscription/subscription-list", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Give rating to user
  giveRatingToUser: function (params) {
    return axios
      .post(API_BASEURL + "/user/give-rating", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Rating
  getRating: function (params) {
    return axios
      .post(API_BASEURL + "/user/get-rating-by-user", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Get Earnings
  getEarnings: function (params) {
    return axios
      .post(API_BASEURL + "/user/earnings", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get average rating by user
  getAverageRating: function (params) {
    return axios
      .post(API_BASEURL + "/user/get-average-rating", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  manageChatDetails: function (params) {
    return axios
      .post(API_BASEURL + "/user/settings/get-chat-media-info", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  editChatDetails: function (params) {
    return axios
      .post(API_BASEURL + "/user/settings/chat-media", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  setWelcomeMessage: function (params) {
    return axios
      .post(API_BASEURL + "/chat/set-welcome-message", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  chatCalculateTotal: function (params) {
    return axios
      .post(API_BASEURL + "/chat/calculate-total", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  chatCalculateTotalToUnlock: function (params) {
    return axios
      .post(API_BASEURL + "/chat/get-total-to-unlock", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  sendPaidMessageToCreator: function (params) {
    return axios
      .post(API_BASEURL + "/chat/confirm-total", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Chat List By User
  getChatListByUser: function (params) {
    return axios
      .post(API_BASEURL + "/chat/list-message", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get New User List
  getNewUserList: function (params) {
    return axios
      .post(API_BASEURL + "/chat/list-new-user", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Purchase Message
  purchaseMessage: function (params) {
    return axios
      .post(API_BASEURL + "/chat/unlock", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Edit Message
  editMessage: function (params) {
    return axios
      .post(API_BASEURL + "/chat/edit-message", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  editMassMessage: function (params) {
    return axios
      .post(API_BASEURL + "/chat/edit-mass-message", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete Message
  deleteScheduledMessage: function (params) {
    return axios
      .post(API_BASEURL + "/chat/delete-scheduled-message", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Unsend Mass Message
  unsendMassMessage: function (params) {
    return axios
      .post(API_BASEURL + "/chat/unsend-mass-message", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Search People Chat
  searchPeople: function (params) {
    return axios
      .post(API_BASEURL + "/chat/search/users", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Search Messages Chat
  searchMessages: function (params) {
    return axios
      .post(API_BASEURL + "/chat/search/messages", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get User Info Chat
  getUserInfo: function (params) {
    return axios
      .post(API_BASEURL + "/chat/user-info", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Schedule Mass Message
  scheduleMassMessage: function (params) {
    return axios
      .post(API_BASEURL + "/chat/send-mass-scheduled", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Schedule Message
  scheduleMessage: function (params) {
    return axios
      .post(API_BASEURL + "/chat/send-scheduled", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Chat Subscribers List
  getSubscribers: function () {
    return axios
      .get(API_BASEURL + `/subscribers/list`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Chat Expired Subscribers List
  getExpiredSubscribers: function () {
    return axios
      .get(API_BASEURL + `/subscribers/expired`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Chat Expired Subscribers List
  getNewSubscribers: function () {
    return axios
      .get(API_BASEURL + `/subscribers/new`, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get Mass Message Statistics
  getMassMessageStats: function (page, limit) {
    return axios
      .get(
        API_BASEURL +
          `/chat/mass-message-stats?page=${page || 1}&limit=${limit || 10}`,
        {
          headers: headers,
        }
      )
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Forgot Password
  forgotPassword: function (params) {
    return axios
      .post(API_BASEURL + "/user/forgot-password", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Reset password
  resetPassword: function (params) {
    return axios
      .post(API_BASEURL + "/user/reset-password", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Change password
  changePassword: function (params) {
    return axios
      .post(API_BASEURL + "/user/change-password", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Logout
  logout: function (params) {
    return axios
      .post(API_BASEURL + "/user/log-out", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },
};
export default ApiUtils;
